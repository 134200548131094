import { mixins } from "@aeo/core/api/querykeys/mixins";

export const querykeys = {
  loggedUser: "logged-user",
  profile: "profile",
  dashboard: "dashboard",
  menuNotifications: "menu-notifications",
  documents: {
    one: mixins.one("documents"),
    many: mixins.many("documents"),
  },
  applications: {
    one: mixins.one("applications"),
    many: mixins.many("applications"),
    select: mixins.many("select-applications"),
  },
  users: {
    many: mixins.many("users"),
    settings: ["users", "settings"],
    assign_list: mixins.many("search-users-list"),
  },
  news: {
    many: mixins.many("news"),
  },
  approvals: {
    many: mixins.many("approvals"),
    one: mixins.one("approvals"),
  },
  organizations: {
    many: mixins.many("organizations"),
    one: mixins.one("organizations"),
  },
  agents: {
    many: mixins.many("agents"),
    select: mixins.many("select-agents"),
    one: mixins.one("agent"),
  },
  agent: {
    audits: "audit",
    reaudits: "reaudit",
    logs: mixins.many("logs"),
    info: mixins.many("info"),
  },
  chat: {
    organizations: {
      one: mixins.one("chat-organizations"),
      many: mixins.many("chat-organizations"),
      replies: (id: number) => mixins.many([id, "chat-organizations-replies"]),
    },
  },
  tasks: {
    many: mixins.many("tasks"),
  },
  authorizations: {
    many: mixins.many("authorizations"),
  },
  preaudit: mixins.one("pre-audit"),
  audit: mixins.one("audit"),
  reaudit: mixins.one("re-audit"),
  riskMatrix: mixins.one("risk-matrix"),
  asycuda: {
    organization: "organization",
    declarations: {
      one: "declaration",
      many: "declarations",
    },
    items: {
      one: "item",
      many: "items",
    },
    segments: {
      one: "segment",
      many: "segments",
    },
  },
  criterions: (id: number, type: string) => ({
    done: mixins.many(["criterions-done", id, type]),
    detailsDone: mixins.many(["criterions-details-done", id, type]),
  }),
};
