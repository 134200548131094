import { Button, Switch, Tooltip } from "ebs-design";

import { Flex, Icon, WhiteSpace, ColumnNoWrap } from "@aeo/core/components";
import {
  getFullName,
  TableColumnBuilder,
  userRoles,
  MCSUserRole,
} from "@aeo/core/utils";
import models from "@aeo/core/models";

interface Args {
  onResendConfirmation: (username: string) => void;
  onEditClick: React.Dispatch<models.User>;
  onDeleteClick: React.Dispatch<models.User>;
  onChangeUserStatus: React.Dispatch<Pick<models.User, "is_blocked" | "id">>;
}

export const usersTableColumns = ({
  onResendConfirmation,
  onEditClick,
  onDeleteClick,
  onChangeUserStatus,
}: Args) =>
  new TableColumnBuilder<models.User>()
    .add("ID", "id")
    .add("Nume", "", getFullName)
    .add("IDNP", "idnp")
    .add("E-mail", "", (record) => record.email || record.username)
    .add("Telefon", "phone")
    .add("Funcție", ["profile", "function"])
    .add("Compartiment", ["profile", "domain", "name"], ColumnNoWrap)
    .add("Rol", ["profile", "role"], (value: MCSUserRole) => userRoles[value])
    .add("Blocare", "", (record) => (
      <Switch
        checked={record.is_blocked}
        onChange={() => onChangeUserStatus(record)}
      />
    ))
    .add(
      "",
      "",
      (record) => (
        <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
          {!record.last_login && (
            <Tooltip
              placement="top"
              title="Retrimite invitația"
              trigger="hover"
              delayHide={300}
            >
              <Button
                onClick={() => onResendConfirmation(record.email)}
                size="small"
                icon={() => <Icon type="send" />}
              ></Button>
            </Tooltip>
          )}
          <WhiteSpace h="1rem" />
          <Button
            onClick={() => onEditClick(record)}
            size="small"
            icon={() => <Icon type="edit" />}
          ></Button>
          <WhiteSpace h="1rem" />
          <Button
            onClick={() => onDeleteClick(record)}
            size="small"
            icon={() => <Icon type="delete" />}
          ></Button>
        </Flex>
      ),
      { width: 1 },
    )
    .getColumns();
