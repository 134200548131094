import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const SecurityTwo = () => {
  const securityTwo = fields.securityTwo;

  return (
    <>
      <h4>Subsectiunea 6.2. SECURITATEA CLĂDIRILOR</h4>
      <KeyValueRow {...securityTwo.location_secured} />
      <KeyValueRow {...securityTwo.procedures_verified} />
      <KeyValueRow {...securityTwo.checks_buildings} />
      <KeyValueRow {...securityTwo.register_checks} />
      <KeyValueRow {...securityTwo.register_checks_solution} />
      <KeyValueRow {...securityTwo.access_possibilities} />
      <KeyValueRow {...securityTwo.access_respected} />
      <KeyValueRow {...securityTwo.access_limits} />
      <KeyValueRow {...securityTwo.premises_light} />
      <KeyValueRow {...securityTwo.manage_keys} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.has_described_procedure} type="boolean" />
      <KeyValueRow
        {...securityTwo.described_procedure_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.has_private_parking} type="boolean" />
      <KeyValueRow {...securityTwo.parking_person} NA />
      <KeyValueRow {...securityTwo.parking_approval} NA />
      <WhiteSpace v="1rem" />

      <KeyValueRow
        {...securityTwo.has_parking_verification}
        NA
        type="boolean"
      />
      <KeyValueRow {...securityTwo.has_parking_procedure} NA type="boolean" />
      <KeyValueRow
        {...securityTwo.parking_procedure_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.premisesAccess.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityTwo.access_buildings} />
      <KeyValueRow {...securityTwo.verification_person} />
      <KeyValueRow {...securityTwo.unauthorized_procedure} />
      <KeyValueRow {...securityTwo.procedure_communicated} />
      <KeyValueRow {...securityTwo.plan_subdivisions} />
      <WhiteSpace v="2rem" />

      <KeyValueRow
        {...securityTwo.plan_subdivisions_documents}
        type="attachments"
      />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityTwo.specify_location} />

      <WhiteSpace v="2rem" />
      <h4>{messages.titles.loadingUnits.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityTwo.has_restrictions_loading} type="boolean" />
      <KeyValueRow {...securityTwo.restrictions_loading} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.unauthorized_access_measures} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.has_own_seals} type="boolean" />

      <KeyValueRow {...securityTwo.seals_details} NA />
      <KeyValueRow {...securityTwo.seals_not_used} NA reverseNA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.load_unit_measures} />
      <KeyValueRow {...securityTwo.load_unit_operators} />
      <KeyValueRow {...securityTwo.load_unit_maintains} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityTwo.has_regular_maintenance} type="boolean" />
      <KeyValueRow {...securityTwo.has_external_maintenance} type="boolean" />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.logisticsProcesses.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityTwo.transportation_methods} />
      <KeyValueRow {...securityTwo.external_service} />
      <KeyValueRow {...securityTwo.external_security_standards} />
      <KeyValueRow {...securityTwo.external_measures} />
      <WhiteSpace v="2rem" />

      <AttachmentsList />
    </>
  );
};
