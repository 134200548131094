import { useHistory } from "react-router";

import { Table, TableTitle } from "@aeo/core/components";
import models from "@aeo/core/models";

import { agentRequestsColumns } from "features/agents/columns";

interface Props {
  applications?: models.ActiveApplication[];
}

export const Requests = ({ applications }: Props) => {
  const history = useHistory();

  return (
    <Table
      data={applications}
      columns={agentRequestsColumns()}
      title={() => <TableTitle title={<h3>Cereri</h3>} />}
      variants={["highlight-hover"]}
      onRow={(data, _index) => ({
        onClick: () => {
          if (data.state !== models.ApplicationState.draft) {
            history.push(`/applications/flow/${data.id}`);
          }
        },
      })}
    />
  );
};
