import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import { Button, Loader } from "ebs-design";

import { Flex, Icon, WhiteSpace } from "@aeo/core/components";
import { makeBEM } from "@aeo/core/utils";
import { useParam } from "@aeo/core/hooks";

import { ChatMessageList } from ".";
import api, { querykeys } from "api";

const bem = makeBEM("chat-conversation");

export const ConversationContent = () => {
  const idParam = useParam("id", NumberParam.decode);
  const queryClient = useQueryClient();

  const conversationContainerRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);

  const query = useQuery(
    querykeys.chat.organizations.one(idParam || 0),
    () => api.chat.organizations.getById(idParam || 0),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.chat.organizations.many());
        queryClient.invalidateQueries(querykeys.loggedUser);
      },
      enabled: !!idParam,
    },
  );

  const organization = query.data;

  React.useEffect(() => {
    const adjust = () => {
      const $messagesContainer = messagesContainerRef.current;
      const $conversationContainer = conversationContainerRef.current;
      const $title = titleRef.current;

      if (!$messagesContainer || !$conversationContainer || !$title) return;

      $messagesContainer.style.height =
        $conversationContainer?.getBoundingClientRect().height -
        $title?.getBoundingClientRect().height +
        "px";
    };

    window.addEventListener("resize", adjust);
    adjust();

    return () => {
      window.removeEventListener("resize", adjust);
    };
  }, []);

  return (
    <div className={bem()} ref={conversationContainerRef}>
      <div className={bem("title")} ref={titleRef}>
        <Flex align="center">
          <Button type="text" icon={() => <Icon type="back" />} />
          <WhiteSpace h="0.25rem" />
          <h3>
            {query.isError && (
              <div className="color-danger">{query.error?.message}</div>
            )}
            {query.isLoading ? (
              <Loader.Inline> </Loader.Inline>
            ) : (
              organization?.name
            )}
          </h3>
        </Flex>
      </div>
      <div className={bem("content-row")}>
        <div
          className={bem("messages-container")}
          style={{ height: "0px" }}
          ref={messagesContainerRef}
        >
          <ChatMessageList managerId={query?.data?.manager_id} />
        </div>
        {/*
          TODO: Show organization details
          <div className={bem("details")}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
            <WhiteSpace h="0.25rem" />
            <div className="divider"></div>
            <WhiteSpace h="0.25rem" />
            <div className="color-gray">Persoana de contact</div>
            <div>Constantin Constantinov</div>
            <WhiteSpace h="0.25rem" />
            <div className="divider"></div>
            <WhiteSpace h="0.25rem" />
            <div className="color-gray">Persoana responsabilă</div>
            <div>Constantin Constantinov</div>
          </div> */}
      </div>
    </div>
  );
};
