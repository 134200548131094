import { Card } from "ebs-design";

import fields from "@aeo/core/fields";
import { WhiteSpace } from "@aeo/core/components";
import models from "@aeo/core/models";
import { formattedDate } from "@aeo/core/utils";

import { LabelValueRow } from "features/agents/components/LabelValueRow";

interface Props {
  data?: models.Agent;
  dataAsycuda?: models.AsycudaOrganization;
}

export const AgentCard = ({ data, dataAsycuda }: Props) => {
  const request = fields.request;

  return (
    <Card>
      <Card.Header>
        <h3>Despre companie</h3>
      </Card.Header>
      <Card.Body>
        <LabelValueRow
          label={request.applicant_name.label}
          value={data?.name}
        />
        <LabelValueRow
          label={request.applicant_idno.label}
          value={data?.idno}
        />
        <div className="divider"></div>
        <WhiteSpace />
        <LabelValueRow
          label={request.juridic_form.label}
          value={data?.active_application?.request?.juridic_form?.name}
        />
        <LabelValueRow
          label={request.applicant_created.label}
          value={data?.active_application?.request?.applicant_created}
        />
        <LabelValueRow
          label={request.applicant_address.label}
          value={data?.active_application?.request?.applicant_address}
        />
        <LabelValueRow
          label={request.activity_address.label}
          value={data?.active_application?.request?.activity_address}
        />
        <LabelValueRow
          label={request.postal_address.label}
          value={data?.active_application?.request?.postal_address}
        />
        <LabelValueRow
          label={request.activity_sector.label}
          value={data?.active_application?.request?.activity_sector
            ?.map((i) => i.name)
            .join(", ")}
        />
        <LabelValueRow
          label={request.activity_states.label}
          value={data?.active_application?.request?.activity_states
            ?.map((i) => i.name)
            .join(", ")}
        />
        <div className="divider"></div>
        <WhiteSpace />
        <LabelValueRow
          label={request.request_type.label}
          value={data?.active_application?.type}
        />
        <LabelValueRow
          label={request.crossing_customs.label}
          value={data?.active_application?.request?.crossing_customs
            ?.map((i) => i.name)
            .join(", ")}
        />
        <LabelValueRow
          label={request.facility.label}
          value={data?.active_application?.request?.facility}
        />
        <LabelValueRow
          label={request.management_customs.label}
          value={data?.active_application?.request?.management_customs}
        />
        <LabelValueRow
          label={request.accounting_office.label}
          value={data?.active_application?.request?.accounting_office}
        />
        <div className="divider"></div>
        <WhiteSpace />
        <h3>INFORMAȚIE ASYCUDA</h3>
        <WhiteSpace />
        <LabelValueRow
          label={request.applicant_name.label}
          value={dataAsycuda?.name}
        />
        <LabelValueRow
          label={request.applicant_idno.label}
          value={dataAsycuda?.identifier}
        />
        <LabelValueRow
          label={request.applicant_address.label}
          value={`${dataAsycuda?.address || "-"}\n${dataAsycuda?.region || ""}`}
        />
        <LabelValueRow
          label={request.contact_phone.label}
          value={dataAsycuda?.phone}
        />
        <LabelValueRow label="Cod" value={dataAsycuda?.code} />
        <LabelValueRow label="FAX" value={dataAsycuda?.fax} />
        <LabelValueRow label="flg_rem" value={dataAsycuda?.flg_rem} />
        <LabelValueRow
          label="Data modificării"
          value={formattedDate(dataAsycuda?.modify_time)}
        />
        <LabelValueRow label="tlx" value={dataAsycuda?.tlx} />
        <LabelValueRow
          label="Valabil de la "
          value={formattedDate(dataAsycuda?.valid_from)}
        />
        <LabelValueRow
          label="Valabil pâna la"
          value={formattedDate(dataAsycuda?.valid_to)}
        />
        <div className="divider"></div>
        <WhiteSpace />
        <h3>PERSOANA DE CONTACT</h3>
        <WhiteSpace />
        <LabelValueRow
          label={request.contact_name.label}
          value={data?.active_application?.request?.contact_name}
        />
        <LabelValueRow
          label={request.contact_phone.label}
          value={data?.active_application?.request?.contact_phone}
        />
        <LabelValueRow
          label={request.contact_email.label}
          value={data?.active_application?.request?.contact_email}
        />
        <LabelValueRow
          label={request.contact_fax.label}
          value={data?.active_application?.request?.contact_fax}
        />
        <div className="divider"></div>
        <WhiteSpace />
        <h3>SEMNATAR</h3>
        <WhiteSpace />
        <LabelValueRow
          label={request.signer_name.label}
          value={data?.active_application?.request?.signer_name}
        />
        <LabelValueRow
          label={request.signer_function.label}
          value={data?.active_application?.request?.signer_function}
        />
      </Card.Body>
    </Card>
  );
};
