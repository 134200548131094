import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Loader, useNotify } from "ebs-design";

import { CircularProgress, WhiteSpace } from "@aeo/core/components";
import { notifyErrors, calculatePercentage } from "@aeo/core/utils";
import { GenericObject } from "@aeo/core/types";
import { useRole } from "@aeo/core/hooks";

import { StatisticsCard } from "components";
import api, { querykeys } from "api";

export const Dashboard = () => {
  const { isUser } = useRole();
  const notify = useNotify();
  const history = useHistory();

  //Redirect to active applications page if role is "user"
  if (isUser) {
    history.push("/applications/active");
  }

  const { data = {}, isLoading } = useQuery(
    querykeys.dashboard,
    () => api.dashboard.statistics.get(),
    {
      select: (statistics) => {
        const updatedStatistics: GenericObject<{
          value: number;
          percentage: number;
        }> = {};

        Object.entries(statistics).forEach(([key, value]) => {
          updatedStatistics[key] = {
            value: value || 0,
            percentage: calculatePercentage(value, statistics.total),
          };
        });

        return updatedStatistics;
      },
      onError: (errors) => {
        notifyErrors(notify, errors);
      },
    },
  );

  return (
    <Loader loading={isLoading}>
      <h2>Dashboard</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="24px" />
      <div className="statistics-board">
        <StatisticsCard
          title="Numarul de cereri înregistrate"
          value={data?.registered?.value}
          color="#F68D2E"
          redirect="/applications/active?page=1&state=registered"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#F68D2E"
              value={data?.registered?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Numărul de cereri în draft"
          value={data?.draft?.value}
          redirect="/applications/draft"
          progress={
            <CircularProgress
              circleWidth={12}
              value={data?.draft?.percentage}
            />
          }
        />
        <StatisticsCard
          title=" Numărul de cereri în examinare de la etapa de examinare prealabila, audit"
          value={data?.examination?.value}
          color="#22C348"
          redirect="/applications/active?page=1&state=audit"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#22C348"
              value={data?.examination?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Numărul de cereri suspendate de la etapa de aplicare, examinare prealabila, audit"
          value={data?.suspended?.value}
          color="#A23FEE"
          redirect="/applications/active?page=1&state=suspend_audit"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#A23FEE"
              value={data?.suspended?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Numărul de cereri respinse de etapa de aplicare"
          value={data?.rejected?.value}
          color="#F2271C"
          redirect="/applications/active?page=1&state=rejected_registered"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#F2271C"
              value={data?.rejected?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Refuz de acordare autorizații de la etapa de audit"
          value={data?.rejected_audit?.value}
          color="#F8C51B"
          redirect="/applications/active?page=1&state=rejected_audit"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#F8C51B"
              value={data?.rejected_audit?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații acordate de la etapa de audit"
          value={data?.approved?.value}
          color="#1AC19D"
          redirect="/applications/active?page=1&state=approved"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#1AC19D"
              value={data?.approved?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații valide de până acum, după acele 5 zile"
          value={data?.active?.value}
          color="#F68D2E"
          redirect="/applications/active?page=1&state=active"
          progress={
            <CircularProgress
              circleWidth={12}
              color="#F68D2E"
              value={data?.active?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații suspendate"
          value={data?.suspended_active?.value}
          color="#f412eb"
          redirect="/applications/active?page=1&state=suspend_active"
          progress={
            <CircularProgress
              color="#f412eb"
              circleWidth={12}
              value={data?.suspended_active?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații retrase"
          value={data?.withdraw_active?.value}
          color="#2ef65c"
          redirect="/applications/active?page=1&state=withdraw_active"
          progress={
            <CircularProgress
              color="#2ef65c"
              circleWidth={12}
              value={data?.withdraw_active?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații revocate"
          value={data?.revoked?.value}
          color="#b44d4d"
          redirect="/applications/active?page=1&state=revoked"
          progress={
            <CircularProgress
              color="#b44d4d"
              circleWidth={12}
              value={data?.revoked?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații anulate"
          value={data?.annulled?.value}
          color="#3e00ff"
          redirect="/applications/active?page=1&state=annulled"
          progress={
            <CircularProgress
              color="#3e00ff"
              circleWidth={12}
              value={data?.annulled?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații modificate din AEOF/C in AEOS si invers"
          value={data?.upgraded?.value}
          color="#fff700"
          redirect="/applications/active?page=1&state=upgraded"
          progress={
            <CircularProgress
              color="#fff700"
              circleWidth={12}
              value={data?.upgraded?.percentage}
            />
          }
        />
        <StatisticsCard
          title="Autorizații în proces de reevaluare de la etapa de reevaluare"
          value={data?.re_audit?.value}
          color="#00c3ff"
          redirect="/applications/active?page=1&state=re_audit"
          progress={
            <CircularProgress
              color="#00c3ff"
              circleWidth={12}
              value={data?.re_audit?.percentage}
            />
          }
        />
      </div>
    </Loader>
  );
};
