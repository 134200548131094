import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";
import { Competent } from "./Competent";

export const SecurityOne = () => {
  const securityOne = fields.securityOne;

  return (
    <>
      <ExaminationCard {...securityOne.competent} title={<Competent />} />

      <ExaminationCard {...securityOne.has_assessment_risk} type="boolean" />
      <ExaminationCard {...securityOne.has_security_plan} type="boolean" />
      <ExaminationCard
        {...securityOne.security_plan_attachments}
        type="attachments"
      />

      <ExaminationCard {...securityOne.documents_reviewed} />
      <ExaminationCard {...securityOne.risks} />
      <ExaminationCard {...securityOne.measures} />

      <ExaminationCard
        {...securityOne.has_subdivision_measures}
        type="boolean"
      />

      <ExaminationCard {...securityOne.has_instructions} type="boolean" />
      <ExaminationCard {...securityOne.staff_communication} />
      <ExaminationCard {...securityOne.instructions_documented} />
      <ExaminationCard
        {...securityOne.instructions_documented_attachments}
        type="attachments"
      />

      <ExaminationCard {...securityOne.has_incidents} type="boolean" />
      <ExaminationCard {...securityOne.incidents} NA />

      <ExaminationCard {...securityOne.incidents_documented} type="boolean" />

      <ExaminationCard {...securityOne.has_certificate} type="boolean" />
      <ExaminationCard {...securityOne.certificates} type="attachments" NA />

      <ExaminationCard {...securityOne.has_apply_security} type="boolean" />
      <ExaminationCard {...securityOne.apply_security} NA />

      <ExaminationCard
        {...securityOne.has_special_requirements}
        type="boolean"
      />

      <ExaminationCard {...securityOne.has_security_service} type="boolean" />
      <ExaminationCard {...securityOne.security_service} NA />

      <ExaminationCard {...securityOne.has_assessment_service} type="boolean" />
      <ExaminationCard {...securityOne.risks_identified} NA />

      <ExaminationCard
        {...securityOne.has_client_requirements}
        type="boolean"
      />
      <ExaminationCard {...securityOne.client_requirements} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
