import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

import {} from ".";
import { KeyValueRow, AttachmentsList } from "..";

export const RequestDetails = () => {
  const request = fields.request;
  return (
    <>
      <h4>{capitalize(messages.titles.requiredRequestData)}</h4>
      <KeyValueRow {...request.applicant_name} />
      <KeyValueRow {...request.applicant_idno} />
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <KeyValueRow {...request.juridic_form} type="nomenclature" />
      <KeyValueRow {...request.applicant_created} />
      <KeyValueRow {...request.applicant_address} />
      <KeyValueRow {...request.activity_address} />
      <KeyValueRow {...request.postal_address} />
      <KeyValueRow {...request.activity_sector} type="nomenclature-list" />
      <KeyValueRow {...request.activity_states} type="nomenclature-list" />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...request.crossing_customs} type="nomenclature-list" />
      <KeyValueRow {...request.facility} />
      <KeyValueRow {...request.management_customs} />
      <KeyValueRow {...request.accounting_office} />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />

      <h4>{capitalize(messages.titles.contactPerson)}</h4>
      <KeyValueRow {...request.contact_name} />
      <KeyValueRow {...request.contact_phone} />
      <KeyValueRow {...request.contact_email} />
      <KeyValueRow {...request.contact_fax} />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />

      <h4>{capitalize(messages.titles.signatory)}</h4>
      <KeyValueRow {...request.signer_name} />
      <KeyValueRow {...request.signer_function} />
      <KeyValueRow {...request.license_attachments} type="attachments" />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
