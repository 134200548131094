import { Card, Button, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";

import { Icon, LoadingButton } from "@aeo/core/components";
import { Flex, PDFViewer } from "@aeo/core/components";
import { useDownloadFile, useModalState } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { useApplication } from "../../hooks";
import { LogoModal } from ".";

export interface LogoFormData {
  state?: models.LogoStatus;
  body?: { content: string; period: string };
}

export const AuthorizationLogo = () => {
  const activeApp = useApplication();
  const downloadFile = useDownloadFile();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const logoModalState = useModalState<LogoFormData>();

  const attachment: models.Attachment = {
    file_upload: activeApp?.logo?.image_url,
    title: `logo_nr_${activeApp.logo?.id}`,
  };

  const logoMutation = useMutation(
    (value: LogoFormData) =>
      api.logo.changeLogoStatus(activeApp?.logo?.id!, value),
    {
      onSuccess: () => {
        notify.success({
          title: "Statutul logo-ului a fost schimbat cu succes",
        });
        queryClient.invalidateQueries(
          querykeys.applications.one(activeApp?.id!),
        );
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  return (
    <>
      <Card className="authorization-logo">
        <Card.Header>
          <Flex justify="space-between" align="center">
            <span style={{ minWidth: "250px" }} className="mb-10">
              Cererea Nr. <strong>{activeApp?.id || ""}</strong> cu statutul{" "}
              <strong>{activeApp?.type || ""}</strong>
            </span>
            <Flex justify="flex-end" align="flex-start" wrap>
              <LoadingButton
                className="ml-10 mb-10"
                type="ghost"
                loading={logoMutation.isLoading}
                onClick={() =>
                  logoMutation.mutate({ state: models.LogoStatus.APPROVE })
                }
                disabled={
                  activeApp.logo?.state !== models.ApplicationLogoState.REVIEW
                }
              >
                Aprobare
              </LoadingButton>

              {activeApp?.logo?.image_url && (
                <Button
                  prefix={<Icon type="download" />}
                  onClick={() => downloadFile(attachment, "image/svg+xml")}
                  type="ghost"
                  className="ml-10"
                >
                  Descarcă
                </Button>
              )}
            </Flex>
          </Flex>
        </Card.Header>
        <Card.Body>
          {activeApp.logo ? (
            <PDFViewer
              file={activeApp.logo?.signed_document?.file_upload || ""}
              renderMode="canvas"
            />
          ) : (
            <p>Operatorul Economic nu are logo.</p>
          )}
        </Card.Body>
      </Card>
      {logoModalState.isOpen && (
        <LogoModal
          open={logoModalState.isOpen}
          onClose={logoModalState.close}
          data={logoModalState.data}
          mutation={logoMutation}
          title="Suspendare Logo"
        />
      )}
    </>
  );
};
