export const criterionExamination = {
  responsible: "Persoana/Subdiviziunea responsabilă",
  information: "Informație consultativă",
  consultativeRisks: "Riscuri consultative",
  identifiedRisk: "Risc identificat",
  newRisk: "Risc nou",
  monitoringRisk: "Risc spre monitorizare",
  revisionRisks: "Evaluarea riscurilor",
  recommendedActions: "Acțiuni recomandate",
  finding: "Constatări",
  conclusion: "Concluzie generală pe subcriteriu",
  recommendations: "Recomandări pentru agent economic",
};
