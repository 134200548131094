import { capitalize } from "ebs-design";

import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import {} from ".";
import { KeyValueRow, AttachmentsList } from "..";

export const EconomicAgent = () => {
  const economicAgent = fields.economicalAgent;
  return (
    <>
      <h4>{capitalize(messages.titles.companyInfo)}</h4>
      <h4>1.1.1.</h4>
      <KeyValueRow {...economicAgent.name} />
      <KeyValueRow {...economicAgent.address} />
      <KeyValueRow {...economicAgent.created} type="date" />
      <KeyValueRow {...economicAgent.juridic_form} type="nomenclature" />
      <KeyValueRow {...economicAgent.site} />
      <KeyValueRow {...economicAgent.part_of_group} type="boolean" />
      <KeyValueRow {...economicAgent.detail_group} NA />
      <KeyValueRow {...economicAgent.license_group} type="boolean" />
      <KeyValueRow {...economicAgent.license_name_group} NA />
      <KeyValueRow {...economicAgent.license_audit} type="boolean" />
      <KeyValueRow {...economicAgent.license_audit_group} NA />
      <KeyValueRow {...economicAgent.reorganization} />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
