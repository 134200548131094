import { Form, Select } from "ebs-design";
import { SelectProps } from "ebs-design/dist/components/molecules/Select/components";
import { ListField } from "rc-field-form/es/List";
import { NamePath } from "rc-field-form/es/interface";

import { makeBEM, makeNamePath } from "@aeo/core/utils";
import classNames from "classnames";

export interface RiskSelectFieldProps {
  options: SelectProps["options"];
  field: ListField;
  name: NamePath;
}

const bem = makeBEM("examination-card");

export const RiskSelectField = ({
  options,
  field,
  name,
}: RiskSelectFieldProps) => {
  return (
    <div style={{ minWidth: "100px" }}>
      <Form.Field
        className={classNames(bem("risk-select-field"), "m-0")}
        rules={[{ required: true, message: <></> }]}
        {...field}
        name={makeNamePath(field.name, name)}
      >
        <Select options={options} />
      </Form.Field>
    </div>
  );
};
