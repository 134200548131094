import models from "@aeo/core/models";

export const monitoringTablePlan = (list: models.Agent[]) => {
  return `
  <h3 style="text-align: right;">APROBAT <br /> DIRECTOR SERVICIULUI VAMAL</h3>
  <p></p>
  <p style="text-align: right;">________________________</p>
  <p></p>
<h3 style="text-align: center;">Plan de monitorizare a statutului AEO <br /> pentru perioada anului </h3>
<p></p>
<table style="border-collapse: collapse; width: 100%;" border="1" cellspacing="0" cellpadding="5">
<tbody>
<tr>
  <td>Nr. d/o</td>
  <td>Denumirea operatorului economic</td>
  <td>Codul fiscal</td>
  <td>Statut AEO</td>
  <td>Temei legal de monitorizare* </td>
</tr>
  ${list
    ?.map?.(
      (r, i: number) => `
      <tr>
        <td>${i + 1}</td>
        <td>${r.name}</td>
        <td>${r.idno}</td>
        <td>${r?.active_application
          ?.type
        // messages.applicationStates[
        //   r?.active_application?.state as models.ApplicationState
        // ]
      }</td>
        <td></td>
      </tr>
  `,
    )
    .join(" ")}
</tbody>
</table>`;
};

export const reevaluateTablePlan = (list: models.Agent[]) => {
  return `<table style="border-collapse: collapse; width: 100%;" border="1" cellspacing="0" cellpadding="5">
    <tbody>
    <tr>
      <td>Nr. d/o</td>
      <td>Denumirea operatorului economic</td>
      <td>Codul fiscal</td>
      <td>Adresa juridică</td>
      <td>Tip autorizație</td>
      <td>Responsabil</td>
    </tr>
      ${list
        ?.map?.(
          (r, i: number) => `
          <tr>
            <td>${i + 1}</td>
            <td>${r.name}</td>
            <td>${r.idno}</td>
            <td>${r.active_application?.request?.applicant_address}</td>
            <td>${r.active_application?.type}</td>
            <td>${r.manager?.name}</td>
          </tr>
      `,
        )
        .join(" ")}
    </tbody>
    </table>`;
};
