import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const SecurityFour = () => {
  const securityFour = fields.securityFour;

  return (
    <>
      <h4>{messages.titles.securityRequirements.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityFour.verification_identity_partners} />
      <KeyValueRow {...securityFour.safety_partner_requirements} />
      <KeyValueRow {...securityFour.safety_partner_procedure} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.has_partner_breaches} type="boolean" />
      <KeyValueRow {...securityFour.partner_breaches} NA />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.staffSecurity.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityFour.security_requirements} />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityFour.has_security_procedure} type="boolean" />
      <KeyValueRow {...securityFour.security_procedure} NA />
      <KeyValueRow
        {...securityFour.security_procedure_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.checking_new_employees} />
      <KeyValueRow {...securityFour.checking_old_employees} />
      <KeyValueRow {...securityFour.access_employees} />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityFour.has_safety_training} type="boolean" />
      <KeyValueRow {...securityFour.safety_training} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.has_annual_course} type="boolean" />
      <KeyValueRow
        {...securityFour.has_conducted_sessions}
        type="boolean-place"
      />
      <KeyValueRow {...securityFour.has_sessions_register} type="boolean" />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.fixed_term_employee} />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityFour.has_security_standards} type="boolean" />
      <KeyValueRow {...securityFour.security_standards} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.has_employee_instructions} type="boolean" />
      <h4>{messages.titles.contractedServices.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityFour.has_contractual_services} type="boolean" />
      <KeyValueRow {...securityFour.contractual_services} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityFour.has_written_agreements} type="boolean" />
      <KeyValueRow {...securityFour.written_agreements} NA />
      <WhiteSpace v="2rem" />

      <AttachmentsList />
    </>
  );
};
