import { useQuery } from "react-query";

import api, { querykeys } from "@aeo/core/api";
import models from "@aeo/core/models";

export const useTemplate = (
  values: models.VariablesToReplace,
  templateId?: string,
  isReplace?: boolean,
) => {
  const { data, isLoading, isFetching } = useQuery(
    querykeys.templates.one(templateId!),
    () =>
      api.nomenclatures.getEntityById<models.Template>(
        models.Nomenclatures.Templates,
        templateId!,
      ),
    {
      enabled: !!templateId,
      cacheTime: 0,
      staleTime: 0,
      select: (data) => {
        if (isReplace) {
          let content = data.content;
          Object.entries(values).forEach(
            ([key, value]) =>
              (content = content?.replaceAll(
                `{{${key}}}`,
                (value as string) || "",
              )),
          );
          return { ...data, content };
        }
        return data;
      },
    },
  );

  return { data, isLoading, isFetching };
};
