import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";
import { Card, Button, Loader } from "ebs-design";
import cn from "classnames";

import {
  Flex,
  GoBackTitle,
  Grid,
  PDFViewer,
  WhiteSpace,
  Icon,
} from "@aeo/core/components";
import { useParam, useDownloadFile } from "@aeo/core/hooks";
import { formattedDateTime, getFullName } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { LabelValueRow } from "features/agents/components";

const tabsButtons = [
  { value: "document", title: "Document de confirmare" },
  { value: "request_document", title: "Cererea" },
];

export const Order = () => {
  const [tabs, setTabs] = React.useState<string | undefined>();
  const idParam = useParam("id", NumberParam.decode) || 0;
  const downloadFile = useDownloadFile();

  const query = useQuery(
    querykeys.approvals.one(idParam),
    () => api.approvals.getById(idParam),
    { enabled: !!idParam },
  );

  const approval = query?.data;

  useEffect(() => {
    if (
      (approval?.document && !approval?.request_document) ||
      (approval?.request_document && approval.document)
    )
      setTabs("document");
    if (approval?.request_document && !approval?.document)
      setTabs("request_document");
  }, [approval?.request_document, approval?.document]);

  return (
    <>
      <Card>
        <Card.Header>
          <GoBackTitle to="/orders">
            <Flex justify="space-between" align="center">
              <h3>{approval?.title}</h3>

              <Button
                prefix={<Icon type="download" />}
                onClick={() => {
                  approval?.document && downloadFile(approval?.document);
                }}
              >
                Descarcă
              </Button>
            </Flex>
          </GoBackTitle>
        </Card.Header>
        <Card.Body>
          {query.isLoading && <Loader loading></Loader>}
          {approval && (
            <>
              <Grid rows="1fr" cols="1fr 1fr 1fr" gap="1rem">
                <LabelValueRow
                  label="Numărul cererii"
                  value={approval?.application?.id}
                />
                <LabelValueRow
                  label="Nume operator economic"
                  value={approval?.organization?.name}
                />
                <LabelValueRow
                  label="Persoana responsabilă"
                  value={approval?.manager?.name}
                />
                <LabelValueRow label="Creat de" value={approval?.owner?.name} />
                <LabelValueRow
                  label="Data creării"
                  value={formattedDateTime(approval?.created_at)}
                />
                <LabelValueRow label="Termenul" value="" />
                <LabelValueRow
                  label="Necesar de semnat de către"
                  value={
                    <>
                      {approval?.required_users?.map((item) => (
                        <p>{item?.name}</p>
                      ))}
                    </>
                  }
                />
                <LabelValueRow
                  label="Semnat de"
                  value={
                    <>
                      {approval?.document?.signatures?.map((item) => (
                        <p>{`${getFullName(item.owner)}`}</p>
                      ))}
                    </>
                  }
                />

                <LabelValueRow
                  label="Motiv de respingere"
                  value={approval?.reason}
                />
              </Grid>
              <WhiteSpace v="1rem" />
            </>
          )}
          {approval?.request_document && approval?.document && (
            <Flex justify="center">
              {tabsButtons?.map((v) => (
                <div
                  className={cn("tab-label", {
                    "tab-label--active": v.value === tabs,
                  })}
                  onClick={() => setTabs(v.value)}
                  key={v.value}
                >
                  <p>{v.title}</p>
                </div>
              ))}
            </Flex>
          )}
          <WhiteSpace v="2rem" />
          {approval?.document || approval?.request_document ? (
            <PDFViewer
              file={
                tabs === "document"
                  ? approval?.document?.file_upload
                  : approval?.request_document?.file_upload
              }
            />
          ) : (
            <p>(Nu sunt documente)</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
