import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";
import { GenericObject } from "@aeo/core/types";

export const audit = {
  get: async (applicationId: number, query?: GenericObject) => {
    const url = stringifyUrl(`/cs/audit/${applicationId}`, query);
    const { data } = await apiAxios.get<models.Profile>(url);
    return data;
  },
  patch: async (id: number, values?: { attachments?: models.Attachment[] }) => {
    const { data } = await apiAxios.patch(`/cs/audit/${id}`, values);
    return data;
  },
  getByCriterion: async (applicationId: number, query?: GenericObject) => {
    const url = stringifyUrl(`/cs/audit/${applicationId}/criterion`, query);
    const { data } = await apiAxios.get<models.AuditCriterion>(url);
    return data;
  },
  patchCriterion: async (id: number, values: models.AuditCriterion) => {
    const { data } = await apiAxios.patch(`/cs/audit/${id}/criterion`, values);
    return data;
  },
  report: async (id: number, values: models.ContentEntity) => {
    const { data } = await apiAxios.post(`/cs/audit/${id}/report`, values);
    return data;
  },
  getCriterionDashboard: async (id?: number, query?: GenericObject) => {
    const url = stringifyUrl(`/cs/audit/${id}/criterion-dashboard`, query);
    const { data } = await apiAxios.get<models.CriterionDashboard[]>(url);
    return data;
  },
  updateCriterionRisk: async (id: number, values?: Partial<models.Risk>) => {
    const { data } = await apiAxios.patch(`/cs/audit/${id}/risk`, values);
    return data;
  },
  updateRiskObservation: async (
    id: number,
    values?: models.RiskObservation[],
  ) => {
    const { data } = await apiAxios.patch(`/cs/audit/${id}/risks`, values);
    return data;
  },
  controlPlan: async (id: number, value: models.ApplicationId) => {
    const { data } = await apiAxios.post<models.ApplicationId>(
      `/cs/audit/${id}/plan`,
      value,
    );
    return data;
  },
  verificationList: async (id: number, value: models.ApplicationId) => {
    const { data } = await apiAxios.post<models.ApplicationId>(
      `/cs/audit/${id}/verification-list`,
      value,
    );
    return data;
  },
};
