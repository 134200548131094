import {
  AttachmentsList,
  KeyValueList,
  WhiteSpace,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";

import { ExaminationCard } from ".";
import { KeyValueRow } from "..";

export const Contact = () => {
  const person = { ...fields.person, ...fields.personContact };
  return (
    <>
      <ExaminationCard {...person.first_name} />
      <ExaminationCard {...person.last_name} />
      <ExaminationCard {...person.birthday} type="date" />
      <ExaminationCard {...person.idnp} />
      <ExaminationCard {...person.phone} />
      <ExaminationCard {...person.email} />
      <ExaminationCard {...person.address} />
      <ExaminationCard {...person.attachments_persons} type="attachments" />
      <ExaminationCard {...person.departments_involved} />

      <ExaminationCard {...person.has_customs_consulting} type="boolean" />
      <ExaminationCard {...person.customs_consulting} />

      <ExaminationCard {...person.has_third_party_involved} type="boolean" />
      <ExaminationCard
        name={[person.third_party_physic.name]}
        title={
          <KeyValueList
            title={`${messages.titles.thirdParty} - ${messages.titles.physicPerson}`}
            name={person.third_party_physic.name}
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
                <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.birthday, prefix)}
                  type="date"
                />
                <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
                <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                <KeyValueRow {...prefFieldName(person.email, prefix)} />
                <KeyValueRow {...prefFieldName(person.address, prefix)} />

                <WhiteSpace v="1rem" />
                <AttachmentsList name={makeNamePath(prefix, "attachments")} />
              </>
            )}
            Wrapper={({ children }) => (
              <>
                <div className="divider mb-10 mt-10" />
                {children}
              </>
            )}
          />
        }
      />

      <ExaminationCard
        name={[person.third_party_juridic.name]}
        title={
          <KeyValueList
            title={`${messages.titles.thirdParty} - ${messages.titles.juridicPerson}`}
            name={person.third_party_juridic.name}
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow {...prefFieldName(person.name, prefix)} />
                <KeyValueRow {...prefFieldName(person.idno, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.created, prefix)}
                  type="date"
                />
                <KeyValueRow {...prefFieldName(person.address, prefix)} />
                <KeyValueRow {...prefFieldName(person.phone, prefix)} />

                <WhiteSpace v="1rem" />
                <AttachmentsList name={makeNamePath(prefix, "attachments")} />
              </>
            )}
            Wrapper={({ children }) => (
              <>
                <div className="divider mb-10 mt-10" />
                {children}
              </>
            )}
          />
        }
      />
      <ExaminationCard attachmentList />
    </>
  );
};
