import { GenericObject } from "@aeo/core/types";
import { stringifyUrl } from "@aeo/core/api/utils";
import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const news = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl("/cs/news", query);
    const { data } = await apiAxios.get<models.WithResults<models.NewsItem>>(
      url,
    );
    return data;
  },
  post: async (news: models.NewsItem) => {
    const { data } = await apiAxios.post("/cs/news", news);
    return data;
  },
};
