import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";

import { KeyValueRow, KeyValueList } from "..";

import { AttachmentsList } from "..";

export const ShareholdersList = () => {
  const person = fields.person;

  return (
    <>
      <h4>
        a) Date complete privind proprietarii sau principalii acţionari,
        inclusiv procentul de acțiuni deţinute de aceştia.
      </h4>
      <WhiteSpace v="1rem" />
      <KeyValueList
        title={messages.titles.juridicPerson}
        name={person.juridic.name}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.created, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.percentage_shares, prefix)} />

            <WhiteSpace v="1rem" />
            <AttachmentsList name={makeNamePath(prefix, "attachments")} />
          </>
        )}
      />
      <WhiteSpace v="1rem" />
      <KeyValueList
        title={messages.titles.physicPerson}
        name={person.physic.name}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.birthday, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.percentage_shares, prefix)} />

            <WhiteSpace v="1rem" />
            <AttachmentsList name={makeNamePath(prefix, "attachments")} />
          </>
        )}
      />
    </>
  );
};
