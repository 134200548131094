import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "react-query";
import cn from "classnames";
import { Card, Space } from "ebs-design";

import { Badge, Flex, QueryList, WhiteSpace } from "@aeo/core/components";
import { makeBEM } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { ApplicationExaminationStateType } from "@aeo/core/types";

import api, { querykeys } from "api";

import { useApplication } from "features/applications/hooks";

import { ConclusionModal } from "./ConclusionModal";

const bem = makeBEM("applications-criteria");
interface Props {
  type: ApplicationExaminationStateType;
  disabled: boolean;
}

export const Criteria = ({ type, disabled }: Props) => {
  const app = useApplication();

  const query = useQuery(querykeys.criterions(app.id!, type).done(), () =>
    api.criterions.done(app.id!, type),
  );

  return (
    <Card>
      <Card.Header>
        <Space justify="space-between">
          <h3>Criteriile</h3>
          <ConclusionModal type={type} />
        </Space>
      </Card.Header>
      <Card.Body>
        <QueryList
          query={query}
          getItems={(data) => data?.results}
          renderItems={(data?: models.CriterionNomenclature[]) => (
            <>
              {data?.map((item, i) => (
                <Item
                  key={i}
                  nr={i + 1}
                  title={item.name}
                  name={item.id || 0}
                  completed={item.completed}
                  disabled={disabled}
                />
              ))}
            </>
          )}
        />
      </Card.Body>
    </Card>
  );
};

interface ItemProps {
  nr: React.ReactNode;
  title: React.ReactNode;
  name: string | number;
  completed?: boolean;
  disabled?: boolean;
}

const Item = ({ nr, title, name, completed, disabled }: ItemProps) => {
  const routeMatch = useRouteMatch();

  return (
    <Link
      to={`${routeMatch.url}/${name}`}
      className={cn(bem("list-item", { disabled }))}
    >
      <Flex align="center">
        <Badge
          color={completed ? "success" : disabled ? "secondary" : "danger"}
          variant="subtle"
          style={{ fontWeight: 600 }}
        >
          {nr}
        </Badge>
        <WhiteSpace h="1rem" />
        <h4>{title}</h4>
      </Flex>
    </Link>
  );
};
