import { Button } from "ebs-design";
import { Link, useHistory } from "react-router-dom";

import { Table, TableTitle } from "@aeo/core/components";
import models from "@aeo/core/models";
import { useRole } from "@aeo/core/hooks";

import { agentReauditsColumns } from "features/agents/columns";

interface Props {
  data?: models.Agent;
}

export const Reaudits = ({ data }: Props) => {
  const history = useHistory();
  const role = useRole(data?.manager?.id);
  const reAuditLink =
    data?.active_application?.id &&
    data?.active_application.state === models.ApplicationState.active
      ? `/applications/flow/${data?.active_application?.id}/re-audit`
      : null;

  return (
    <Table
      className="mcs-aplication-table"
      title={() => (
        <TableTitle
          title={<h3>Reaudit</h3>}
          extra={
            reAuditLink &&
            role.isManager && (
              <Link to={reAuditLink}>
                <Button>Crează reaudit</Button>
              </Link>
            )
          }
        />
      )}
      data={data?.re_audit_approvals}
      columns={agentReauditsColumns()}
      variants={["highlight-hover", "bordered"]}
      onRow={(data, _index) => ({
        onClick: () => {
          if (data.state !== models.ApprovalState.waiting) {
            history.push(`/applications/flow/${data.application}/re-audit`);
          }
        },
      })}
    />
  );
};
