import { AxiosError } from "axios";
import { UseMutationResult } from "react-query";
import { Button, Form, Modal, Space } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, InputFormField, LoadingButton } from "@aeo/core/components";

interface Props extends ModalProps {
  mutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
}

export const RejectModal = ({ mutation, ...props }: Props) => {
  return (
    <Modal {...props}>
      <Form
        onFinish={(values) => {
          mutation?.mutate(values, {
            onSuccess: () => {
              if (props.onClose) props.onClose();
            },
          });
        }}
      >
        <Modal.Content>
          <h4 style={{ marginBottom: "10px" }}>Motiv:</h4>
          <InputFormField textarea name="reason" placeholder="Descriere" />
        </Modal.Content>
        <Modal.Footer>
          <Flex>
            <Space style={{ marginLeft: "auto" }}>
              <Button onClick={props.onClose}>Anulează</Button>
              <LoadingButton
                type="primary"
                loading={mutation?.isLoading}
                submit
              >
                Respinge
              </LoadingButton>
            </Space>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
