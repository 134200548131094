import { Icon, IconProps } from "@aeo/core/components";
import classNames from "classnames";

export interface CheckCrossIconProps extends Omit<IconProps, "type"> {
  checked?: boolean;
}

export const CheckCrossIcon = ({ checked, ...props }: CheckCrossIconProps) => {
  return (
    <span className={classNames("check-cross-icon", { checked })}>
      <Icon {...props} size="1.2em" type={checked ? "check" : "close"} />
    </span>
  );
};
