import { Auth } from "@aeo/core/components/auth";
import { LoginForm } from "../components";

export const Login = () => {
  return (
    <Auth title="SV" subTitle="Panoul de administrare">
      <LoginForm />
    </Auth>
  );
};
