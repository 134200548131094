import React from "react";

import { Table, TableTitle } from "@aeo/core/components";
import { useModalState, useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";

import { ComplaintReplyModal } from "features/agents/components/ComplaintReplyModal";
import { agentAeoComplaintsColumns } from "features/agents/columns";

interface Props {
  data?: models.Agent;
}

export const AeoComplaints = ({ data }: Props) => {
  const modalState = useModalState<models.Complaint>();
  const role = useRole(data?.manager?.id);

  const tableColumns = React.useMemo(
    () =>
      agentAeoComplaintsColumns({
        modal: modalState.openWith,
        isManager: role.isManager,
      }),
    [modalState.openWith, role.isManager],
  );

  return (
    <>
      <Table
        data={data?.complaints}
        columns={tableColumns}
        title={() => <TableTitle title={<h3>Sesizări - AEO</h3>} />}
        variants={["bordered"]}
      />
      <ComplaintReplyModal
        open={modalState.isOpen}
        onClose={modalState.close}
        complaint={modalState.data}
        title="Raspunde la sesizare"
      />
    </>
  );
};
