import React from "react";
import { Button, Form, Input, Loader, Modal, useForm } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { UseMutationResult, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import "react-quill-2/dist/quill.snow.css";

import {
  AttachmentsFormField,
  Editor,
  Flex,
  Grid,
  LoadingButton,
  ModalHeader,
  PDFViewer,
  PreviewTabs,
  PreviewTabsEntity,
  PreviewTabsState,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";

import { useTemplate } from "hooks";
import { useApplication } from "features/applications/hooks";
import { querykeys } from "@aeo/core/api";

export interface TemplateModalProps extends ModalProps {
  templateId?: string;
  headerTemplateId?: string;
  mutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
  period?: boolean;
  hasTitle?: boolean;
  isReplace?: boolean;
  previewMutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
  pdfFile?: ArrayBuffer;
  headerContent?: boolean;
  withAttachments?: boolean;
}

const contentTitle: Record<string, string> = {
  header_project_report: "Concluzie și semnături",
  header_report: "Concluzie și semnături",
};

export const TemplateModal = ({
  templateId,
  headerTemplateId,
  mutation,
  period,
  hasTitle,
  isReplace,
  previewMutation,
  pdfFile,
  headerContent: hasHeader,
  withAttachments,
  ...props
}: TemplateModalProps) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const [headerContent, setHeaderContent] = React.useState<
    string | undefined
  >();
  const [editorContent, setEditorContent] = React.useState<
    string | undefined
  >();
  const [currentTab, setCurrentTab] =
    React.useState<PreviewTabsState>("editor");
  const [periodState, setPeriodState] = React.useState<string | number>();
  const [titleState, setTitleState] = React.useState<string | number>();
  const activeApp = useApplication();

  const variablesToReplace: models.VariablesToReplace = {
    company_name: activeApp?.organization?.name,
    company_type: activeApp?.saq?.economic_agent.juridic_form?.name,
    fiscal_code: activeApp?.organization?.idno,
    company_address: activeApp?.saq?.economic_agent.address,
    app_type: activeApp?.type,
    authorization_id: activeApp?.authorization?.id,
    authorization_date: activeApp?.authorization?.created_at,
    audit_report_date: activeApp?.report?.created_at,
    audit_report_number: activeApp?.report?.id,
  };

  const headerTemplate = useTemplate(
    variablesToReplace,
    headerTemplateId,
    isReplace,
  );

  const template = useTemplate(variablesToReplace, templateId, isReplace);

  const resetState = React.useCallback(() => {
    templateId &&
      queryClient.invalidateQueries(querykeys.templates.one(templateId!));
    headerTemplateId &&
      queryClient.invalidateQueries(querykeys.templates.one(headerTemplateId!));
    setHeaderContent(undefined);
    setEditorContent(undefined);
  }, [headerTemplateId, queryClient, templateId]);

  React.useEffect(() => {
    setHeaderContent(headerTemplateId ? headerTemplate?.data?.content : "");
    setEditorContent(templateId ? template?.data?.content : "");

    // Reset values
    return () => resetState();
  }, [
    template?.data?.content,
    headerTemplate?.data?.content,
    templateId,
    headerTemplateId,
    resetState,
  ]);

  React.useEffect(
    () => setTitleState(template?.data?.title || ""),
    [template?.data?.title],
  );

  const handleClose = async () => {
    resetState();
    props.onClose?.();
  };

  const handleSave = () => {
    mutation?.mutate(
      {
        header: headerContent,
        content: editorContent,
        period: period ? periodState : undefined,
        title: hasTitle ? titleState : undefined,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(querykeys.templates.one(templateId!));
          resetState();
        },
      },
    );
  };

  const previewTabs: PreviewTabsEntity = React.useMemo(() => {
    return {
      editor: {
        tabKey: "editor",
        title: "Editare",
        onClick: () => setCurrentTab("editor"),
        content: (
          <Loader loading={template.isLoading || headerTemplate.isLoading}>
            <Form form={form}>
              {hasHeader && (
                <>
                  <h3 className="mb-10">Antetul</h3>
                  {typeof headerContent !== "undefined" ? (
                    <Editor value={headerContent} onChange={setHeaderContent} />
                  ) : null}
                  <WhiteSpace v="1rem" />
                </>
              )}
              {hasHeader && (
                <h3 className="mb-10">
                  {headerTemplateId && contentTitle[headerTemplateId]
                    ? contentTitle[headerTemplateId]
                    : "Conținutul"}
                </h3>
              )}

              {typeof editorContent !== "undefined" ? (
                <Editor value={editorContent} onChange={setEditorContent} />
              ) : null}

              {withAttachments && (
                <div className="p-15">
                  <AttachmentsFormField />
                </div>
              )}
            </Form>
          </Loader>
        ),
      },
      preview: {
        tabKey: "preview",
        title: "Vizualizare",
        onClick: () => {
          previewMutation?.mutate({
            header: headerContent,
            content: editorContent,
          });
          setCurrentTab("preview");
          setHeaderContent(headerContent);
          setEditorContent(editorContent);
        },
        content: (
          <div className="py-20">
            <Loader loading={previewMutation?.isLoading!}>
              <PDFViewer file={pdfFile} />
            </Loader>
          </div>
        ),
      },
    };
  }, [
    editorContent,
    form,
    hasHeader,
    headerContent,
    pdfFile,
    previewMutation,
    withAttachments,
    template.isLoading,
    headerTemplate.isLoading,
    headerTemplateId,
  ]);

  return (
    <Modal
      className="editor-modal"
      size="large"
      closeOnClickOutside={false}
      onClose={handleClose}
      {...props}
    >
      <Modal.Content className="p-0">
        {!props.title && (
          <ModalHeader
            title={template?.data?.title || " "}
            onClose={handleClose}
          />
        )}

        <PreviewTabs tabs={previewTabs} currentTab={currentTab} />
      </Modal.Content>
      <Modal.Footer>
        <Grid cols="300px 1fr" gap="1rem">
          <div>
            {period && (
              <Input
                onChange={(value) => setPeriodState(value)}
                placeholder="Numărul de zile"
                size="large"
              />
            )}
            {hasTitle && (
              <Input
                onChange={(value) => setTitleState(value)}
                placeholder="Titlu document"
                size="large"
                value={titleState}
              />
            )}
          </div>
          <Flex justify="flex-end">
            <Button onClick={handleClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton
              type="primary"
              onClick={handleSave}
              loading={mutation?.isLoading}
            >
              Transmite
            </LoadingButton>
          </Flex>
        </Grid>
      </Modal.Footer>
    </Modal>
  );
};
