import React from "react";
import { UseInfiniteQueryResult } from "react-query";

import { Button } from "ebs-design";

export interface InfiniteScrollTriggerProps {
  data: unknown[];
  query: UseInfiniteQueryResult;
}

export const InfiniteScrollTrigger = React.forwardRef<
  HTMLDivElement,
  InfiniteScrollTriggerProps
>(({ query, data }, ref) => {
  return (
    <div ref={ref} className="text-center m-15">
      {data?.length
        ? query.hasNextPage && (
            <Button
              onClick={() => query.fetchNextPage()}
              disabled={!query.hasNextPage || query.isFetchingNextPage}
            >
              {query.isFetchingNextPage
                ? "Se încarcă..."
                : query.hasNextPage
                ? "Încărcați mai multe"
                : null}
            </Button>
          )
        : null}
    </div>
  );
});
