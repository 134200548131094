import { Button } from "ebs-design";

import { formattedDateTime, TableColumnBuilder } from "@aeo/core/utils";
import { Icon } from "@aeo/core/components/icons";
import models from "@aeo/core/models";
import { RoleGuard } from "@aeo/core/components";

import { StatusBadge } from "components";

interface Args {
  showModal: React.Dispatch<any>;
}

export const activeApplicationsColumns = ({ showModal }: Args) =>
  new TableColumnBuilder<models.ActiveApplication>()
    .add("Numărul de înregistrare", "number")
    .add("Data înregistrării", "created_at", formattedDateTime)
    .add("IDNO", ["organization", "idno"])
    .add("Adresa juridică", ["request", "applicant_address"])
    .add("Operator economic", ["organization", "name"])
    .add("Starea", "state", (state: models.ApplicationState) => (
      <StatusBadge state={state} />
    ))
    .add("Tipul autorizației", "type")
    .add("Data schimbării stării", "modified_at", formattedDateTime)
    .add("Persoana responsabilă", "", (record: models.ActiveApplication) =>
      record?.manager ? (
        <RoleGuard
          restritedTo={["user", "admin"]}
          fallback={<span>{record?.manager?.name}</span>}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              round
              size="small"
              onClick={() => {
                showModal(record);
              }}
            >
              {record?.manager?.name}
            </Button>
          </div>
        </RoleGuard>
      ) : (
        <RoleGuard restritedTo={["user", "admin"]}>
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              round
              size="small"
              icon={() => <Icon type="plus" />}
              onClick={() => showModal(record)}
            />
          </div>
        </RoleGuard>
      ),
    )
    .getColumns();

export const draftApplicationsColumns = () =>
  new TableColumnBuilder<models.ActiveApplication>()
    .add("Operator economic", ["organization", "name"])
    .add("Tipul autorizației", "type")
    .add("Data înregistrării", "created_at", formattedDateTime)
    .getColumns();
