import { KeyValueList, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";

import { KeyValueRow, AttachmentsList } from "..";
import { ExaminationCard } from ".";

export interface PersonListProps {
  title: string;
}

export const PersonList = ({ title }: PersonListProps) => {
  const person = fields.person;

  return (
    <ExaminationCard
      name={[]}
      title={
        <KeyValueList
          ItemContent={({ prefix }) => (
            <>
              <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
              <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
              <KeyValueRow
                {...prefFieldName(person.birthday, prefix)}
                type="date"
              />
              <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
              <KeyValueRow {...prefFieldName(person.phone, prefix)} />
              <KeyValueRow {...prefFieldName(person.email, prefix)} />
              <KeyValueRow {...prefFieldName(person.address, prefix)} />
              <WhiteSpace v="1rem" />
              <AttachmentsList name={makeNamePath(prefix, "attachments")} />
            </>
          )}
          name={[]}
          title={title}
          Wrapper={({ children }) => (
            <>
              <div className="divider mb-10 mt-10" />
              {children}
            </>
          )}
        />
      }
    />
  );
};
