import { useMutation, useQueryClient } from "react-query";
import { Button, Card, useNotify } from "ebs-design";

import { Flex, Icon, ViewPdfModal } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import { useModalState, useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";
import { useApplication } from "features/applications/hooks";

export const ControlPlanButtons = () => {
  const application = useApplication();
  const queryClient = useQueryClient();
  const controlPlanModal = useModalState();
  const notify = useNotify();
  const modalPdf = useModalState<models.Attachment>();

  const { isManager } = useRole(application?.organization?.manager?.id);

  const { mutate } = useMutation(
    api.reAudit.plan.bind(null, application?.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.one(application?.id!),
        );
        notify.success({ title: notifications.successControlPlan });
        controlPlanModal.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  return (
    <>
      <Card className="control-card">
        <Card.Body>
          <Flex justify="space-between">
            {isManager && application?.permissions?.can_re_plan && (
              <Button
                size="medium"
                prefix={<Icon type="action-plan" />}
                onClick={mutate}
              >
                Plan de control
              </Button>
            )}
            {application?.re_plan?.signed_document && (
              <Button
                size="medium"
                prefix={<Icon type="open-eye" />}
                onClick={() =>
                  application?.re_plan?.signed_document &&
                  modalPdf.openWith(application?.re_plan?.signed_document)
                }
              >
                Plan de control
              </Button>
            )}
          </Flex>
        </Card.Body>
      </Card>

      {modalPdf.isOpen && (
        <ViewPdfModal
          file={modalPdf?.data?.file_upload}
          fileTitle={modalPdf?.data?.title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title={modalPdf?.data?.title}
          size="large"
        />
      )}
    </>
  );
};
