import React from "react";
import { useUpdate } from "react-use";

export type RerenderContextValue = () => void;

export const RerenderContext = React.createContext<RerenderContextValue>(
  () => {},
);

export const RerenderProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const rerender = useUpdate();

  return (
    <RerenderContext.Provider value={rerender}>
      {children}
    </RerenderContext.Provider>
  );
};

export const useRerenderContext = () => React.useContext(RerenderContext);
