import { DatePicker } from "ebs-design";
import { parse } from "date-fns";
import { dateToInputFormat, maxDate, minDate } from "@aeo/core/utils";

import { DATE_FORMAT } from "../../app-constants";
import { Flex, WhiteSpace } from "..";
import { FilterCollapse } from ".";

export interface DateRangeFilterProps {
  after: string | null | undefined;
  before: string | null | undefined;
  setAfter: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setBefore: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  title: React.ReactNode;
}

export const DateRangeFilter = ({
  title,
  after,
  before,
  setAfter,
  setBefore,
}: DateRangeFilterProps) => {
  return (
    <FilterCollapse title={title} count={Number(!!after) + Number(!!before)}>
      <Flex align="center">
        <div className="flex-1">
          <DatePicker
            placeholderText="De la"
            value={after ? new Date(after) : undefined}
            onChange={(v) => {
              if (!v) return setAfter(undefined);

              v = parse(v as string, DATE_FORMAT, new Date());
              setAfter(dateToInputFormat(v));

              before &&
                setBefore(dateToInputFormat(maxDate(v, new Date(before))));
            }}
            //
            isClearable={!!after}
            // dateFormat={getDateFormat()}
            dateFormat={DATE_FORMAT}
            //
            selectsStart
            endDate={before ? new Date(before) : undefined}
          />
        </div>
        <WhiteSpace h="0.5rem" />
        <span>-</span>
        <WhiteSpace h="0.5rem" />
        <div className="flex-1">
          <DatePicker
            placeholderText="Până la"
            value={before ? new Date(before) : undefined}
            onChange={(v) => {
              if (!v) return setBefore(undefined);

              v = parse(v as string, DATE_FORMAT, new Date());

              setBefore(dateToInputFormat(v));
              after && setAfter(dateToInputFormat(minDate(v, new Date(after))));
            }}
            //
            isClearable={!!before}
            // dateFormat={getDateFormat()}
            dateFormat={DATE_FORMAT}
            //
            selectsEnd
            startDate={after ? new Date(after) : undefined}
          />
        </div>
      </Flex>
    </FilterCollapse>
  );
};
