import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Card, useNotify, Space, Loader, Modal } from "ebs-design";

import api from "@aeo/core/api";
import { notifyErrors } from "@aeo/core/utils";
import { usePagination } from "@aeo/core/hooks";
import { Flex, Icon, Pagination, WhiteSpace } from "@aeo/core/components";

import { CreateNomenclatureForm } from "./CreateNomenclatureForm";
import { EditNomenclatureForm } from "./EditNomenclatureForm";

interface Props {
  title: string;
  entity: string;
}

export const NomenclatureCard = ({ title, entity }: Props) => {
  const notify = useNotify();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const [idNomenclature, setIdNomenclature] = useState<number | null>();

  const queryClient = useQueryClient();
  const pagination = usePagination();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const { data, isLoading } = useQuery(
    [entity, queryParams],
    () => api.nomenclatures.getEntityList(entity, queryParams),
    { enabled: !!cardIsOpen },
  );

  const { mutate: handleDelete } = useMutation(
    (id: string) => api.nomenclatures.deleteEntity(entity, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([entity, queryParams]);
        notify.success({
          title: "Nomenclatura ștearsă cu succes!",
        });
        setShowDeleteModal(false);
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <Card collapsible collapsed>
        <Card.Header
          onClick={() => {
            setCardIsOpen((prevState) => !prevState);
          }}
        >
          <h3>{title}</h3>
        </Card.Header>
        <Card.Body>
          <Loader loading={isLoading}>
            {data?.results?.map((item) => (
              <EditNomenclatureForm
                item={item}
                entity={entity}
                setShowDeleteModal={setShowDeleteModal}
                setIdNomenclature={setIdNomenclature}
              />
            ))}
            <WhiteSpace />
            <CreateNomenclatureForm entity={entity} />
          </Loader>
        </Card.Body>

        {cardIsOpen && (
          <Card.Footer>
            <Flex justify="flex-end">
              <Pagination
                data={data}
                pagination={pagination}
                pageSizeChoice={[5, 10, 15]}
              />
            </Flex>
          </Card.Footer>
        )}
      </Card>

      <WhiteSpace />

      {showDeleteModal && (
        <Modal
          key={entity}
          open={showDeleteModal}
          closeOnClickOutside
          mask
          size="small"
          title="Șteregere nomenclatură"
        >
          <Modal.Content>
            <p>Sunteți siguri că doriți să ștergeți nomenclatura?</p>
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={() => setShowDeleteModal(false)}>
                Anulează
              </Button>
              <Button
                onClick={() => handleDelete(String(idNomenclature))}
                prefix={<Icon type="check" />}
                type="primary"
              >
                Șterege
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
