export function scrollToFieldError($container: HTMLDivElement | null) {
  setTimeout(() => {
    const $el =
      $container?.querySelector(".has-error") ||
      $container?.querySelector(".ebs-form__field__error");

    $el?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, 0);
}
