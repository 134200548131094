import { makeBEM } from "@aeo/core/utils";

import { Conversation, ConversationList } from "../components";

const bem = makeBEM("chat");

export const Chat = () => {
  return (
    <div className={bem("layout")}>
      <ConversationList />
      <Conversation />
    </div>
  );
};
