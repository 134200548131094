interface Props {
  label: string;
  value?: React.ReactNode;
}
export const LabelValueRow = ({ label, value }: Props) => {
  return (
    <div className="agent-info__card-row mb-10">
      <label className="agent-info__card-row__label color-mid-gray">
        {label}
      </label>
      <div className="fw-600">{value || "-"}</div>
    </div>
  );
};
