import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { makeNamePath, prefFieldName } from "@aeo/core/utils";
import { AttachmentsList } from "..";
import { KeyValueRow, KeyValueList } from "..";

export const Contact = () => {
  const person = { ...fields.person, ...fields.personContact };

  return (
    <>
      <KeyValueRow {...person.first_name} />
      <KeyValueRow {...person.last_name} />
      <KeyValueRow {...person.birthday} type="date" />
      <KeyValueRow {...person.idnp} />
      <KeyValueRow {...person.phone} />
      <KeyValueRow {...person.email} />
      <KeyValueRow {...person.address} />
      <KeyValueRow {...person.attachments_persons} type="attachments" />
      <KeyValueRow {...person.departments_involved} />

      <KeyValueRow {...person.has_customs_consulting} type="boolean" />
      <KeyValueRow {...person.customs_consulting} />

      <KeyValueRow {...person.has_third_party_involved} type="boolean" />
      <KeyValueList
        title={`${messages.titles.thirdParty} - ${messages.titles.physicPerson}`}
        name={person.third_party_physic.name}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.birthday, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />

            <WhiteSpace v="1rem" />
            <AttachmentsList name={makeNamePath(prefix, "attachments")} />
          </>
        )}
      />
      <WhiteSpace v="1rem" />
      <KeyValueList
        title={`${messages.titles.thirdParty} - ${messages.titles.juridicPerson}`}
        name={person.third_party_juridic.name}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.created, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />

            <WhiteSpace v="1rem" />
            <AttachmentsList name={makeNamePath(prefix, "attachments")} />
          </>
        )}
      />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
