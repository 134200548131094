import fields from "@aeo/core/fields";

import { ExaminationCard } from ".";

export const LogisticTwo = () => {
  const logisticTwo = fields.logisticTwo;

  return (
    <>
      <ExaminationCard {...logisticTwo.registration_procedure} />
      <ExaminationCard {...logisticTwo.stock_procedure} />

      <ExaminationCard
        {...logisticTwo.has_declaration_process}
        type="boolean"
      />
      <ExaminationCard {...logisticTwo.declaration_process} NA />
      <ExaminationCard
        {...logisticTwo.declaration_process_attachments}
        type="attachments"
      />

      <ExaminationCard
        {...logisticTwo.has_checking_declaration}
        type="boolean"
      />
      <ExaminationCard {...logisticTwo.checking_declaration} NA />

      <ExaminationCard
        {...logisticTwo.has_irregularities_instruction}
        type="boolean"
      />
      <ExaminationCard
        {...logisticTwo.has_documented_instruction}
        type="boolean"
      />
      <ExaminationCard
        {...logisticTwo.documented_instruction_attachments}
        type="attachments"
      />
      <ExaminationCard
        {...logisticTwo.has_identified_violation}
        type="boolean"
      />
      <ExaminationCard {...logisticTwo.identified_violation} />

      <ExaminationCard {...logisticTwo.has_licensed_goods} type="boolean" />
      <ExaminationCard
        {...logisticTwo.licensed_goods_attachments}
        type="attachments"
      />

      <ExaminationCard {...logisticTwo.has_external_license} type="boolean" />
      <ExaminationCard {...logisticTwo.external_license} NA />
      <ExaminationCard
        {...logisticTwo.external_license_attachments}
        type="attachments"
      />

      <ExaminationCard {...logisticTwo.has_goods_under_law} type="boolean" />
      <ExaminationCard {...logisticTwo.goods_under_law} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
