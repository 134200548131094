import { Redirect, Switch } from "react-router-dom";

import { Loader } from "ebs-design";

import { AuthLayout } from "@aeo/core/components/auth";
import { LayoutRoute } from "@aeo/core/components/LayoutRoute";
import { useAuth } from "@aeo/core/hooks";

import { authRoutes, appRoutes } from "routes";

import { AppLayout } from "./AppLayout";

export const AppRouter = () => {
  const { isLoading, user } = useAuth();

  return (
    <>
      {isLoading ? (
        <Loader loading />
      ) : (
        <Switch>
          {user &&
            authRoutes.map((route, i) => (
              <Redirect
                key={i}
                from={route.path instanceof Array ? route.path[0] : route.path}
                to="/"
              />
            ))}
          {user &&
            appRoutes.map((route, i) => (
              <LayoutRoute key={i} {...route} layout={AppLayout} />
            ))}
          {authRoutes.map((route, i) => (
            <LayoutRoute key={i} {...route} layout={AuthLayout} />
          ))}
          <Redirect to="/login" />
        </Switch>
      )}
    </>
  );
};
