import { Card, Button, Space } from "ebs-design";

import {
  Flex,
  WhiteSpace,
  Grid,
  Icon,
  ViewPdfModal,
} from "@aeo/core/components";
import { useModalState, useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";

import { useApplication } from "../../hooks";
import { ControlOrderDetails } from "./ControlOrderDetails";
import { ControlOrderModal } from "./ControlOrderModal";

interface Props {
  state: "pre-audit" | "audit";
}

export const ControlOrderAudit = ({ state }: Props) => {
  const application = useApplication();
  const controlOrderModal = useModalState();
  const modalPdf = useModalState<models.Attachment>();

  const isAudit = state === "audit";

  const { isManager } = useRole(application?.organization?.manager?.id);

  const canCreateOrder =
    !application?.order &&
    isAudit &&
    isManager &&
    !!application?.verification_list &&
    !!application?.plan?.signed_document;

  const canEditOrder = isAudit && application?.order?.can_edit_audit;

  return (
    <>
      <Card>
        <Card.Header>
          <Flex align="center" justify="space-between">
            <h3>Ordin de control</h3>
            <WhiteSpace h="1rem" />
            <Space>
              {canCreateOrder && (
                <Button
                  size="medium"
                  type="primary"
                  prefix={<Icon type="edit" />}
                  onClick={controlOrderModal.open}
                >
                  Crează Ordin
                </Button>
              )}

              {application?.order?.signed_document && (
                <Space>
                  <Button
                    size="medium"
                    type="primary"
                    prefix={<Icon type="open-eye" />}
                    onClick={() =>
                      application?.order?.signed_document &&
                      modalPdf.openWith(application?.order?.signed_document)
                    }
                  >
                    Ordin de control
                  </Button>

                  {isManager && canEditOrder && (
                    <Button
                      size="medium"
                      type="primary"
                      prefix={<Icon type="edit" />}
                      onClick={controlOrderModal.open}
                    >
                      Modifică Ordin
                    </Button>
                  )}
                </Space>
              )}
            </Space>
          </Flex>
        </Card.Header>
        {application?.order && (
          <Card.Body>
            <Grid gap="4rem">
              <ControlOrderDetails order={application?.order} />
            </Grid>
          </Card.Body>
        )}
      </Card>

      {controlOrderModal.isOpen && application && (
        <ControlOrderModal
          open={controlOrderModal.isOpen}
          onClose={controlOrderModal.close}
          title="Modificare Ordin de Control"
          canCreateOrder={canCreateOrder}
          application={application}
          size="large"
          state={state}
        />
      )}

      {modalPdf.isOpen && (
        <ViewPdfModal
          file={modalPdf?.data?.file_upload}
          fileTitle={modalPdf?.data?.title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title="Ordin de Control"
          size="large"
        />
      )}
    </>
  );
};
