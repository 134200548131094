import { TableColumnBuilder, formattedDate } from "@aeo/core/utils";
import models from "@aeo/core/models";

import { StatusBadge } from "components";

export const authorizationsColumns = () =>
  new TableColumnBuilder<models.Authorization>()
    .add("Numărul de înregistrare", ["number", "number"])
    .add("Data validării", "valid_date", formattedDate)
    .add("Agent economic", ["economic_agent", "applicant_name"])
    .add("IDNO", ["economic_agent", "applicant_idno"])
    .add("Tip Autorizație", ["application", "type"])
    .add("Starea", "state", (state: models.ApplicationState) => (
      <StatusBadge state={state} />
    ))
    .getColumns();
