import { useHistory } from "react-router-dom";

import models from "@aeo/core/models";
import { Table, TableTitle } from "@aeo/core/components";
import { agentAuditsColumns } from "features/agents/columns";

interface Props {
  auditApprovalsData?: models.AuditApproval[];
}

export const Audits = ({ auditApprovalsData }: Props) => {
  const history = useHistory();

  return (
    <Table
      data={auditApprovalsData}
      columns={agentAuditsColumns()}
      className="mcs-aplication-table"
      title={() => <TableTitle title={<h3>Audite</h3>} />}
      variants={["highlight-hover"]}
      onRow={(data, _index) => ({
        onClick: () => {
          if (data.state !== models.ApprovalState.waiting) {
            history.push(`/applications/flow/${data.application}/audit`);
          }
        },
      })}
    />
  );
};
