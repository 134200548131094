import { TabsWithRoute, WhiteSpace } from "@aeo/core/components";
import { useAuth } from "@aeo/core/hooks";
import { UserRoles } from "@aeo/core/utils";
import { settingTabs } from "../config/config";

export const Settings = () => {
  const { role } = useAuth();

  return (
    <div className="container-card">
      <h2>Setări</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />

      <TabsWithRoute
        type="badges"
        tabItems={settingTabs.filter((item) =>
          item.allowedTo.includes(role as UserRoles),
        )}
      />
    </div>
  );
};
