import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NumberParam } from "use-query-params";
import { Card, Form, Loader, useForm, Alert, useNotify } from "ebs-design";
import cn from "classnames";

import {
  AlertErrors,
  BooleanRadioFormField,
  InputFormField,
  Flex,
  GoBackTitle,
  InputFormList,
  LoadingButton,
  ScrollToTopButton,
  WhiteSpace,
  AttachmentsFormField,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { useParam, useRole, useAuth } from "@aeo/core/hooks";
import { groupBy, notifyErrors, scrollToFieldError } from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";

import { useApplication } from "../../hooks";
import { RiskList, RiskViewItem } from "..";
import { criterionExamination } from "../titles";

export const SubcriterionExamination = () => {
  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();
  const queryClient = useQueryClient();
  const match = useRouteMatch(
    "/applications/flow/:applicationId/re-audit/:criterion",
  );

  const { user } = useAuth();
  const application = useApplication();
  const role = useRole(application?.organization?.manager?.id);

  const criterionParam = useParam("subcriterion", NumberParam.decode) || 0;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isUserInReAuditTeam = !!application?.re_order?.team?.find(
    (item) => item.id === user?.id,
  );

  const isAllowedForUser =
    role.isManager || role.isSuperuser || isUserInReAuditTeam;

  const { data, isLoading, isError, error } = useQuery(
    querykeys.reaudit(application?.id || 0),
    () =>
      api.reAudit.getByCriterion(application?.id || 0, {
        criterion: criterionParam,
      }),
    {
      enabled: !!application && isAllowedForUser,
      select: (data) => {
        const { risks, residual_risks } = groupBy<models.Risk>(data?.risks, {
          // Holds new risks
          risks: (r) => !r.re_residual,
          // Filter residual risks
          residual_risks: (r) => r.re_residual,
        });

        return {
          ...data,
          risks,
          residual_risks,
        };
      },
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
    },
  );

  const mutation = useMutation(
    (values: models.AuditCriterion) =>
      api.reAudit.patchCriterion(application?.id || 0, values),
    {
      onSuccess: () => {
        notify.success({ title: notifications.savedData });
        if (match?.url) history.push(match?.url);
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const submitHandler = (values: models.AuditCriterion) => {
    mutation.mutate({
      ...values,
      criterion: criterionParam,
      // Merge residual risks with new risks
      risks: [...(data?.residual_risks || []), ...(values?.risks || [])],
    });
  };

  const mutationRisk = useMutation(
    (values: Partial<models.Risk>) =>
      api.reAudit.updateCriterionRisk(application.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.reaudit(application?.id!));
        notify.success({
          title: "Riscul a fost modificat cu succes!",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const updateRisk = (v: models.Risk, type?: string) => {
    mutationRisk.mutate({
      id: v.id,
      re_residual: type === "revision" ? true : !v.re_residual,
      re_audit_statements: v.re_audit_statements,
    });
  };

  if (!isAllowedForUser) {
    return (
      <GoBackTitle>
        <Alert type="warning">Nu aveți permisiune să vizualizați pagina</Alert>
      </GoBackTitle>
    );
  }

  return (
    <Form form={form} onFinish={submitHandler}>
      <ScrollToTopButton />
      <GoBackTitle>
        <Flex justify="space-between" align="center">
          {data?.criterion_nomenclature?.name}
          <WhiteSpace h="1rem" />
          {!isError && !isLoading && data?.can_edit && (
            <LoadingButton
              loading={mutation.isLoading}
              onClick={() => scrollToFieldError(containerRef.current)}
              submit
              type="primary"
            >
              Salvează
            </LoadingButton>
          )}
        </Flex>
      </GoBackTitle>
      <WhiteSpace v="1rem" />
      <Card>
        <Card.Header>
          <h3> {data?.criterion_nomenclature?.name}</h3>
        </Card.Header>
        <Card.Body>
          {isLoading && <Loader loading />}
          <AlertErrors error={error} />
          {data && (
            <div ref={containerRef}>
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.responsible}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.domain?.name}</p>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.information}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.description}</p>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.consultativeRisks}</h4>
                  <WhiteSpace v="0.5rem" />
                  <ul>
                    {data?.criterion_nomenclature?.default_risks.map((risk) => (
                      <li key={risk.id} className="mt-10">
                        {risk.description}
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.revisionRisks}</h4>
                  <WhiteSpace v="1rem" />
                  {data?.revision_risks?.map((risk) => (
                    <RiskViewItem
                      key={risk.id}
                      risk={risk}
                      riskType="revision"
                      riskTypeTitle="Evaluat"
                      {...(data?.can_edit && { onClick: updateRisk })}
                    />
                  ))}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.recommendedActions}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.activity}</p>
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.newRisk}</h4>
                </Card.Header>
                <Card.Body>
                  {data?.can_edit ? (
                    <RiskList name="risks" state="re-audit" />
                  ) : (
                    data?.risks?.map((risk) => (
                      <RiskViewItem
                        key={risk.id}
                        risk={risk}
                        riskType="new"
                        riskTypeTitle="Nou"
                      />
                    ))
                  )}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.monitoringRisk}</h4>
                </Card.Header>
                <Card.Body>
                  {data?.residual_risks?.map((risk) => (
                    <RiskViewItem
                      key={risk.id}
                      risk={risk}
                      riskType="re_residual"
                      {...(data?.can_edit && { onClick: updateRisk })}
                    />
                  ))}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.conclusion}</h4>
                  <WhiteSpace v="1rem" />
                  {data?.can_edit && (
                    <>
                      <BooleanRadioFormField
                        label={
                          data?.criterion_nomenclature?.conclusion_question
                        }
                        required
                        name="conclusion"
                      />
                      <InputFormField
                        label="Detalii"
                        name="conclusion_details"
                        textarea
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.recommendations}</h4>
                </Card.Header>
                <Card.Body className={cn({ "p-0": data?.can_edit })}>
                  {data?.can_edit ? (
                    <InputFormList
                      title="recomandare"
                      name="recommendations"
                      inputName="description"
                      textarea
                    />
                  ) : (
                    data?.recommendations?.map((v) => <p>{v.description}</p>)
                  )}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <AttachmentsFormField />
                </Card.Body>
              </Card>
            </div>
          )}
        </Card.Body>
      </Card>
    </Form>
  );
};
