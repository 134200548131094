import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const SecurityTwo = () => {
  const securityTwo = fields.securityTwo;

  return (
    <>
      <ExaminationCard {...securityTwo.location_secured} />
      <ExaminationCard {...securityTwo.procedures_verified} />
      <ExaminationCard {...securityTwo.checks_buildings} />
      <ExaminationCard {...securityTwo.register_checks} />
      <ExaminationCard {...securityTwo.register_checks_solution} />
      <ExaminationCard {...securityTwo.access_possibilities} />
      <ExaminationCard {...securityTwo.access_respected} />
      <ExaminationCard {...securityTwo.access_limits} />
      <ExaminationCard {...securityTwo.premises_light} />
      <ExaminationCard {...securityTwo.manage_keys} />

      <ExaminationCard
        {...securityTwo.has_described_procedure}
        type="boolean"
      />

      <ExaminationCard
        {...securityTwo.described_procedure_attachments}
        type="attachments"
      />

      <ExaminationCard {...securityTwo.has_private_parking} type="boolean" />
      <ExaminationCard {...securityTwo.parking_person} NA />
      <ExaminationCard {...securityTwo.parking_approval} NA />

      <ExaminationCard
        {...securityTwo.has_parking_verification}
        NA
        type="boolean"
      />
      <ExaminationCard
        {...securityTwo.has_parking_procedure}
        NA
        type="boolean"
      />
      <ExaminationCard
        {...securityTwo.parking_procedure_attachments}
        type="attachments"
      />

      <ExaminationCard {...securityTwo.access_buildings} />
      <ExaminationCard {...securityTwo.verification_person} />
      <ExaminationCard {...securityTwo.unauthorized_procedure} />
      <ExaminationCard {...securityTwo.procedure_communicated} />
      <ExaminationCard {...securityTwo.plan_subdivisions} />

      <ExaminationCard
        {...securityTwo.plan_subdivisions_documents}
        type="attachments"
      />

      <ExaminationCard {...securityTwo.specify_location} />

      <ExaminationCard
        {...securityTwo.has_restrictions_loading}
        type="boolean"
      />
      <ExaminationCard {...securityTwo.restrictions_loading} NA />

      <ExaminationCard {...securityTwo.unauthorized_access_measures} />

      <ExaminationCard {...securityTwo.has_own_seals} type="boolean" />
      <ExaminationCard {...securityTwo.seals_details} NA />

      <ExaminationCard {...securityTwo.seals_not_used} NA reverseNA />

      <ExaminationCard {...securityTwo.load_unit_measures} />
      <ExaminationCard {...securityTwo.load_unit_operators} />
      <ExaminationCard {...securityTwo.load_unit_maintains} />

      <ExaminationCard
        {...securityTwo.has_regular_maintenance}
        type="boolean"
      />
      <ExaminationCard
        {...securityTwo.has_external_maintenance}
        type="boolean"
      />

      <ExaminationCard {...securityTwo.transportation_methods} />
      <ExaminationCard {...securityTwo.external_service} />
      <ExaminationCard {...securityTwo.external_security_standards} />
      <ExaminationCard {...securityTwo.external_measures} />

      <ExaminationCard attachmentList />
    </>
  );
};
