import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Space, useForm, useNotify } from "ebs-design";

import { Icon, InputFormField } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import { usePagination } from "@aeo/core/hooks";
import api from "@aeo/core/api";

export const nomenclaturesPlaceholder: { [key: string]: string } = {
  categories: "Nume categorie",
  domains: "Nume domeniu",
  juridics: "Nume forma juridică de organizare",
  "methods/re-assessment": "Nume metodă de audit (reevaluare)",
  "methods/research": "Nume metodă de audit (investigare)",
  "methods/validation": "Nume metodă de audit (validare)",
  posts: "Nume post vamal",
  sectors: "Nume sector de activitate economică",
  templates: "Nume șablon",
};

export const CreateNomenclatureForm = (entityObj: { entity: string }) => {
  let entityName = entityObj.entity;
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const pagination = usePagination();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const mutation = useMutation(
    (body: { name: string }) =>
      api.nomenclatures.createEntity(entityName, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([entityName, queryParams]);
        notify.success({
          title: "Nomenclatura creată cu succes!",
        });
        form.resetFields();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );
  return (
    <Form form={form} onFinish={mutation.mutate}>
      <Space align="start" className="ebs-space--grow-first">
        <InputFormField
          placeholder={nomenclaturesPlaceholder[entityName]}
          name="name"
          required
        />
        <Form.Field>
          {(_, __, form) => {
            const disabled = !form.getFieldValue("name") || mutation.isLoading;

            return (
              <Button
                loading={mutation.isLoading}
                disabled={disabled}
                submit
                type="primary"
                icon={() => <Icon type="plus" color="gray" />}
              />
            );
          }}
        </Form.Field>
      </Space>
    </Form>
  );
};
