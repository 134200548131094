import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import classNames from "classnames";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import {
  formattedDateTime,
  riskLevelFeminine,
  riskLevelMasculine,
  setFieldsErrors,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";

interface DoneModalProps extends ModalProps {
  task?: models.Task;
  viewMode?: boolean;
}

export const ViewModal = ({ task, viewMode, ...props }: DoneModalProps) => {
  const [form] = useForm();

  const queryClient = useQueryClient();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError<models.ErrorResponse>,
    models.Task
  >((values) => api.tasks.done(task?.id!, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(querykeys.tasks.many());
      close();
      notify.success({ title: notifications.successTask });
    },
    onError: (error) => {
      if (error.response?.status === 400)
        setFieldsErrors(error.response?.data, form, notify);
    },
  });

  const handleForm = (values: models.Task) => {
    mutate(values);
  };

  const close = () => {
    form.resetFields();
    props?.onClose?.();
  };

  return (
    <Modal {...props} onClose={close}>
      <Form form={form} onFinish={handleForm}>
        <Modal.Content>
          <Flex
            justify="space-between"
            align="start"
            className="color-light-gray"
          >
            <Flex direction="column" align="start">
              <span>
                Operator economic: {task?.organization?.name || " - "}
              </span>
              <span>ID cerere: {task?.application || " - "}</span>
            </Flex>
            <Flex direction="column">
              <span>
                Data creării: {formattedDateTime(task?.created_at) || " - "}
              </span>
              <span>
                Data executării:{" "}
                {formattedDateTime(task?.execution_date) || " - "}
              </span>
            </Flex>
          </Flex>
          <WhiteSpace v="1rem" />
          <div>
            <span>Titlu:</span>
            <WhiteSpace v="4px" />
            <p className="color-mid-gray">{task?.title}</p>
          </div>
          <WhiteSpace v="1rem" />
          <Flex justify="space-between">
            <span>Descriere:</span>
            {task?.risk && (
              <Flex>
                <div>
                  <span className="mr-5">Probabilitate:</span>

                  <span
                    className={classNames({
                      [`risk-level-${task.risk?.probability?.toLowerCase()}`]:
                        task.risk.probability,
                    })}
                  >
                    {riskLevelFeminine[task.risk.probability] || " - "}
                  </span>
                </div>
                <WhiteSpace h="1rem" />
                <div>
                  <span className="mr-5">Impact:</span>

                  <span
                    className={classNames({
                      [`risk-level-${task.risk?.impact?.toLowerCase()}`]:
                        task.risk.impact,
                    })}
                  >
                    {riskLevelMasculine[task.risk.impact!] || " - "}
                  </span>
                </div>
              </Flex>
            )}
          </Flex>

          <WhiteSpace v="4px" />
          <p className="color-mid-gray">{task?.description || " - "}</p>
          <WhiteSpace v="1rem" />
          <div>
            <span>Răspuns:</span>
            <WhiteSpace v="4px" />
            {viewMode && (
              <p className="color-mid-gray">
                {task?.complaints_response || " - "}
              </p>
            )}
            {!viewMode && (
              <InputFormField textarea name="complaints_response" label="" />
            )}
          </div>
        </Modal.Content>
        {!viewMode && (
          <Modal.Footer>
            <Flex justify="end">
              <Button onClick={close}>Anulează</Button>
              <WhiteSpace h="1rem" />
              <LoadingButton loading={isLoading} submit type="primary">
                Marchează completat
              </LoadingButton>
            </Flex>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};
