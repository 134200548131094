import React from "react";
import { useQuery, useMutation } from "react-query";
import { NumberParam } from "use-query-params";
import { useForm, Form, Loader, useNotify } from "ebs-design";
import { useHistory } from "react-router-dom";

import {
  AlertErrors,
  Flex,
  GoBackTitle,
  GoBackTitleProps,
  LoadingButton,
  ScrollToTopButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { useParam } from "@aeo/core/hooks";
import { ApplicationPathContext } from "@aeo/core/contexts";
import {
  assignProperty,
  getProperty,
  notifyErrors,
  scrollToFieldError,
} from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";

export interface ExaminationFormProps {
  title: React.ReactNode;
  content: React.ReactNode;
  path: models.ApplicationValidatePath;
  backLink: GoBackTitleProps["to"];
}

/**
 * This function normalizes some data for the api.
 * It's more like workaround for the api.
 */
const normalizePreAudit = (values: any) => ({
  ...values,
  ...("saq" in values
    ? {
        saq: {
          ...values.saq,
          // Turnover
          ...("turnover" in values.saq
            ? {
                turnover: {
                  ...values?.saq?.turnover,
                  has_annual_profit: values?.saq?.turnover?.annual_profit,
                  has_annual_turnover: values?.saq?.turnover?.annual_turnover,
                },
              }
            : {}),
        },
      }
    : {}),
});

export const ExaminationForm = ({
  title,
  content,
  path,
  backLink,
}: ExaminationFormProps) => {
  const [form] = useForm();
  const idParam = useParam("id", NumberParam.decode) || 0;
  const notify = useNotify();
  const history = useHistory();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const query = useQuery(
    querykeys.preaudit(idParam),
    () => api.preaudit.getById(idParam),
    {
      enabled: !!idParam,
      onSuccess: (data) => {
        form.setFieldsValue(getProperty(data, ...path.split("/")));
      },
    },
  );

  const isLoading = query.isLoading || query.isFetching;

  const mutation = useMutation(api.preaudit.patch.bind(null, idParam), {
    onSuccess: () => {
      history.push(backLink as string);
      notify.success({ title: notifications.savedData });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: any) => {
    mutation.mutate(
      normalizePreAudit(assignProperty({}, values, ...path.split("/"))),
    );
  };

  const saveBtn = !query.isError && !isLoading && (
    <LoadingButton
      loading={mutation.isLoading}
      onClick={() => scrollToFieldError(containerRef.current)}
      submit
      type="primary"
    >
      Salvează
    </LoadingButton>
  );

  return (
    <>
      <Form form={form} onFinish={submitHandler}>
        <GoBackTitle to={backLink}>
          <Flex justify="space-between" align="center">
            {title}
            <WhiteSpace h="1rem" />
            {saveBtn}
          </Flex>
        </GoBackTitle>

        <WhiteSpace v="1rem" />

        <AlertErrors error={query.error} />

        {isLoading && <Loader loading />}

        {!query.isError && !isLoading && (
          <div ref={containerRef}>
            <ApplicationPathContext.Provider value={path}>
              {content}
            </ApplicationPathContext.Provider>
          </div>
        )}

        <Flex justify="center" align="center">
          {saveBtn}
        </Flex>
      </Form>

      <ScrollToTopButton />
    </>
  );
};
