import { useContext } from "react";

import models from "@aeo/core/models";
import { useIsManager } from "@aeo/core/hooks";
import { ApplicationContext } from "@aeo/core/contexts";

export const useApplication = (): models.ActiveApplication => {
  const application = useContext(ApplicationContext);
  const isManager = useIsManager();

  return {
    ...application,
    is_manager: isManager(application),
  };
};
