import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { ExaminationCard } from ".";

export const PracticalStandard = () => {
  const standard = fields.practicalStandard;

  return (
    <>
      <ExaminationCard {...standard.has_experience} type="boolean" />
      <ExaminationCard {...standard.experience} NA />
      <ExaminationCard
        {...standard.experience_attachments}
        type="attachments"
      />

      <ExaminationCard {...standard.has_quality_standard} type="boolean" />
      <ExaminationCard {...standard.quality_standard} NA />
      <ExaminationCard
        {...standard.quality_standard_attachments}
        type="attachments"
      />

      <h4 className="my-20">
        {messages.titles.professionalQualifications.toUpperCase()}
      </h4>

      <ExaminationCard {...standard.graduated_training_course} type="boolean" />
      <ExaminationCard {...standard.training_course} NA />
      <ExaminationCard
        {...standard.training_course_attachments}
        type="attachments"
      />

      <ExaminationCard attachmentList />
    </>
  );
};
