import { NomenclatureCard } from "./NomenclatureCard";

export const NomenclatureSettings = () => {
  return (
    <>
      <NomenclatureCard entity="categories" title="Categorii" />

      <NomenclatureCard entity="domains" title="Domenii" />

      <NomenclatureCard
        entity="juridics"
        title="Forma juridică de organizare"
      />

      {/* <NomenclatureCard
        entity="methods/re-assessment"
        title="Metode de audit - reevaluare"
      />

      <NomenclatureCard
        entity="methods/research"
        title="Metode de audit - investigare"
      />

      <NomenclatureCard
        entity="methods/validation"
        title="Metode de audit - validare"
      /> */}

      <NomenclatureCard entity="posts" title="Posturi vamale" />

      <NomenclatureCard
        entity="sectors"
        title="Sectoare de activitate economică"
      />

      <NomenclatureCard entity="templates" title="Șabloane documente" />
    </>
  );
};
