import api, { querykeys } from "api";
import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";
import { Loader, useNotify } from "ebs-design";

import { useParam } from "@aeo/core/hooks";
import { notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { ApplicationExaminationStateType } from "@aeo/core/types";

type RiskType = `${models.RiskLevel}_${models.RiskLevel}`;
type RiskItems = {
  [key in RiskType]?: models.CriterionDashboard;
};

export const RiskMatrix = ({
  state = "audit",
}: {
  state?: ApplicationExaminationStateType;
}) => {
  const auditId = useParam("id", NumberParam.decode) || 0;
  const criterionId = useParam("criterion", NumberParam.decode) || 0;
  const notify = useNotify();

  const { data, isLoading } = useQuery(
    querykeys.riskMatrix(criterionId),
    () =>
      state === "audit"
        ? api.audit.getCriterionDashboard(auditId, {
            criterion: criterionId,
          })
        : api.reAudit.getCriterionDashboard(auditId, {
            criterion: criterionId,
          }),
    {
      enabled: !!auditId && !!criterionId,
      select: (values) => {
        const risks: RiskItems = {};

        values.forEach((v) => {
          risks[`${v.impact}_${v.probability}` as RiskType] = v;
        });

        return risks;
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Loader loading={isLoading}>
      <div className="matrix">
        <div className="matrix__top__header">
          <div className="matrix__top__header__empty-cell"></div>
          <div className="matrix__top__header__wrapper">
            <div className="matrix__top__header__title">
              <span>Impactul</span>
            </div>
            <div className="matrix__level-title">
              <span>SCĂZUT</span>
            </div>
            <div className="matrix__level-title">
              <span>MEDIU</span>
            </div>
            <div className="matrix__level-title">
              <span>ÎNALT</span>
            </div>
          </div>
        </div>
        <div className="matrix__content">
          <div className="matrix__left__header">
            <div className="matrix__left__header__title">
              <span className="text-vertical">Probabilitatea</span>
            </div>
            <div className="matrix__level-title">
              <span className="text-vertical">SCĂZUTĂ</span>
            </div>
            <div className="matrix__level-title">
              <span className="text-vertical">MEDIE</span>
            </div>
            <div className="matrix__level-title">
              <span className="text-vertical">ÎNALTĂ</span>
            </div>
          </div>
          <div className="matrix__blocks">
            <div className="matrix__blocks__block color-green">
              {data?.LOW_LOW?.count}
            </div>
            <div className="matrix__blocks__block color-green">
              {data?.MEDIUM_LOW?.count}
            </div>
            <div className="matrix__blocks__block color-yellow">
              {data?.HIGH_LOW?.count}
            </div>
            <div className="matrix__blocks__block color-green">
              {data?.LOW_MEDIUM?.count}
            </div>
            <div className="matrix__blocks__block color-yellow">
              {data?.MEDIUM_MEDIUM?.count}
            </div>
            <div className="matrix__blocks__block color-red">
              {data?.HIGH_MEDIUM?.count}
            </div>
            <div className="matrix__blocks__block color-yellow">
              {data?.LOW_HIGH?.count}
            </div>
            <div className="matrix__blocks__block color-red">
              {data?.MEDIUM_HIGH?.count}
            </div>
            <div className="matrix__blocks__block color-red">
              {data?.HIGH_HIGH?.count}
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};
