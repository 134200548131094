import { Badge, Grid } from "@aeo/core/components";
import { makeBEM, formattedDate } from "@aeo/core/utils";
import models from "@aeo/core/models";
export interface ControlOrderDetailsProps {
  order?: models.Order;
}

const bem = makeBEM("audit-order-details");

export const ControlOrderDetails = ({ order }: ControlOrderDetailsProps) => {
  return (
    <div className={bem()}>
      <Grid cols="auto 1fr" gap="1rem 2rem" items="center stretch">
        <h4 className="color-gray">Obiectivul: </h4>
        <div>{order?.subject}</div>
        <h4 className="color-gray">Echipa de audit: </h4>
        <div>
          {order?.team?.map?.((fullName, idx) => (
            <Badge key={idx} className="mr-5 mb-5" variant="subtle">
              {fullName.name}
            </Badge>
          ))}
        </div>
        <h4 className="color-gray">Data: </h4>
        <div>{formattedDate(order?.start_date)}</div>
      </Grid>
    </div>
  );
};
