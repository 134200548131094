import React from "react";
import cn from "classnames";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { Collapse, Flex } from "@aeo/core/components";
import { useModalState, usePopupClose } from "@aeo/core/hooks";
import { makeBEM, notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import coreApi from "@aeo/core/api";

import api, { querykeys } from "api";
import { TemplateModal } from "components";
import { applicationStatesByStep, CurrentTab } from "../utils";
import { useApplication } from "../hooks";

const bem = makeBEM("authorizations");

type EditorData = {
  content?: string;
  period?: string | number;
  state?: models.StateAction;
  method?: "post" | "patch";
  endpoint?: string;
};

interface SelectStatusProps {
  tabs: models.TabsEntity[];
}
export const SelectStatus = ({ tabs }: SelectStatusProps) => {
  const overlayRef = React.useRef<HTMLDivElement>(null);
  usePopupClose(overlayRef, () => setIsOpen(false));

  const [isOpen, setIsOpen] = React.useState(false);
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const application = useApplication();
  const templateModal = useModalState<models.SelectCurrentStatus>();
  const notify = useNotify();

  // Get options by current state
  const splitLocation = location.pathname.split("/");

  // Current page state
  const currentPageState = tabs?.find((tab) =>
    splitLocation.includes(tab.route),
  );

  // Request type
  const checkRequestType = templateModal.data?.changeType;

  // Check if it's downgrade or upgrade
  const requestType =
    application?.type === models.AEOType.AEOF
      ? models.RequestType.DOWNGRADE
      : models.RequestType.UPGRADE;

  //Mutation Methods
  const mutationMethods = {
    onSuccess: () => {
      templateModal.close();
      return queryClient.invalidateQueries(
        querykeys.applications.one(application?.id!),
      );
    },
    onError: (error: AxiosError) => notifyErrors(notify, error),
  };

  const currentPageStateOptions = applicationStatesByStep({ history, url })[
    currentPageState?.route as CurrentTab
  ];

  // Update app state mutation object
  const updateStateObj = (value: EditorData) =>
    templateModal.data
      ? {
          state: templateModal?.data?.value,
          method: templateModal?.data?.method!,
          endpoint: templateModal?.data?.endpoint,
          body: { content: value.content, period: value.period },
        }
      : (value as models.ChangeApplicationStatusEntity);

  // Update app type mutation obj
  const changeAppTypeObj = (value: EditorData) => ({
    type: templateModal?.data?.name as models.AEOType,
    content: value.content,
  });

  // Mutation function
  const mutationFunc = async (value: EditorData) =>
    checkRequestType
      ? api.applications.changeAppType(
          application?.id!,
          requestType,
          changeAppTypeObj(value),
        )
      : api.applications.updateState(application?.id!, updateStateObj(value));

  // Mutation
  const updateState = useMutation(mutationFunc, mutationMethods);

  //Preview mutation
  const previewMutation = useMutation(coreApi.nomenclatures.orderPreview, {
    onSuccess: (value) => setPdfFile(value),
    onError: notifyErrors.bind(null, notify),
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => setIsOpen(false));
  });

  //Disable Select status Button
  const isDisabledStatusBtn = currentPageStateOptions
    ?.map((v) => v.enabled?.includes(application?.state!))
    ?.some((m) => m);

  return (
    <div className="applications__select-status">
      <Flex>
        {!application.state?.includes("withdraw") && (
          <Button
            loading={updateState.isLoading}
            className="no-wrap"
            type="primary"
            size="medium"
            onClick={() => setIsOpen(true)}
            disabled={!isDisabledStatusBtn}
          >
            Schimbare statut
          </Button>
        )}
        {isOpen && <div className={cn(bem("overlay"))} ref={overlayRef} />}

        <div className={cn(bem("surface", { open: isOpen }), "surface")}>
          <div className={bem("container")}>
            {currentPageStateOptions?.map((item, i: number) => (
              <React.Fragment key={i}>
                {item.orders ? (
                  <Collapse
                    header={<h4 className="color-gray pointer">{item.name}</h4>}
                    key={item.name}
                    disabled={
                      application?.state
                        ? !item?.enabled?.includes(application?.state)
                        : true
                    }
                  >
                    <div className={bem("dropdown-wrapper")}>
                      {item.orders.map(
                        (order, idx) =>
                          (order.changeType?.includes(application?.type!) ||
                            !order.changeType) && (
                            <Button
                              type="light"
                              key={idx}
                              className={bem("item")}
                              onClick={() => templateModal.openWith(order)}
                            >
                              {order.name}
                            </Button>
                          ),
                      )}
                    </div>
                  </Collapse>
                ) : (
                  <Button
                    type="light"
                    className={bem("title")}
                    disabled={
                      item.value === models.StateAction.RE_AUDIT_ACTIVE &&
                      currentPageState?.route === "re-audit"
                        ? !application?.re_report?.can_move_to_active
                        : application?.state
                        ? !item?.enabled?.includes(application?.state)
                        : true
                    }
                    onClick={() => {
                      if (item.modal) {
                        templateModal.openWith(item);
                      } else {
                        updateState.mutate(
                          {
                            state: item?.value,
                            method: item?.method!,
                            endpoint: item?.endpoint,
                          },
                          {
                            onSuccess: () =>
                              mutationMethods
                                .onSuccess()
                                .then(() => item?.onSuccess?.()),
                          },
                        );
                      }
                    }}
                  >
                    {item.name}
                  </Button>
                )}
              </React.Fragment>
            ))}
            {templateModal.isOpen && (
              <TemplateModal
                open={templateModal.isOpen}
                onClose={templateModal.close}
                templateId={templateModal?.data?.templateId}
                period={templateModal?.data?.period}
                mutation={updateState}
                previewMutation={previewMutation}
                pdfFile={pdfFile}
                isReplace
              />
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};
