import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { prefFieldName } from "@aeo/core/utils";
import { KeyValueRow, KeyValueList } from "..";

export const Advisory = () => {
  const person = { ...fields.person, ...fields.personAdvisory };

  return (
    <>
      <KeyValueRow {...person.has_advisory} type="boolean" />
      <KeyValueList
        title={messages.SAQTabs.advisory.subTitle}
        name={person.advisers.name}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.birthday, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              label="Documente confirmative privind numele, prenumele membrilor consiliului consultativ."
              type="attachments"
            />
          </>
        )}
      />
    </>
  );
};
