import { Button, Modal, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, LoadingButton, WhiteSpace } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import api from "api";

export interface DeleteUserModalProps extends ModalProps {
  data?: models.User;
}

export const DeleteUserModal = ({ data, ...props }: DeleteUserModalProps) => {
  const queryClient = useQueryClient();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError<models.ErrorResponse>,
    number
  >(api.users.deleteUser, {
    onError: (error) => {
      if (error.response?.status === 400)
        notifyErrors(notify, error.response.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      props?.onClose?.();
    },
  });

  return (
    <Modal title="Șterge utilizator" size="small" {...props}>
      <Modal.Content>
        Doriți să ștergeți utilizatorul{" "}
        <strong>{`${data?.last_name} ${data?.first_name}`}</strong>?
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="end">
          <Button onClick={props.onClose}>Anulează</Button>
          <WhiteSpace h="1rem" />
          <LoadingButton
            submit
            type="primary"
            loading={isLoading}
            onClick={() => mutate(data?.id!)}
          >
            Șterge utilizator
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
