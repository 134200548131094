import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Modal, Space, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  AlertErrors,
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import {
  formattedDateTime,
  getProperty,
  setFieldsErrors,
} from "@aeo/core/utils";
import { useParam } from "@aeo/core/hooks";

import api, { querykeys } from "api";
import { NumberParam } from "use-query-params";

interface Props extends ModalProps {
  complaint?: models.Complaint;
}

export const ComplaintReplyModal = ({ complaint, ...props }: Props) => {
  const [form] = useForm();
  const notify = useNotify();

  const id = Number(complaint?.id);
  const idParam = useParam("id", NumberParam.decode) || 0;

  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    (value: models.Complaint) => api.agents.complaintReply(id || 0, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.agents.one(idParam));
        resetModal();
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(getProperty(error.response?.data), form, notify);
      },
    },
  );

  const handleForm = (values: models.Complaint) => {
    mutate(values);
  };

  const resetModal = () => {
    props?.onClose?.();
    form.resetFields();
  };

  return (
    <Modal
      title={props.title}
      size="small"
      open={props.open}
      onClose={resetModal}
    >
      <Form form={form} onFinish={handleForm}>
        <Modal.Content>
          <Space justify="space-between" className="color-light-gray">
            <span>{complaint?.user?.name}</span>
            <span>{formattedDateTime(complaint?.created_at)}</span>
          </Space>
          <WhiteSpace v="1em" />
          <span>Departament</span>
          <WhiteSpace v="4px" />
          <p className="color-mid-gray">{complaint?.category}</p>
          <WhiteSpace v="1em" />
          <span>Sesizare</span>
          <WhiteSpace v="4px" />
          <p className="color-mid-gray">{complaint?.body}</p>
          <WhiteSpace v="1em" />
          <InputFormField
            textarea
            required
            name="body"
            label="Raspuns la sesizare"
          />
          <AlertErrors error={error} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={resetModal}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton submit type="primary" loading={isLoading}>
              Transmite răspuns
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
