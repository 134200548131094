import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";

import { QueryTable, TableTitle } from "@aeo/core/components";
import models from "@aeo/core/models";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "@aeo/core/hooks";

import api, { querykeys } from "api";
import { AssignModal } from "../components/AssignModal";
import {
  activeApplicationsColumns,
  orderingOptions,
} from "features/applications/config";

import { ApplicationsFilters } from ".";
import { useApplicationsFilterParams } from "../hooks";

export const ActiveApplications = () => {
  const [search, setSearch] = React.useState("");
  const history = useHistory();

  const pagination = useQueryPagination();
  const modal = useModalState<models.ActiveApplication>();

  const [filterParams, setFilterParams] = useApplicationsFilterParams();
  const [ordering, setOrdering] = useOrderingQueryParam();

  const queryParams = {
    ...pagination.queryParams,
    ...filterParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.applications.many(queryParams), () =>
    api.applications.get(queryParams),
  );

  const tableColumns = React.useMemo(
    () => activeApplicationsColumns({ showModal: modal.openWith }),
    [modal.openWith],
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <>
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <ApplicationsFilters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
                options={["type", "state", "manager", "date", "organization"]}
              />
            }
            sortOptions={orderingOptions}
            onSortChange={setOrdering}
          />
        )}
        query={query}
        columns={tableColumns}
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          onClick: () => history.push(`/applications/flow/${data.id}/details`),
        })}
        rowClassName={({ state, manager }) =>
          state === models.ApplicationState.registered && !manager
            ? "highlight-row"
            : "assign-user"
        }
      />

      {modal.isOpen && (
        <AssignModal
          open={modal.isOpen}
          onClose={modal.close}
          organization={modal?.data?.organization}
          manager={modal?.data?.manager}
        />
      )}
    </>
  );
};
