import { TableColumnBuilder, formattedDateTime } from "@aeo/core/utils";
import models from "@aeo/core/models";

export const ordersColumns = () =>
  new TableColumnBuilder<models.Approval>()
    .add("Numărul de înregistrare", "number")
    .add("Numărul cererii", ["application", "id"])
    .add("Data înregistrării", "created_at", formattedDateTime)
    .add("Titlul", "title")
    .add("Agent economic", ["organization", "name"])
    .add("Persoana responsabilă", ["manager", "name"])
    .getColumns();
