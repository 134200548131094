import React from "react";
import { Button, Form, Space, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";

import { Icon, InputFormField } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import { usePagination } from "@aeo/core/hooks";
import api from "@aeo/core/api";
import models from "@aeo/core/models";

interface Props {
  item: models.IdName & models.IdTitle;
  entity: string;
  setShowDeleteModal: (arg0: boolean) => void;
  setIdNomenclature: (arg0: number) => void;
}

export const EditNomenclatureForm = ({
  item,
  entity,
  setShowDeleteModal,
  setIdNomenclature,
}: Props) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const pagination = usePagination();

  const fieldName =
    entity === models.Nomenclatures.Templates ? "title" : "name";

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const mutation = useMutation(
    (payload: models.IdName & models.IdTitle) =>
      api.nomenclatures.editEntity(entity, String(item.id), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([entity, queryParams]);
        notify.success({
          title: "Nomenclatura modificată cu succes!",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Form key={item.id} onFinish={mutation.mutate}>
      <Space align="start" className="ebs-space--grow-first">
        <InputFormField
          name={fieldName}
          required
          initialValue={item[fieldName]}
        />
        <Space justify="space-between">
          <Form.Field>
            {(_, __, form) => {
              const disabled =
                !form.getFieldValue(fieldName) || mutation.isLoading;

              return (
                <Button
                  disabled={disabled}
                  loading={mutation.isLoading}
                  submit
                  type="primary"
                >
                  Salvează
                </Button>
              );
            }}
          </Form.Field>
          <Button
            icon={() => <Icon type="delete" color="gray" />}
            onClick={() => {
              setShowDeleteModal(true);
              setIdNomenclature(item.id || 0);
            }}
          />
        </Space>
      </Space>
    </Form>
  );
};
