import { useQuery } from "react-query";
import { Card } from "ebs-design";

import { QueryTable, TableTitle, WhiteSpace } from "@aeo/core/components";

import api, { querykeys } from "api";
import {
  declarationColumns,
  itemColumns,
  segmentColumns,
} from "features/agents/asycudaColumns";

interface Props {
  idno: string | undefined;
}

export const InstitutionInfo = (agent: Props) => {
  const declarationQuery = useQuery(
    querykeys.asycuda.declarations.many,
    () => api.asycuda.getDeclaration(agent.idno!),
    { enabled: !!agent.idno, retry: false },
  );

  const itemQuery = useQuery(
    querykeys.asycuda.items.many,
    () => api.asycuda.getItems(agent.idno!),
    { enabled: !!agent.idno, retry: false },
  );

  const segmentsQuery = useQuery(
    querykeys.asycuda.segments.many,
    () => api.asycuda.getSegments(agent.idno!),
    { enabled: !!agent.idno, retry: false },
  );

  return (
    <Card>
      <Card.Header>
        <h3>Informații de la alte instituții</h3>
      </Card.Header>
      <Card.Body>
        <QueryTable
          className="mcs-aplication-table"
          title={() => <TableTitle title={<h3>Declaratii</h3>} />}
          query={declarationQuery}
          columns={declarationColumns()}
          variants={["bordered"]}
        />
        <WhiteSpace v="1.5rem" />

        <QueryTable
          className="mcs-aplication-table"
          title={() => <TableTitle title={<h3>Articole</h3>} />}
          query={itemQuery}
          columns={itemColumns()}
          variants={["bordered"]}
        />
        <WhiteSpace v="1.5rem" />

        <QueryTable
          className="mcs-aplication-table"
          title={() => <TableTitle title={<h3>Segmente</h3>} />}
          query={segmentsQuery}
          columns={segmentColumns()}
          variants={["bordered"]}
        />
      </Card.Body>
    </Card>
  );
};
