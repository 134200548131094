import { NamePath } from "rc-field-form/es/interface";

import { AttachmentItem, Badge, WhiteSpace } from "@aeo/core/components";
import models from "@aeo/core/models";
import { useFieldValue } from "@aeo/core/hooks";

export interface AttachmentsListProps {
  name?: NamePath;
  hideLabel?: true;
  value?: models.Attachment[] | models.Attachment;
}

export const AttachmentsList = ({
  name = "attachments",
  hideLabel,
  value,
}: AttachmentsListProps) => {
  const _value = useFieldValue(name) as
    | models.Attachment[]
    | models.Attachment
    | undefined;
  const _list = value || _value;

  const list = _list ? (_list instanceof Array ? _list : [_list]) : [];

  return (
    <>
      {!hideLabel && <h4 className="pt-8">Documente atașate</h4>}
      <WhiteSpace v="0.5rem" />
      {_list && list?.length ? (
        list?.map?.((a, i) => (
          <AttachmentItem className="mb-5 mr-7" key={i} {...a} />
        ))
      ) : (
        <Badge variant="subtle">Lipsesc</Badge>
      )}
    </>
  );
};
