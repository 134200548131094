import { Link, LinkProps, useHistory } from "react-router-dom";

import { Button } from "ebs-design";

import { Flex, Icon, WhiteSpace } from "@aeo/core/components";

export interface GoBackTitleProps {
  to?: LinkProps["to"];
  actions?: React.ReactNode;
}

export const GoBackTitle = ({
  children,
  to,
  actions,
}: React.PropsWithChildren<GoBackTitleProps>) => {
  const history = useHistory();

  return (
    <Flex align="center" style={{ width: "100%" }}>
      <Flex align="center">
        <Link to={to || "#"}>
          <Button
            size="small"
            onClick={() => !to && history.goBack()}
            icon={() => <Icon type="back" />}
          />
        </Link>
        <WhiteSpace h="1rem" />
        <h3 className="flex-1">{children}</h3>
        <div>{actions}</div>
      </Flex>
    </Flex>
  );
};
