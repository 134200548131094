import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const LogisticThree = () => {
  const logisticThree = fields.logisticThree;

  return (
    <>
      <h4>{messages.titles.procedures.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...logisticThree.backup_process} />
      <KeyValueRow {...logisticThree.backup_ttl} />
      <KeyValueRow {...logisticThree.has_emergency_plan} type="boolean" />
      <KeyValueRow {...logisticThree.emergency_plan} />
      <KeyValueRow
        {...logisticThree.emergency_plan_attachments}
        type="attachments"
      />

      <WhiteSpace v="2rem" />
      <h4>{messages.titles.protectionOfSystems.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logisticThree.protect_system} />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...logisticThree.has_test_auth} type="boolean" />
      <KeyValueRow {...logisticThree.test_authorization} NA />

      <WhiteSpace v="2rem" />
      <KeyValueRow {...logisticThree.incident_security} />
      <KeyValueRow {...logisticThree.access_rights} />
      <KeyValueRow {...logisticThree.protection_responsible} />
      <KeyValueRow {...logisticThree.internal_security} />
      <KeyValueRow
        {...logisticThree.internal_security_attachments}
        type="attachments"
      />

      <KeyValueRow {...logisticThree.monitor_security} />
      <KeyValueRow {...logisticThree.server_located} />
      <KeyValueRow {...logisticThree.server_secured} />

      <WhiteSpace v="2rem" />
      <h4>{messages.titles.documentationSecurity.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logisticThree.protect_information_procedure} />

      <WhiteSpace v="1rem" />
      <KeyValueRow {...logisticThree.has_unauthorized_access} type="boolean" />
      <KeyValueRow {...logisticThree.unauthorized_access} NA />

      <WhiteSpace v="1rem" />
      <KeyValueRow {...logisticThree.employee_category} />
      <KeyValueRow {...logisticThree.allowed_change} />
      <KeyValueRow {...logisticThree.change_document_procedure} />
      <KeyValueRow {...logisticThree.security_requirements} />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
