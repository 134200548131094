import { WhiteSpace } from "@aeo/core/components";

import { AgentsTable } from "features/agents/components";

export const Agents = () => {
  return (
    <div>
      <h2>Operatori economici</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <AgentsTable />
    </div>
  );
};
