import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { NumberParam } from "use-query-params";
import { useMutation, useQuery } from "react-query";

import { Card, Form, useNotify, useForm } from "ebs-design";

import {
  Badge,
  Flex,
  GoBackTitle,
  GoBackTitleProps,
  Grid,
  InputFormField,
  LoadingButton,
  QueryList,
  WhiteSpace,
} from "@aeo/core/components";
import { makeBEM, notifyErrors } from "@aeo/core/utils";
import { useParam } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { ApplicationExaminationStateType } from "@aeo/core/types";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";

import { RiskMatrix } from "components/RiskMatrix";
import { useApplication } from "features/applications/hooks";

const bem = makeBEM("applications-subcriteria");

export interface SubcriteriaProps {
  backLink: GoBackTitleProps["to"];
  location: ApplicationExaminationStateType;
}

/** TMP markup */
export const Subcriteria = ({ backLink, location }: SubcriteriaProps) => {
  const idParam = useParam("criterion", NumberParam.decode);
  const app = useApplication();
  const notify = useNotify();
  const [form] = useForm();

  const queryParams = {
    criterion: idParam,
  };

  const query = useQuery(
    querykeys.criterions(app?.id!, location).detailsDone(queryParams),
    () => api.criterions.detailsDone(app?.id!, location, queryParams),
    {
      onSuccess: (data) =>
        form.setFieldsValue({ conclusion_details: data?.conclusion_details }),
      enabled: !!idParam,
    },
  );

  const conclusionMutation = useMutation(
    (v: {}) =>
      api.criterions.update(app?.id!, location, {
        ...v,
        criterion: idParam,
      }),
    {
      onSuccess: () => notify.success({ title: notifications.conclusionSent }),
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <GoBackTitle to={backLink}>{query.data?.name}</GoBackTitle>
      <div style={{ width: "100%" }}>
        <WhiteSpace v="1rem" />
      </div>
      <Grid
        cols="1fr 480px"
        gap="20px"
        items="baseline"
        style={{ maxWidth: "1400px", width: "100%" }}
      >
        <Card>
          <Card.Header>
            <h3>{query?.data?.name}</h3>
          </Card.Header>
          <Card.Body>
            <QueryList
              query={query}
              getItems={(data) => data?.children}
              renderItems={(items?: models.CriterionNomenclature[]) => (
                <>
                  {items?.map((item, i) => (
                    <Item
                      key={i}
                      nr={i + 1}
                      title={item.name}
                      id={item.id || 0}
                      completed={item.completed}
                    />
                  ))}
                </>
              )}
            />
            <WhiteSpace v="3rem" />
            <RiskMatrix state={location} />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <h3>Concluzie</h3>
          </Card.Header>
          <Card.Body>
            {query?.data?.can_edit ? (
              <Form form={form} onFinish={conclusionMutation.mutate}>
                <InputFormField name="conclusion_details" textarea />
                <Flex justify="right">
                  <LoadingButton
                    type="primary"
                    loading={conclusionMutation.isLoading}
                    submit
                  >
                    Trimite
                  </LoadingButton>
                </Flex>
              </Form>
            ) : (
              <p>{query?.data?.conclusion_details || ""}</p>
            )}
          </Card.Body>
        </Card>
      </Grid>
    </>
  );
};

interface ItemProps {
  nr: React.ReactNode;
  title: React.ReactNode;
  id: string | number;
  person?: { first_name?: string; last_name?: string };
  completed?: boolean;
}

const Item = ({ nr, title, id, person, completed }: ItemProps) => {
  const routeMatch = useRouteMatch();

  // const personName = `${person?.last_name || ""} ${person?.first_name || ""}`;

  const tagRef = React.useRef<HTMLDivElement>(null);
  const [tagWidth, setTagWidth] = React.useState(0);

  React.useEffect(() => {
    setTagWidth(tagRef.current?.getBoundingClientRect().width || 0);
  }, [person]);

  return (
    <div className={bem("list-item-wrapper")}>
      <Link
        to={`${routeMatch.url}/${id}`}
        className={bem("list-item")}
        // style={
        //   {
        //     "--progress": Math.floor(Math.random() * 100),
        //   } as DynamicCssProperties
        // }
        tabIndex={0}
      >
        <Flex align="center">
          <Badge
            className="fw-600"
            color={completed ? "success" : "danger"}
            variant="subtle"
          >
            {nr}
          </Badge>
          <WhiteSpace h="1rem" />
          <h4 className="flex-1">{title}</h4>
          <WhiteSpace h="1rem" />
          <WhiteSpace h={tagWidth} />
        </Flex>
      </Link>
      {/* <div ref={tagRef} className={bem("list-item-side")}>
        {person ? (
          <Badge
            round
            suffix={
              <Button
                icon={() => <Icon type="close" size="1em" />}
                className="btn-xsm"
                round
                type="text"
              />
            }
          >
            {personName}
          </Badge>
        ) : (
          <Button icon={() => <Icon type="task" color="gray" />} size="small" />
        )}
      </div> */}
    </div>
  );
};
