import { Card, Button, Space } from "ebs-design";

import {
  Flex,
  WhiteSpace,
  Grid,
  Icon,
  ViewPdfModal,
} from "@aeo/core/components";
import { useModalState, useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";

import { useApplication } from "../../hooks";
import { ControlOrderDetails } from "./ControlOrderDetails";
import { ControlOrderModal } from "./ControlOrderModal";

export const ControlOrderReAudit = () => {
  const application = useApplication();
  const controlOrderModal = useModalState();
  const modalPdf = useModalState<models.Attachment>();

  const { isManager } = useRole(application?.organization?.manager?.id);

  const canCreateReAuditOrder =
    isManager && application.permissions?.can_re_order;

  return (
    <>
      <Card>
        <Card.Header>
          <Flex align="center" justify="space-between">
            <h3>Ordin de control</h3>
            <WhiteSpace h="1rem" />
            <Space>
              {canCreateReAuditOrder && (
                <Button
                  size="medium"
                  type="primary"
                  prefix={<Icon type="edit" />}
                  onClick={controlOrderModal.open}
                >
                  Crează Ordin
                </Button>
              )}

              {application?.re_order?.signed_document && (
                <Space>
                  <Button
                    size="medium"
                    type="primary"
                    prefix={<Icon type="open-eye" />}
                    onClick={() =>
                      application?.re_order?.signed_document &&
                      modalPdf.openWith(application?.re_order?.signed_document)
                    }
                  >
                    Ordin de control
                  </Button>

                  {application?.re_order?.can_edit_re_order && isManager && (
                    <Button
                      size="medium"
                      type="primary"
                      prefix={<Icon type="edit" />}
                      onClick={controlOrderModal.open}
                    >
                      Modifică Ordin
                    </Button>
                  )}
                </Space>
              )}
            </Space>
          </Flex>
        </Card.Header>
        {application?.re_order && (
          <Card.Body>
            <Grid gap="4rem">
              <ControlOrderDetails order={application?.re_order} />
            </Grid>
          </Card.Body>
        )}
      </Card>

      {controlOrderModal.isOpen && application && (
        <ControlOrderModal
          open={controlOrderModal.isOpen}
          onClose={controlOrderModal.close}
          canCreateOrder={canCreateReAuditOrder}
          application={application}
          size="large"
          state="re-audit"
          title="Modificare Ordin de Control"
        />
      )}
      {modalPdf.isOpen && (
        <ViewPdfModal
          file={modalPdf?.data?.file_upload}
          fileTitle={modalPdf?.data?.title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title="Ordin de Control"
          size="large"
        />
      )}
    </>
  );
};
