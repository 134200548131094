import { DynamicCssProperties } from "../types";

export interface CircularProgressProps {
  value: number;
  color?: string;
  circleWidth?: number;
  fontSize?: number | string;
  padding?: number | string;
  circleOpacity?: number | string;
  digitsAfterPoint?: number;
  content?: JSX.Element | string;
}

export const CircularProgress = ({
  value = 0,
  color = "#01689a",
  circleWidth = 8,
  fontSize = "1em",
  padding = 0,
  circleOpacity = 0.3,
  digitsAfterPoint = 2,
  content,
}: CircularProgressProps) => {
  const r = 50 - circleWidth / 2;

  return (
    <div
      className="circular-progress"
      style={{ fontSize, display: "inline-block", padding }}
    >
      <svg viewBox="0 0 100 100">
        <circle
          cx={50}
          cy={50}
          r={r}
          strokeWidth={circleWidth}
          stroke={color}
          fill="none"
          strokeOpacity={circleOpacity}
        />
        <circle
          cx={50}
          cy={50}
          r={r}
          strokeWidth={circleWidth}
          stroke={color}
          strokeDasharray={`${Math.PI * 2 * r * (value / 100)} 500`}
          fill="none"
        />
      </svg>
      <div
        className="circular-progress__text"
        style={
          {
            "--color": color,
          } as DynamicCssProperties
        }
      >
        {content}
        {!content && `${value.toFixed(digitsAfterPoint)}%`}
      </div>
    </div>
  );
};
