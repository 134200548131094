export const approvalTypeContent = {
  suspend_approval: "suspend approval",
  resume_approval: "resume approval",
  withdraw_approval: "withdraw approval",
  reject_approval: "reject approval",
  audit_approval: "audit approval",
  report_audit_approval: "report audit approval",
  list_pre_audit_approval: "list pre audit approval",
  plan_pre_audit_approval: "plan pre audit approval",
  changed_order_audit_approval: "changed order audit approval",
  approved_application_approval: "approved application approval",
  re_audit_approval: "re audit approval",
  active_application_approval: "active application approval",
  report_re_audit_approval: "report re audit approval",
  changed_order_re_audit_approval: "changed order re audit approval",
  revoked_approval: "revoked approval",
  annulled_approval: "annulled approval",
};
