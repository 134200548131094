import { TabLabel, BadgeTabLabel } from "@aeo/core/components";
import models from "@aeo/core/models";

import {
  ActiveApplications,
  DraftApplications,
} from "features/applications/components";
import {
  Audit,
  PreAudit,
  ReAudit,
  Summary,
  Authorization,
} from "../components";

import { AuthorizationLogo } from "../components/AuthorizationLogo";

export const applicationsTabs = [
  {
    label: <BadgeTabLabel title="Active" />,
    route: "active",
    content: <ActiveApplications />,
  },
  {
    label: <BadgeTabLabel title="Draft" />,
    route: "draft",
    content: <DraftApplications />,
  },
];

export const applicationFlowTabs: models.TabsEntity[] = [
  {
    label: <TabLabel index={1} title="Cererea" />,
    route: "details",
    content: <Summary />,
    states: [
      models.ApplicationState.registered,
      models.ApplicationState.suspend_registered,
      models.ApplicationState.rejected_registered,
      models.ApplicationState.withdraw_registered,
      models.ApplicationState.upgraded,
    ],
  },
  {
    label: <TabLabel index={2} title="Examinare prealabilă" />,
    route: "pre-audit",
    content: <PreAudit />,
    states: [
      models.ApplicationState.suspend_pre_audit,
      models.ApplicationState.withdraw_pre_audit,
      models.ApplicationState.pre_audit,
      models.ApplicationState.rejected_pre_audit,
    ],
  },
  {
    label: <TabLabel index={3} title="Audit" />,
    route: "audit",
    content: <Audit />,
    states: [
      models.ApplicationState.audit,
      models.ApplicationState.withdraw_audit,
      models.ApplicationState.suspend_audit,
      models.ApplicationState.approved,
      models.ApplicationState.rejected_audit,
    ],
  },
  {
    label: <TabLabel index={4} title="Autorizatia" />,
    route: "authorization",
    content: <Authorization />,
    states: [
      models.ApplicationState.revoked,
      models.ApplicationState.annul,
      models.ApplicationState.suspend_active,
      models.ApplicationState.active,
      models.ApplicationState.withdraw_active,
      models.ApplicationState.approved,
    ],
  },
  {
    label: <TabLabel index={5} title="Logo" />,
    route: "logo",
    content: <AuthorizationLogo />,
    states: [
      models.ApplicationState.revoked,
      models.ApplicationState.annul,
      models.ApplicationState.suspend_active,
      models.ApplicationState.active,
      models.ApplicationState.withdraw_active,
    ],
  },
  {
    label: <TabLabel index={6} title="Reaudit" />,
    route: "re-audit",
    content: <ReAudit />,
    states: [
      models.ApplicationState.active,
      models.ApplicationState.re_audit,
      models.ApplicationState.suspend_re_audit,
      models.ApplicationState.withdraw_re_audit,
      models.ApplicationState.rejected_re_audit,
    ],
  },
];
