import { TableColumnBuilder, formattedDateTime } from "@aeo/core/utils";
import models from "@aeo/core/models";

export const approvalsColumns = () =>
  new TableColumnBuilder<models.Approval>()
    .add("Numărul de înregistrare", "number")
    .add("Numărul cererii", ["application", "id"])
    .add("Denumirea documentului", "title")
    .add("Operator economic", ["organization", "name"])
    .add("Persoana responsabilă", ["manager", "name"])
    .add("Data creării", "created_at", formattedDateTime)
    .add("Creat de", ["owner", "name"])
    .add("Termenul", "period")
    .getColumns();
