import { Card, Button } from "ebs-design";
import { Icon } from "@aeo/core/components";

import { useApplication } from "../../hooks";
import { Flex, PDFViewer } from "@aeo/core/components";
import { useDownloadFile } from "@aeo/core/hooks";
import models from "@aeo/core/models";

export const Authorization = () => {
  const application = useApplication();
  const downloadFile = useDownloadFile();
  const attachment: models.Attachment = {
    file_upload: application?.authorization?.file_upload,
    title: `Cerere_Nr_${application?.id}_statut_${application?.type}`,
  };

  return (
    <>
      <Card className="authorizations">
        <Card.Header>
          <Flex justify={"space-between"} align={"center"}>
            <span>
              Cererea Nr. <strong>{application?.id || ""}</strong> cu statutul{" "}
              <strong>{application?.type || ""}</strong>
            </span>
            <Button
              prefix={<Icon style={{ display: "block" }} type="download" />}
              disabled={!application?.authorization?.file_upload}
              onClick={() => downloadFile(attachment)}
            >
              Descarcă
            </Button>
          </Flex>
        </Card.Header>
        <Card.Body>
          <PDFViewer
            file={application?.authorization?.file_upload || ""}
            renderMode="canvas"
          />
        </Card.Body>
      </Card>
    </>
  );
};
