import { UserRoles } from "../utils";
import { useAuth } from "./useAuth";

export type UseRole = ReturnType<typeof useRole>;

export const useRole = (id?: number) => {
  const { role, user } = useAuth();

  return {
    isAdmin: role === UserRoles.admin,
    isUser: role === UserRoles.user,
    isSuperuser: role === UserRoles.superuser,
    isManager: user?.id === id || role === UserRoles.superuser,
  };
};
