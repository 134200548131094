import React from "react";
import { useUpdateEffect } from "react-use";
import { useQuery } from "react-query";

import { QueryTable, TableTitle, WhiteSpace } from "@aeo/core";
import { useOrderingQueryParam, useQueryPagination } from "@aeo/core/hooks";

import api, { querykeys } from "api";
import { ApplicationsFilters } from "features/applications/components";
import { useApplicationsFilterParams } from "features/applications/hooks";
import { authorizationsColumns } from "../config";
import models from "@aeo/core/models";

export const Authorizations = () => {
  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useApplicationsFilterParams();

  const queryParams = {
    ...pagination.queryParams,
    ...filterParams,
    search,
    ordering,
  };

  const query = useQuery(querykeys.authorizations.many(queryParams), () =>
    api.approvals.authorizations(queryParams),
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <div>
      <h2>Autorizații</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <QueryTable
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            sortOptions={[{ title: "Data creării", value: "created_at" }]}
            onSortChange={setOrdering}
            filters={
              <ApplicationsFilters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
                options={["type", "state"]}
                excludeStates={[
                  models.StatusGroups.COMPLETION,
                  models.StatusGroups.APPLICATION,
                  models.StatusGroups.EXAMINATION,
                  models.StatusGroups.AUDIT,
                ]}
              />
            }
          />
        )}
        query={query}
        columns={authorizationsColumns()}
      />
    </div>
  );
};
