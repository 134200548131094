import React from "react";
import { useUpdateEffect } from "react-use";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Button, useNotify } from "ebs-design";

import { DeleteModal, QueryTable, TableTitle } from "@aeo/core/components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
  useRole,
} from "@aeo/core/hooks";

import api, { querykeys } from "api";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";
import { currentColumns, orderingOptions } from "features/tasks/config";
import {
  TaskCreationModal,
  ViewModal,
  TasksFilters,
} from "features/tasks/components";

import { useTasksFilterParams } from "../hooks";

export const CurrentTasks = () => {
  const [search, setSearch] = React.useState("");
  const role = useRole();
  const notify = useNotify();

  const contentModal = useModalState<models.Task>();
  const viewModal = useModalState<models.Task>();
  const deleteModal = useModalState();

  const pagination = useQueryPagination();
  const queryClient = useQueryClient();

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useTasksFilterParams();

  const queryParams = {
    ...pagination.queryParams,
    done: false,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.tasks.many(queryParams), () =>
    api.tasks.get(queryParams),
  );

  const deleteMutation = useMutation(
    () => api.tasks.delete(deleteModal.data as number),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.tasks.many());
        deleteModal.close();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      currentColumns({
        onCreate: contentModal.openWith,
        onDelete: deleteModal.openWith,
        onDone: viewModal.openWith,
        role: role,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentModal.openWith, deleteModal.openWith, viewModal.openWith],
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <>
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          onClick: () => {
            viewModal.openWith(data);
          },
        })}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <TasksFilters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              />
            }
            sortOptions={orderingOptions}
            onSortChange={setOrdering}
            extra={
              <Button type="primary" onClick={contentModal.open}>
                Adaugă sarcină
              </Button>
            }
          />
        )}
        query={query}
        columns={tableColumns}
        scroll={{ x: 1440 }}
      />
      <ViewModal
        open={viewModal.isOpen}
        onClose={viewModal.close}
        title="Marchează completat"
        size="regular"
        task={viewModal.data}
      />
      <TaskCreationModal
        open={contentModal.isOpen}
        onClose={contentModal.close}
        size="small"
        task={contentModal.data}
      />

      <DeleteModal
        open={deleteModal.isOpen}
        onClose={() => {
          deleteModal.close();
          deleteMutation.reset();
        }}
        deleteMutation={deleteMutation}
        title="Șterge sarcina"
      >
        Doriți să ștergeți sarcina cu numărul{" "}
        <strong>{deleteModal?.data as string}</strong>?
      </DeleteModal>
    </>
  );
};
