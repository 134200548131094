import models from "@aeo/core/models";
import { apiAxios } from "@aeo/core/api/axios";
import { jsonFromBufferTransformer, stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types";

export const approvals = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/approvals", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Approval>>(
      url,
    );
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.Approval>(`/cs/approvals/${id}`);
    return data;
  },
  getOrders: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/approvals/orders", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Approval>>(
      url,
    );
    return data;
  },
  document: async (id: number, body: models.ContentEntity) => {
    const { data } = await apiAxios.post(`/cs/approvals/${id}/document`, body);
    return data;
  },
  documentPreview: async (id: number, body: models.ContentEntity) => {
    const { data }: { data: ArrayBuffer } = await apiAxios.post(
      `/cs/approvals/${id}/document/preview`,
      body,
      {
        responseType: "arraybuffer",
        transformResponse: jsonFromBufferTransformer,
      },
    );
    return data;
  },
  sign: async (id: number) => {
    const { data } = await apiAxios.patch<models.Approval>(
      `/cs/approvals/${id}/sign`,
    );
    return data;
  },
  approve: async (id: number) => {
    const { data } = await apiAxios.patch<models.Approval>(
      `/cs/approvals/${id}/approve`,
    );
    return data;
  },
  reject: async (id: number, body: { reason: string }) => {
    const { data } = await apiAxios.patch(`/cs/approvals/${id}/reject`, body);
    return data;
  },
  authorizations: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/approvals/authorizations", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.Authorization>
    >(url);
    return data;
  },
};
