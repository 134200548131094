import { Tax, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const Turnover = () => {
  const turnover = fields.turnover;

  return (
    <>
      <KeyValueRow {...turnover.annual_turnover} type="default" NA />
      <KeyValueRow {...turnover.annual_profit} type="default" NA />
      <KeyValueRow {...turnover.rented_space} />
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />
      <p>{turnover.declarations.label}</p>
      <KeyValueRow {...turnover.import_declaration} type="declaration" NA />
      <KeyValueRow {...turnover.export_declaration} type="declaration" NA />
      <KeyValueRow {...turnover.suspended_mode} type="declaration" NA />
      <KeyValueRow {...turnover.customs_destination} type="declaration" NA />
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />
      <Tax />
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...turnover.any_structural_changes} type="boolean" />
      <KeyValueRow {...turnover.structural_changes} />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...turnover.any_supply_chain_changes} type="boolean" />
      <KeyValueRow {...turnover.supply_chain_changes} />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
