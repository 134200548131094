import React from "react";
import { useMutation } from "react-query";
import { Button, useNotify } from "ebs-design";

import { notifyErrors } from "@aeo/core/utils";
import { EditorModal } from "@aeo/core/components";
import { useModalState } from "@aeo/core/hooks";
import { notifications } from "@aeo/core/messages/notifications";
import coreApi from "@aeo/core/api";

import api from "api";

interface Props {
  applicationId: number;
}

export const SendLetter = ({ applicationId }: Props) => {
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const formModal = useModalState();
  const notify = useNotify();

  const actionMutation = useMutation(
    api.applications.postInfo.bind(null, applicationId),
    {
      onSuccess: () => {
        notify.success({ title: notifications.sentLetter });
        formModal.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const previewMutation = useMutation(
    coreApi.nomenclatures.orderPreview.bind(null),
    {
      onSuccess: (value) => setPdfFile(value),
      onError: (errors) => {
        notifyErrors(notify, errors);
        setPdfFile(undefined);
      },
    },
  );

  return (
    <>
      <Button
        className="no-wrap"
        type="ghost"
        size="medium"
        onClick={() => formModal.openWith({ isOpen: true })}
      >
        Expediere scrisoare
      </Button>

      {formModal.isOpen && (
        <EditorModal
          open={formModal.isOpen}
          onClose={formModal.close}
          title="Expediere scrisoare către operatorul economic"
          mutation={actionMutation}
          previewMutation={previewMutation}
          pdfFile={pdfFile}
          setPdfFile={setPdfFile}
          withAttachments
        />
      )}
    </>
  );
};
