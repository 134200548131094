import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types";
import models from "@aeo/core/models";

export const applications = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/applications", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.ActiveApplication>
    >(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.ActiveApplication>(
      `/cs/applications/${id}`,
    );
    return data;
  },

  updateState: async (
    id: number,
    query: models.ChangeApplicationStatusEntity,
  ) => {
    query.endpoint = query.endpoint || `applications`;
    const { data } = await apiAxios[query.method](
      `/cs/${query.endpoint}/${id}/${query.state}`,
      query.body,
    );

    return data;
  },

  postInfo: async (id: number, body: models.ContentEntityWithAttachment) => {
    const { data } = await apiAxios.post(`/cs/applications/${id}/info`, body);

    return data;
  },

  updateStateInfo: async (
    id: number,
    query: models.ChangeApplicationStatusEntity,
  ) => {
    const { data } = await apiAxios[query.method](
      `/cs/applications/${id}/info/${query.state}`,
      query.body,
    );

    return data;
  },

  changeAppType: async (
    id: number,
    requestType: "upgrade" | "downgrade",
    values?: { type: models.AEOType; content?: string },
  ) => {
    const { data } = await apiAxios.patch(
      `/cs/transform/${id}/${requestType}`,
      values,
    );
    return data;
  },

  getDocument: async (id: number) => {
    const { data } = await apiAxios.get<models.Agent>(
      `/cs/organizations/${id}`,
    );

    return data;
  },
};
