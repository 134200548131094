import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const Turnover = () => {
  const turnover = fields.turnover;

  return (
    <>
      <ExaminationCard {...turnover.annual_turnover} type="default" NA />

      <ExaminationCard {...turnover.annual_profit} type="default" NA />
      <ExaminationCard {...turnover.rented_space} />

      <ExaminationCard
        {...turnover.has_declarations}
        label={turnover.declarations.label}
        type="boolean"
        NA
      />
      <ExaminationCard {...turnover.import_declaration} type="declaration" NA />
      <ExaminationCard {...turnover.export_declaration} type="declaration" NA />
      <ExaminationCard {...turnover.suspended_mode} type="declaration" NA />
      <ExaminationCard
        {...turnover.customs_destination}
        type="declaration"
        NA
      />

      <ExaminationCard {...turnover.tax} type="boolean" NA />

      <ExaminationCard {...turnover.tax.customs_taxes} type="declaration" NA />
      <ExaminationCard
        {...turnover.tax.customs_procedures}
        type="declaration"
        NA
      />
      <ExaminationCard {...turnover.tax.excise} type="declaration" NA />
      <ExaminationCard {...turnover.tax.vat} type="declaration" NA />

      <ExaminationCard {...turnover.any_structural_changes} type="boolean" />
      <ExaminationCard {...turnover.structural_changes} />

      <ExaminationCard {...turnover.any_supply_chain_changes} type="boolean" />
      <ExaminationCard {...turnover.supply_chain_changes} />

      <ExaminationCard attachmentList />
    </>
  );
};
