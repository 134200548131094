import React from "react";
import { Button, Checkbox } from "ebs-design";

import {
  formattedDateTime,
  TableColumnBuilder,
  compose,
  userRoles,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import { ColumnNoWrap, Icon } from "@aeo/core/components";
import { StatusBadge, StatusApprovalBadge } from "components";

interface Args {
  modal: React.Dispatch<React.SetStateAction<models.Complaint | undefined>>;
  isManager: boolean;
}
interface AgentsArgs {
  onCheck: (row: models.Agent) => void;
  data: models.Agent[];
  showModal: React.Dispatch<any>;
}

export const agentsColumns = ({ onCheck, data, showModal }: AgentsArgs) =>
  new TableColumnBuilder<models.Agent>()
    .add("", "", (row: models.Agent) => (
      <div
        className="cell-checkbox"
        onClick={(e) => {
          e.stopPropagation();
          onCheck({
            id: row?.id,
            name: row?.name,
            idno: row?.idno,
            active_application: {
              state: row?.active_application?.state,
              request: {
                applicant_address:
                  row?.active_application?.request?.applicant_address,
              },
              type: row?.active_application?.type,
            },
            manager: {
              name: row?.manager?.name || "",
            },
          });
        }}
      >
        <Checkbox checked={!!data?.find((v) => v.id === row?.id)} />
      </div>
    ))
    .add("Numărul de înregistrare", ["active_application", "id"])
    .add(
      "Data înregistrării",
      ["active_application", "created_at"],
      compose(formattedDateTime, ColumnNoWrap),
    )
    .add("IDNO", "idno")
    .add("Adresa juridică", [
      "active_application",
      "request",
      "applicant_address",
    ])
    .add("Operator economic", "name")
    .add(
      "Starea",
      ["active_application", "state"],
      (state: models.ApplicationState) => <StatusBadge state={state} />,
    )
    .add("Tipul autorizației", ["active_application", "type"])
    .add("Persoana de contact", [
      "active_application",
      "request",
      "contact_name",
    ])
    .getColumns();

export const agentRequestsColumns = () =>
  new TableColumnBuilder<models.ActiveApplication>()
    .add("Nr cererii", "id", undefined, { width: 100 })
    .add("Statut", "state", (state: models.ApplicationState) => (
      <StatusBadge state={state} />
    ))
    .add("Tipul autorizației", "type")
    .add(
      "Data depunerei",
      "created_at",
      compose(formattedDateTime, ColumnNoWrap),
      {
        align: "right",
      },
    )
    .getColumns();

export const agentAuditsColumns = () =>
  new TableColumnBuilder<models.AuditApproval>()
    .add("Nr cererii", "application", undefined, { width: 100 })
    .add("Statut", "state", (state: models.ApprovalState) => (
      <StatusApprovalBadge state={state} />
    ))
    .add(
      "Data auditului",
      "modified_at",
      compose(formattedDateTime, ColumnNoWrap),
      {
        align: "right",
      },
    )
    .getColumns();

export const agentReauditsColumns = () =>
  new TableColumnBuilder<models.AuditApproval>()
    .add("Nr cererii", "application", undefined, { width: 100 })
    .add("Statut", "state", (state: models.ApprovalState) => (
      <StatusApprovalBadge state={state} />
    ))
    .add(
      "Data reauditului",
      "modified_at",
      compose(formattedDateTime, ColumnNoWrap),
      {
        align: "right",
      },
    )
    .getColumns();

export const agentAeoComplaintsColumns = ({ modal, isManager }: Args) =>
  new TableColumnBuilder<models.Complaint>()
    .add("Sesizare", "body", undefined, { width: 375 })
    .add("Autor", ["user", "name"], ColumnNoWrap)
    .add("Răspuns", ["reply", "body"])
    .add("Data", "created_at", compose(formattedDateTime, ColumnNoWrap))
    .add(
      "",
      "",
      (_, record) => (
        <>
          {isManager && (
            <Button
              onClick={() => modal(record)}
              icon={() => <Icon type="reply" />}
              disabled={!!record.reply}
            />
          )}
        </>
      ),
      {
        align: "right",
      },
    )
    .getColumns();

export const agentSvComplaintsColumns = () =>
  new TableColumnBuilder<any>()
    .add("Sesizare", "body", undefined, { width: 375 })
    .add("Autor", ["user", "name"], ColumnNoWrap)
    .add("Data", "created_at", compose(formattedDateTime, ColumnNoWrap), {
      align: "right",
    })
    .getColumns();

export const agentLogsColumns = () =>
  new TableColumnBuilder<models.AsycudaLogs>()
    .add("Acțiune", "title")
    .add("Data", "created_at", formattedDateTime)
    .add("Utilizator ", ["user", "name"])
    .add("E-mail", ["user", "email"])
    .add("IDNP", ["user", "idnp"])
    .add("Telefon", ["user", "phone"])
    .add("Funcție", ["user", "profile", "function"])
    .add(
      "Rol",
      ["user", "profile", "role"],
      (record: keyof typeof userRoles) => <>{userRoles[record]}</>,
    )
    .getColumns();
