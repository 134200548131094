import { useQuery } from "react-query";
import { Card } from "ebs-design";

import { CheckCrossIcon, Grid, WhiteSpace } from "@aeo/core/components";
import { formattedDate } from "@aeo/core/utils";
import api, { querykeys } from "@aeo/mcs/src/api";
import { useApplication } from "../hooks";

export const CompanyDetails: React.FC = ({ children }) => {
  const application = useApplication();
  const { data } = useQuery(
    querykeys.asycuda.organization,
    () => api.asycuda.getOrganization(application?.organization?.idno!),
    { enabled: !!application?.organization?.idno },
  );

  const asycudaOrganization = data?.results ? data?.results[0] : undefined;

  return (
    <Card>
      <Card.Header>
        <h3>Despre companie</h3>
      </Card.Header>
      <Card.Body>
        <h3 className="uppercase">Informaţii generale privind compania</h3>
        <WhiteSpace v="1rem" />
        <Grid cols="1fr 1fr" gap="1rem">
          <div className="color-gray">Denumirea companiei:</div>
          <div>{application?.organization?.name}</div>
          <div className="color-gray">IDNO:</div>
          <div>{application?.organization?.idno}</div>
          <div className="color-gray">Reprezentant:</div>
          <div>
            {application?.organization?.admin_last_name}&thinsp;
            {application?.organization?.admin_first_name}
          </div>
          <div className="color-gray">Adresă:</div>
          <div>{application?.request?.activity_address}</div>
          <div className="color-gray">Telefon:</div>
          <div>{application?.request?.contact_phone}</div>
          <div className="color-gray">E-mail:</div>
          <div>{application?.request?.contact_email}</div>
          <div className="color-gray">Tipul AEO:</div>
          <div>{application?.type}</div>
        </Grid>

        <WhiteSpace v="1rem" />
        <div className="divider"></div>
        <WhiteSpace v="1rem" />

        <h3 className="uppercase">Eligibilitatea cererii</h3>
        <WhiteSpace v="1rem" />
        <Grid cols="1fr 1fr" gap="1rem">
          <div className="color-gray">
            Caracterul complet și exactitatea cererii:
          </div>
          <div>
            <CheckCrossIcon checked />
          </div>
          <div className="color-gray">Operatorul are reședință în Moldova:</div>
          <div>
            <CheckCrossIcon checked />
          </div>
          <div className="color-gray">
            Operatorul este înregistrat la Autoritatea Vamală:
          </div>
          <div>
            <CheckCrossIcon checked />
          </div>
          <div className="color-gray">
            Nici o anulare sau revocare anterioară a unei cereri / autorizații
            AEO:
          </div>
          <div>
            <CheckCrossIcon
              checked={!application?.organization?.has_inactive_statuses}
            />
          </div>
        </Grid>

        <WhiteSpace v="1rem" />
        <div className="divider"></div>
        <WhiteSpace v="1rem" />

        <h3 className="uppercase">Date de la Asycuda</h3>
        <WhiteSpace v="1rem" />
        <Grid cols="1fr 1fr" gap="1rem">
          <div className="color-gray">Denumirea companiei:</div>
          <div>{asycudaOrganization?.name}</div>
          <div className="color-gray">IDNO:</div>
          <div>{asycudaOrganization?.identifier}</div>
          <div className="color-gray">Adresă:</div>
          <div>
            {asycudaOrganization?.address}
            <br />
            {asycudaOrganization?.region}
          </div>
          <div className="color-gray">Telefon:</div>
          <div>{asycudaOrganization?.phone}</div>
          <div className="color-gray">Data:</div>
          <div>{formattedDate(asycudaOrganization?.valid_from)}</div>
        </Grid>

        <WhiteSpace v="1rem" />
        <div className="divider"></div>
        <WhiteSpace v="1rem" />

        {children}
      </Card.Body>
    </Card>
  );
};
