import messages from "../messages";

export const createCounter = () => {
  let major = 0;
  let minor = 0;
  const msgs = messages.SAQTabs;

  return function nr<Key extends keyof typeof msgs>(
    // Type trick to hide the keys pointing to objects without "title"
    key: typeof msgs[Key] extends { title: string } ? Key : never,
    withMinor?: boolean,
  ) {
    const msg = msgs[key];

    if (withMinor) {
      minor === 0 && major++;
      minor++;
    } else {
      minor = 0;
      major++;
    }

    return `SAQ: ${withMinor ? `${major}.${minor}` : major}. ${
      "title" in msg ? msg.title : ""
    }`;
  };
};

export const titleWithSAQ = (key: keyof typeof messages.SAQTabsContent) => {
  const msgs = messages.SAQTabsContent;
  const msg = msgs[key];

  return `SAQ: ${msg.title}`;
};
