import { useQuery } from "react-query";
import { Form } from "ebs-design";

import coreApi, { querykeys } from "@aeo/core/api";
import { FakeInput } from "@aeo/core/components";
import { FormControl } from "@aeo/core/types";
import { useModalState } from "@aeo/core/hooks";

import { CriterionSelectModal } from ".";

export const CriterionSelectField = () => {
  const modal = useModalState();

  const query = useQuery(querykeys.nomenclatures.criterions.many(), () =>
    coreApi.nomenclatures.criterions.get(),
  );

  const criterions = query.data?.results;

  return (
    <>
      <Form.Field
        name="criterion"
        label="Criteriu"
        rules={[{ required: true }]}
      >
        {(_control) => {
          const control = _control as FormControl<number>;

          let inputValue = criterions?.find(
            (crit) => crit.id === control.value,
          )?.name;

          if (!inputValue) {
            criterions?.forEach((crit) =>
              crit.children?.find((subcrit) =>
                subcrit.id === control.value
                  ? (inputValue = subcrit.name)
                  : undefined,
              ),
            );
          }

          return (
            <>
              <FakeInput
                value={inputValue}
                onClick={modal.open}
                onClear={() => control.onChange?.(undefined)}
                isClearable
                cursor="pointer"
                loading={query.isLoading}
              />
              <CriterionSelectModal
                query={query}
                selected={control.value}
                open={modal.isOpen}
                onClose={modal.close}
                onSelect={control.onChange}
              />
            </>
          );
        }}
      </Form.Field>
    </>
  );
};
