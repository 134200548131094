import { Grid } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { useGetFieldValue } from "@aeo/core/hooks";

export const Competent = () => {
  const securityOne = fields.securityOne.competent;

  const getValue = useGetFieldValue();

  return (
    <div>
      <Grid cols="repeat(4, 1fr)" gap="0.25rem 1rem">
        <span className="color-gray">{securityOne.first_name.label}:</span>
        <span>{getValue(securityOne.first_name)}</span>
        <span className="color-gray">{securityOne.last_name.label}:</span>
        <span>{getValue(securityOne.last_name)}</span>
        <span className="color-gray">{securityOne.function.label}:</span>
        <span>{getValue(securityOne.function)}</span>
      </Grid>
    </div>
  );
};
