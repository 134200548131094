import React from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { useHistory } from "react-router-dom";
import {
  useQueryParams,
  NumericArrayParam,
  ArrayParam,
  StringParam,
} from "use-query-params";
import { Button, useNotify } from "ebs-design";

import {
  ChoiceFilter,
  DateRangeFilter,
  EditorModal,
  Filters,
  Flex,
  EditorModalProps,
  QueryTable,
  RoleGuard,
  TableTitle,
  WhiteSpace,
} from "@aeo/core/components";
import {
  useModalState,
  useOrderingQueryParam,
  usePagination,
  useRole,
} from "@aeo/core/hooks";
import { forwardSetState, notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";
import { agentsColumns } from "features/agents/columns";
import { orderingOptions } from "features/agents/config";
import { AssignModal } from "features/applications/components/AssignModal";
import { monitoringTablePlan, reevaluateTablePlan } from "../config/plans";

export const AgentsTable = () => {
  const [search, setSearch] = React.useState("");
  const [checkedList, setCheckedList] = React.useState<models.Agent[]>([]);
  const [ordering, setOrdering] = useOrderingQueryParam();
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const history = useHistory();
  const pagination = usePagination();
  const modalState = useModalState<EditorModalProps["data"]>();
  const modal = useModalState<{ id: number }>();
  const notify = useNotify();
  const { isAdmin } = useRole();

  const [filterParams, setFilterParams] = useQueryParams({
    type: ArrayParam,
    manager_id: NumericArrayParam,
    created_at_after: StringParam,
    created_at_before: StringParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.agents.many(queryParams), () =>
    api.agents.get(queryParams),
  );

  const actionMutation = useMutation(
    api.agents.organizationActions.bind(null, modalState.data?.action!),
    {
      onSuccess: () => {
        notify.success({ title: notifications.monitoringPlan });
        modalState.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const previewMutation = useMutation(
    api.agents.organizationActionsPreview.bind(null, modalState.data?.action!),
    {
      onSuccess: (value) => setPdfFile(value),
      onError: notifyErrors.bind(null, notify),
    },
  );

  const handleChangeCheckbox = (agent: models.Agent) =>
    !!checkedList.find((v) => v.id === agent.id)
      ? setCheckedList((prev) => prev.filter((item) => item.id !== agent.id))
      : setCheckedList((prev) => [...prev, agent]);

  const tableColumns = agentsColumns({
    onCheck: handleChangeCheckbox,
    data: checkedList,
    showModal: modal.openWith,
  });

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  const monitoringText = React.useMemo(
    () => monitoringTablePlan(checkedList),
    [checkedList],
  );
  const reevaluateText = React.useMemo(
    () => reevaluateTablePlan(checkedList),
    [checkedList],
  );

  return (
    <>
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <Filters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              >
                <ChoiceFilter
                  value={filterParams.type}
                  setValue={forwardSetState(setFilterParams, "type")}
                  title="Tipul"
                  choice={Object.values(models.AEOType).map((type) => ({
                    key: type,
                    value: type,
                  }))}
                />
                <DateRangeFilter
                  title="Data creării"
                  after={filterParams.created_at_after}
                  before={filterParams.created_at_before}
                  setAfter={forwardSetState(
                    setFilterParams,
                    "created_at_after",
                  )}
                  setBefore={forwardSetState(
                    setFilterParams,
                    "created_at_before",
                  )}
                />
              </Filters>
            }
            extra={
              <RoleGuard restritedTo={["admin"]}>
                <Flex>
                  <Button
                    type="primary"
                    onClick={() =>
                      !!checkedList.length
                        ? modalState.openWith({
                            action: "monitoring-plan",
                            initialValue: {
                              content: monitoringText,
                              name: "Crează Plan Monitorizare",
                            },
                          })
                        : notify.warning({
                            title: "Selectează cel puţin un operator economic.",
                          })
                    }
                  >
                    Crează Plan Monitorizare
                  </Button>
                  <WhiteSpace h="1rem" />
                  <Button
                    type="primary"
                    onClick={() =>
                      !!checkedList.length
                        ? modalState.openWith({
                            action: "reevaluation-plan",
                            initialValue: {
                              content: reevaluateText,
                              name: "Crează Plan Reevaluare",
                            },
                          })
                        : notify.warning({
                            title: "Selectează cel puţin un operator economic.",
                          })
                    }
                  >
                    Crează Plan Reevaluare
                  </Button>
                </Flex>
              </RoleGuard>
            }
            sortOptions={orderingOptions}
            onSortChange={setOrdering}
          />
        )}
        onRow={(data, _index) => ({
          onClick: () => history.push(`/agents/${data.id}`),
        })}
        variants={["highlight-hover"]}
        query={query}
        columns={
          !isAdmin ? tableColumns : tableColumns.filter((_, i) => i !== 0)
        }
      />
      {modalState.isOpen && (
        <EditorModal
          open={modalState.isOpen}
          onClose={modalState.close}
          data={modalState.data}
          mutation={actionMutation}
          previewMutation={previewMutation}
          pdfFile={pdfFile}
          withAttachments
        />
      )}
      {modal.isOpen && (
        <AssignModal
          open={modal.isOpen}
          onClose={modal.close}
          organization={modal?.data}
          queryParams={queryParams}
        />
      )}
    </>
  );
};
