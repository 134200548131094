import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const asycuda = {
  getOrganization: async (id: string) => {
    const { data } = await apiAxios.get<
      models.WithResults<models.AsycudaOrganization>
    >(`/cs/customs-system/organizations?identifier=${id}`);
    return data;
  },
  getDeclaration: async (id: string) => {
    const { data } = await apiAxios.get<
      models.WithResults<models.AsycudaDeclaration>
    >(`/cs/customs-system/declarations?identifier=${id}`);
    return data;
  },
  getDeclarationDetails: async (id: string) => {
    const { data } = await apiAxios.get<models.AsycudaDeclaration>(
      `/cs/customs-system/declarations/${id}`,
    );
    return data;
  },
  getItems: async (id: string) => {
    const { data } = await apiAxios.get<models.WithResults<models.AsycudaItem>>(
      `/cs/customs-system/items?identifier=${id}`,
    );
    return data;
  },
  getItemDetails: async (id: string) => {
    const { data } = await apiAxios.get<models.AsycudaItem>(
      `/cs/customs-system/items/${id}`,
    );
    return data;
  },
  getSegments: async (id: string) => {
    const { data } = await apiAxios.get<
      models.WithResults<models.AsycudaSegment>
    >(`/cs/customs-system/segments?identifier=${id}`);
    return data;
  },
  getSegmentDetails: async (id: string) => {
    const { data } = await apiAxios.get<models.AsycudaSegment>(
      `/cs/customs-system/segments/${id}`,
    );
    return data;
  },
};
