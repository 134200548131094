import cn from "classnames";
import { Checkbox, Form } from "ebs-design";

import {
  makeNamePath,
  riskLevelFeminine,
  riskLevelMasculine,
} from "@aeo/core/utils";
import { Flex, InputFormField, WhiteSpace } from "@aeo/core/components";
import models from "@aeo/core/models";

type RiskType = "initial" | "residual" | "new" | "revision" | "re_residual";

function getTitleByRisk(risk: models.Risk) {
  if ("revision" in risk && risk.revision) {
    return "Evaluat";
  } else if (risk.initial === true) {
    return "Identificat";
  }

  return "Nou";
}

export interface RiskViewItemProps {
  risk: models.Risk;
  riskType?: RiskType;
  riskTypeTitle?: string;
  canEdit?: boolean;
  onClick?: (risk: models.Risk, type?: RiskType) => void;
  index?: number;
}

export const RiskViewItem = ({
  risk,
  riskType,
  riskTypeTitle,
  canEdit,
  onClick,
  index,
}: RiskViewItemProps) => {
  const title = riskTypeTitle ? riskTypeTitle : getTitleByRisk(risk);

  return (
    <>
      <Flex align="center" justify="space-between">
        <span>Descriere:</span>
        {risk && (
          <Flex align="center">
            {title ? (
              <>
                <div>
                  <span className="mr-5">Tip risc:</span>
                  <span className="fw-500">{title}</span>
                </div>
                <WhiteSpace h="1rem" />
              </>
            ) : null}

            <div>
              <span className="mr-5">Probabilitate:</span>

              <span
                className={cn({
                  [`risk-level-${risk.probability.toLowerCase()}`]:
                    risk.probability,
                })}
              >
                {riskLevelFeminine[risk.probability] || " - "}
              </span>
            </div>
            <WhiteSpace h="1rem" />
            <div>
              <span className="mr-5">Impact:</span>

              <span
                className={cn({
                  [`risk-level-${risk.impact.toLowerCase()}`]: risk.impact,
                })}
              >
                {riskLevelMasculine[risk.impact!] || " - "}
              </span>
            </div>
          </Flex>
        )}
      </Flex>
      <WhiteSpace v="0.5rem" />
      <p className="color-mid-gray">{risk?.description || " - "}</p>

      {canEdit && riskType === "initial" && (
        <>
          <WhiteSpace v="0.5rem" />
          <InputFormField
            label="Constatare:"
            name={makeNamePath("audit_statements", index, "description")}
            textarea
          />
          <Form.Field name={makeNamePath("audit_statements", index, "id")} />
        </>
      )}

      {risk?.audit_statements?.length && !canEdit ? (
        <>
          <WhiteSpace v="1rem" />
          {risk?.audit_statements?.map(
            (statement, index) =>
              statement?.description && (
                <div>
                  <span>Constatare:</span>
                  <p key={index} className="color-mid-gray">
                    {statement.description}
                  </p>
                </div>
              ),
          )}
        </>
      ) : null}

      {risk.task?.complaints_response && (
        <>
          <WhiteSpace v="1rem" />
          <span>Raspuns: </span>
          <p className="color-mid-gray">{risk.task?.complaints_response}</p>
        </>
      )}

      {onClick && (
        <>
          <WhiteSpace h="1rem" />
          <Checkbox
            text="Este risc spre monitorizare?"
            {...(riskType === "residual" || riskType === "re_residual"
              ? { checked: risk[riskType] }
              : {})}
            onChange={() => onClick(risk, riskType)}
          />
        </>
      )}
      <div className="divider my-16" />
    </>
  );
};
