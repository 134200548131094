import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";

import { Button, ButtonGroup } from "ebs-design";

import {
  useQueryParam,
  withDefault,
  createEnumParam,
  StringParam,
  useQueryParams,
  NumericArrayParam,
} from "use-query-params";

import {
  DateRangeFilter,
  Filters,
  QueryTable,
  SelectQueryListFilter,
  TableTitle,
  WhiteSpace,
} from "@aeo/core/components";
import {
  useButtonRadio,
  useOrderingQueryParam,
  useQueryPagination,
} from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { forwardSetState } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { approvalsColumns } from "features/approvals/config";

export const Approvals = () => {
  const [search, setSearch] = React.useState("");
  const [tableColumns, setTableColumns] = React.useState(approvalsColumns());

  const history = useHistory();
  const [state, setState] = useQueryParam(
    "state",
    withDefault(
      createEnumParam(Object.values(models.ApprovalState)),
      models.ApprovalState.waiting,
    ),
  );

  const stateRadioProps = useButtonRadio(state, setState);

  const pagination = useQueryPagination();

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useQueryParams({
    organization_id: NumericArrayParam,
    manager_id: NumericArrayParam,
    application_id: NumericArrayParam,
    created_at_after: StringParam,
    created_at_before: StringParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    state,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.approvals.many(queryParams), () =>
    api.approvals.get(queryParams),
  );

  useUpdateEffect(
    () => pagination.setPage(1),
    [search, filterParams, queryParams.state],
  );

  React.useEffect(() => {
    //remove "number" column if state of approval is approved
    if (state !== models.ApprovalState.approved)
      setTableColumns(
        approvalsColumns().filter((c) => c.dataIndex !== "number"),
      );
    else setTableColumns(approvalsColumns());
  }, [state]);

  return (
    <div>
      <h2>Aprobări</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <QueryTable
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          onClick: () => history.push(`/approvals/view/${data?.id}`),
        })}
        className="mcs-aplication-table"
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <Filters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              >
                <SelectQueryListFilter
                  title="Manager"
                  value={filterParams.manager_id}
                  setValue={forwardSetState(setFilterParams, "manager_id")}
                  querykey={querykeys.users.many}
                  apiCall={api.users.get}
                  getKey={(u: models.User) => u.id}
                  getValue={(u) => u.name}
                />
                <SelectQueryListFilter
                  title="Numărul cererii"
                  value={filterParams.application_id}
                  setValue={forwardSetState(setFilterParams, "application_id")}
                  querykey={querykeys.applications.many}
                  apiCall={api.applications.get}
                  getKey={(u: models.ActiveApplication) => u.id}
                  getValue={(u) => u.id}
                />
                <SelectQueryListFilter
                  title="Organizația"
                  value={filterParams.organization_id}
                  setValue={forwardSetState(setFilterParams, "organization_id")}
                  querykey={querykeys.organizations.many}
                  apiCall={api.organizations.get}
                  getKey={(u: models.Organization) => u.id}
                  getValue={(u) => u.name}
                />
                <DateRangeFilter
                  title="Data creării"
                  after={filterParams.created_at_after}
                  before={filterParams.created_at_before}
                  setAfter={forwardSetState(
                    setFilterParams,
                    "created_at_after",
                  )}
                  setBefore={forwardSetState(
                    setFilterParams,
                    "created_at_before",
                  )}
                />
              </Filters>
            }
            sortOptions={[{ title: "Data creării", value: "created_at" }]}
            onSortChange={setOrdering}
            extra={
              <ButtonGroup style={{ flexWrap: "nowrap" }}>
                <Button {...stateRadioProps(models.ApprovalState.waiting)}>
                  Curente
                </Button>
                <Button {...stateRadioProps(models.ApprovalState.approved)}>
                  Aprobate
                </Button>
                <Button {...stateRadioProps(models.ApprovalState.rejected)}>
                  Respinse
                </Button>
              </ButtonGroup>
            }
          />
        )}
        query={query}
        columns={tableColumns}
      />
    </div>
  );
};
