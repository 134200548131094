import {
  DateRangeFilter,
  Filters,
  FiltersProps,
  SelectQueryListFilter,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { forwardSetState } from "@aeo/core/utils";
import api, { querykeys } from "api";
import { UseTasksFilterParamsResult } from "../hooks";

export interface TasksFiltersProps extends FiltersProps {
  queryParams: UseTasksFilterParamsResult[0];
  setQueryParams: UseTasksFilterParamsResult[1];
}

export const TasksFilters = (props: TasksFiltersProps) => (
  <Filters {...props}>
    <DateRangeFilter
      title="Data creării"
      after={props.queryParams.created_at_after}
      before={props.queryParams.created_at_before}
      setAfter={forwardSetState(props.setQueryParams, "created_at_after")}
      setBefore={forwardSetState(props.setQueryParams, "created_at_before")}
    />
    <DateRangeFilter
      title="Data executării"
      after={props.queryParams.execution_date_after}
      before={props.queryParams.execution_date_before}
      setAfter={forwardSetState(props.setQueryParams, "execution_date_after")}
      setBefore={forwardSetState(props.setQueryParams, "execution_date_before")}
    />
    <SelectQueryListFilter
      title="Persoană asignată"
      value={props.queryParams.executor_id}
      setValue={forwardSetState(props.setQueryParams, "executor_id")}
      querykey={querykeys.users.many}
      apiCall={api.users.get}
      getKey={(u: models.User) => u.id}
      getValue={(u) => u.name}
    />
  </Filters>
);
