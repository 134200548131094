import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";
import { Loader } from "ebs-design";

import { WhiteSpace } from "@aeo/core/components";
import { useParam } from "@aeo/core/hooks";

import api, { querykeys } from "api";
import {
  Audits,
  InstitutionInfo,
  Logs,
  Requests,
} from "features/agents/components/tables";
import { AgentCard } from "features/agents/components";
import { Monitoring } from "features/agents/components";

export const Agent = () => {
  const idParam = useParam("id", NumberParam.decode) || 0;

  const { data, isLoading } = useQuery(
    querykeys.agents.one(idParam),
    () => api.agents.getAgent(idParam),
    {
      enabled: !!idParam,
    },
  );

  const { data: dataAsycudaWithResults } = useQuery(
    querykeys.asycuda.organization,
    () => api.asycuda.getOrganization(data?.idno!),
    { enabled: !!data?.idno },
  );

  const dataAsycuda = dataAsycudaWithResults?.results
    ? dataAsycudaWithResults?.results[0]
    : undefined;

  return (
    <Loader loading={isLoading}>
      <div className="agent-info">
        <AgentCard data={data} dataAsycuda={dataAsycuda} />
        <div className="agent-info__content">
          <Requests applications={data?.applications} />
          <WhiteSpace v="1.75rem" />
          <Audits auditApprovalsData={data?.audit_approvals} />
          <WhiteSpace v="1.75rem" />
          <Monitoring data={data} />
          <WhiteSpace v="1.75rem" />
          <Logs />
          <WhiteSpace v="1.75rem" />
          <InstitutionInfo idno={data?.idno} />
        </div>
      </div>
    </Loader>
  );
};
