import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, LoadingButton } from "@aeo/core/components";
import models from "@aeo/core/models";
import { getProperty, setFieldsErrors } from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";
import { UsersSelect } from "components";

interface AssignModalProps extends ModalProps {
  organization?: models.Organization;
  queryParams?: any;
  manager?: models.Profile;
}
interface FormValues {
  manager: number;
}

export const AssignModal = ({
  organization,
  queryParams,
  manager,
  ...props
}: AssignModalProps) => {
  const [form] = useForm();

  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (data: models.ManagerOrganization) =>
      api.organizations.patch(organization?.id!, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.applications.many());
        if (queryParams)
          queryClient.invalidateQueries(querykeys.agents.many(queryParams));
        notify.success({ title: notifications.personAssigned });
        close();
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(getProperty(error.response?.data), form, notify);
      },
    },
  );

  const handleSubmit = (values: FormValues) => {
    mutate(values);
  };

  const close = () => {
    form.resetFields();
    props?.onClose?.();
  };

  return (
    <Modal title="Adaugă persoană" size="small" {...props} onClose={close}>
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Content>
          <UsersSelect
            name="manager"
            placeholder="Alege utilizatorul"
            mode="single"
            initialValue={manager}
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="space-between">
            <Button onClick={close}>Anulează</Button>
            <LoadingButton type="primary" submit loading={isLoading}>
              Salvează
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
