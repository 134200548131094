import React, { useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";
import { Card, Loader, Space, useNotify } from "ebs-design";
import cn from "classnames";

import {
  EditorModal,
  Flex,
  GoBackTitle,
  Grid,
  LoadingButton,
  PDFViewer,
  WhiteSpace,
} from "@aeo/core/components";
import { useModalState, useParam } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import {
  formattedDateTime,
  notifyErrors,
  getFullName,
  userRoles,
  MCSUserRole,
} from "@aeo/core/utils";
import { documentValidation } from "@aeo/core/messages/documentValidation";

import api, { querykeys } from "api";
import { LabelValueRow } from "features/agents/components";
import { RejectModal } from "../components";
import { approvalTypeContent } from "../utils";

export const Approval = () => {
  const [tabs, setTabs] = React.useState<string | undefined>();
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const orderModal = useModalState<string | undefined>();
  const rejectModal = useModalState<string | undefined>();
  const queryClient = useQueryClient();
  const idParam = useParam("id", NumberParam.decode) || 0;
  const notify = useNotify();

  const query = useQuery(
    querykeys.approvals.one(idParam),
    () => api.approvals.getById(idParam),
    { enabled: !!idParam },
  );

  const signMutation = useMutation(api.approvals.sign.bind(null, idParam), {
    onSuccess: () => {
      notify.success({ title: documentValidation.signedDocument });
      queryClient.invalidateQueries(querykeys.approvals.one(idParam));
    },
    onError: notifyErrors.bind(null, notify),
  });

  const documentMutation = useMutation(
    api.approvals.document.bind(null, idParam),
    {
      onSuccess: () => {
        notify.success({ title: documentValidation.confirmedDocument });
        queryClient.invalidateQueries(querykeys.approvals.one(idParam));
        orderModal.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const documentMutationPreview = useMutation(
    api.approvals.documentPreview.bind(null, idParam),
    {
      onSuccess: (value) => setPdfFile(value),
      onError: notifyErrors.bind(null, notify),
    },
  );

  const approveMutation = useMutation(
    api.approvals.approve.bind(null, idParam),
    {
      onSuccess: () => {
        notify.success({ title: documentValidation.approvedDocument });
        queryClient.invalidateQueries(querykeys.approvals.one(idParam));
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const rejectMutation = useMutation(
    (body: { reason: string }) => api.approvals.reject(idParam, body),
    {
      onSuccess: () => {
        notify.success({ title: documentValidation.rejectedDocument });
        queryClient.invalidateQueries(querykeys.approvals.one(idParam));
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const approval = query?.data;

  useEffect(() => {
    if (
      (approval?.document && !approval?.request_document) ||
      (approval?.request_document && approval.document)
    )
      setTabs("document");
    if (approval?.request_document && !approval?.document)
      setTabs("request_document");
  }, [approval?.request_document, approval?.document]);

  const getUserOrRole = (
    users?: models.SimpleUser[],
    roles?: MCSUserRole[],
  ) => {
    const mapedUsers = users?.map((user) => user.name);
    const mapedRoles = roles?.map((role) => userRoles[role]);

    return mapedUsers?.concat(mapedRoles)?.map((item) => <p>{item}</p>);
  };

  const areDocumentsEqual =
    approval?.document?.id === approval?.request_document?.id;
  const hasBothDocuments = approval?.document && approval?.request_document;

  const tabsButtons = useMemo(
    () => [
      {
        value: "request_document",
        title: "Cererea",
        showDocument: !!approval?.request_document && !areDocumentsEqual,
      },
      {
        value: "document",
        title: "Admitere condiționata",
        showDocument: !!approval?.document,
      },
    ],
    [approval?.request_document, approval?.document, areDocumentsEqual],
  );

  const title = approval?.document?.title || approval?.request_document?.title;

  return (
    <>
      <Card>
        <Card.Header>
          <GoBackTitle to="/approvals">
            <Flex justify="space-between" align="center">
              <h3>{title}</h3>

              {approval?.state === models.ApprovalState.waiting && (
                <Space>
                  <LoadingButton
                    loading={rejectMutation.isLoading}
                    onClick={rejectModal.open}
                    disabled={!approval?.can_reject}
                  >
                    Respinge
                  </LoadingButton>
                  {areDocumentsEqual && (
                    <LoadingButton
                      type="primary"
                      onClick={() =>
                        orderModal.openWith(
                          approval?.type
                            ? approvalTypeContent[approval?.type]
                            : undefined,
                        )
                      }
                    >
                      Admitere condiționata
                    </LoadingButton>
                  )}
                  {approval?.document && (
                    <LoadingButton
                      type="primary"
                      loading={signMutation.isLoading}
                      onClick={() => signMutation.mutate()}
                      disabled={!approval?.can_sign}
                    >
                      Semnează
                    </LoadingButton>
                  )}
                  <LoadingButton
                    type="primary"
                    loading={approveMutation.isLoading}
                    onClick={() => approveMutation.mutate()}
                    disabled={!approval?.can_approve}
                  >
                    Aprobă
                  </LoadingButton>
                </Space>
              )}
            </Flex>
          </GoBackTitle>
        </Card.Header>
        <Card.Body>
          {query.isLoading && <Loader loading></Loader>}
          {approval && (
            <>
              <Grid rows="1fr" cols="1fr 1fr 1fr 1fr" gap="1rem">
                <LabelValueRow
                  label="Numărul cererii"
                  value={approval?.application?.id}
                />
                <LabelValueRow
                  label="Nume operator economic"
                  value={approval?.organization?.name}
                />
                <LabelValueRow
                  label="Persoana responsabilă"
                  value={approval?.manager?.name}
                />
                <LabelValueRow label="Creat de" value={approval?.owner?.name} />
                <LabelValueRow
                  label="Data creării"
                  value={formattedDateTime(approval?.created_at)}
                />
                <LabelValueRow
                  label="Termenul(zile)"
                  value={approval?.period}
                />
                <LabelValueRow
                  label="Necesar de aprobat de către"
                  value={
                    <>
                      {getUserOrRole(
                        approval?.required_users_approvals,
                        approval?.required_approvals,
                      )}
                    </>
                  }
                />

                <LabelValueRow
                  label="Aprobat de"
                  value={
                    <>
                      {approval?.document?.approvals?.map((item) => (
                        <p>{`${getFullName(item.owner)}`}</p>
                      ))}
                    </>
                  }
                />

                <LabelValueRow
                  label="Necesar de semnat de către"
                  value={
                    <>
                      {getUserOrRole(
                        approval?.required_users,
                        approval?.required_signatures,
                      )}
                    </>
                  }
                />
                <LabelValueRow
                  label="Semnat de"
                  value={
                    <>
                      {approval?.document?.signatures?.map((item) => (
                        <p>{`${getFullName(item.owner)}`}</p>
                      ))}
                    </>
                  }
                />

                <LabelValueRow
                  label="Motiv de respingere"
                  value={approval?.reason}
                />
              </Grid>
              <WhiteSpace v="1rem" />
            </>
          )}

          {hasBothDocuments && !areDocumentsEqual && (
            <Flex justify="center">
              {tabsButtons?.map(
                (v) =>
                  v.showDocument && (
                    <div
                      className={cn("tab-label", {
                        "tab-label--active": v.value === tabs,
                      })}
                      onClick={() => setTabs(v.value)}
                      key={v.value}
                    >
                      <p>{v.title}</p>
                    </div>
                  ),
              )}
            </Flex>
          )}
          <WhiteSpace v="2rem" />
          {approval?.document || approval?.request_document ? (
            <PDFViewer
              file={
                tabs === "document"
                  ? approval?.document?.file_upload
                  : approval?.request_document?.file_upload
              }
            />
          ) : (
            <p>(Nu sunt documente)</p>
          )}
        </Card.Body>
      </Card>
      {orderModal.isOpen && (
        <EditorModal
          open={orderModal.isOpen}
          onClose={orderModal.close}
          title="Admitere condiționată"
          data={{ initialValue: { content: orderModal.data } }}
          mutation={documentMutation}
          previewMutation={documentMutationPreview}
          pdfFile={pdfFile}
        />
      )}
      {rejectModal.isOpen && (
        <RejectModal
          open={rejectModal.isOpen}
          onClose={rejectModal.close}
          title="Respingere aprobare"
          mutation={rejectMutation}
        />
      )}
    </>
  );
};
