import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";
import { Button, Loader, Space } from "ebs-design";
import { useRouteMatch } from "react-router-dom";

import { TabsWithRoute } from "@aeo/core/components/TabsWithRoute";
import { GoBackTitle, WhiteSpace, Icon } from "@aeo/core/components";
import { ApplicationContext } from "@aeo/core/contexts";
import { useDownloadFile, useIsManager, useParam } from "@aeo/core/hooks";
import { tabsAccess } from "@aeo/core/utils";
import models from "@aeo/core/models";

import api, { querykeys } from "api";
import { StatusBadge } from "components";
import { applicationFlowTabs } from "../config";
import { SelectStatus, SendLetter } from "../components";

export const ApplicationFlow = () => {
  const id = useParam("id", NumberParam.decode) || 0;
  const isManagerOf = useIsManager();
  const matchLogoPath = !!useRouteMatch(`/applications/flow/:id/logo`);

  const query = useQuery(
    querykeys.applications.one(id),
    () => api.applications.getById(id),
    {
      enabled: !!id,
    },
  );

  const isManager = isManagerOf(query.data);
  const tabsRoutes = tabsAccess(applicationFlowTabs, query.data?.state);

  const { data } = useQuery(
    querykeys.documents.one(id),
    () => api.applications.getDocument(query.data?.organization?.id || 0),
    {
      enabled: !!query.data?.organization?.id,
    },
  );

  const downloadFile = useDownloadFile();

  return (
    <>
      {query.isLoading ? (
        <Loader loading />
      ) : (
        <ApplicationContext.Provider value={query.data}>
          <GoBackTitle
            to="/applications/active"
            actions={
              <Space>
                <Button
                  prefix={<Icon type="download" />}
                  onClick={() => {
                    data?.active_application?.signed_document &&
                      downloadFile(data?.active_application.signed_document);
                  }}
                >
                  Descarcă cerere
                </Button>
                <StatusBadge state={query?.data?.state} />
              </Space>
            }
          >
            Aplicarea - {id}
          </GoBackTitle>
          <WhiteSpace v="1em" />
          <TabsWithRoute tabItems={tabsRoutes as models.TabsEntity[]}>
            <Space>
              <SendLetter applicationId={id} />
              {isManager && !matchLogoPath && (
                <SelectStatus
                  tabs={applicationFlowTabs.map((v) => ({
                    route: v.route,
                    states: v.states,
                  }))}
                />
              )}
            </Space>
          </TabsWithRoute>
        </ApplicationContext.Provider>
      )}
    </>
  );
};
