import React from "react";
import { Card } from "ebs-design";

import { ApplicationContext } from "@aeo/core/contexts";
import { AttachmentsList } from "@aeo/core/components";

export const Documents = () => {
  const application = React.useContext(ApplicationContext);

  return (
    <Card>
      <Card.Header>
        <h2>Documente atașate</h2>
      </Card.Header>
      <Card.Body>
        <AttachmentsList hideLabel value={application?.documents} />
      </Card.Body>
    </Card>
  );
};
