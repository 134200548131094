import fields from "@aeo/core/fields";

import { ExaminationCard } from ".";

export const LogisticThree = () => {
  const logisticThree = fields.logisticThree;

  return (
    <>
      <ExaminationCard {...logisticThree.backup_process} />
      <ExaminationCard {...logisticThree.backup_ttl} />
      <ExaminationCard {...logisticThree.has_emergency_plan} type="boolean" />
      <ExaminationCard {...logisticThree.emergency_plan} />
      <ExaminationCard
        {...logisticThree.emergency_plan_attachments}
        type="attachments"
      />

      <ExaminationCard {...logisticThree.protect_system} />
      <ExaminationCard {...logisticThree.has_test_auth} type="boolean" />
      <ExaminationCard {...logisticThree.test_authorization} NA />

      <ExaminationCard {...logisticThree.incident_security} />
      <ExaminationCard {...logisticThree.access_rights} />
      <ExaminationCard {...logisticThree.protection_responsible} />
      <ExaminationCard {...logisticThree.internal_security} />
      <ExaminationCard
        {...logisticThree.internal_security_attachments}
        type="attachments"
      />
      <ExaminationCard {...logisticThree.monitor_security} />
      <ExaminationCard {...logisticThree.server_located} />
      <ExaminationCard {...logisticThree.server_secured} />
      <ExaminationCard {...logisticThree.protect_information_procedure} />
      <ExaminationCard
        {...logisticThree.has_unauthorized_access}
        type="boolean"
      />
      <ExaminationCard {...logisticThree.unauthorized_access} NA />
      <ExaminationCard {...logisticThree.employee_category} />
      <ExaminationCard {...logisticThree.allowed_change} />
      <ExaminationCard {...logisticThree.change_document_procedure} />
      <ExaminationCard {...logisticThree.security_requirements} />

      <ExaminationCard attachmentList />
    </>
  );
};
