import fields from "@aeo/core/fields";

import { ExaminationCard } from ".";

export const OrganizationStructure = () => {
  const organizationStructure = fields.organizationStructure;

  return (
    <>
      <ExaminationCard {...organizationStructure.departament_description} />
      <ExaminationCard {...organizationStructure.employee_description} />
      <ExaminationCard
        {...organizationStructure.category}
        type="nomenclature"
      />
      <ExaminationCard {...organizationStructure.employee_count} />
      <ExaminationCard
        {...organizationStructure.agreement_publication}
        type="boolean"
      />
      <ExaminationCard
        {...organizationStructure.partial_agreement_publication}
        type="boolean"
      />
      <ExaminationCard {...organizationStructure.employee_name} />
      <ExaminationCard {...organizationStructure.employee_function} />
      <ExaminationCard attachmentList />
    </>
  );
};
