import { Button } from "ebs-design";
import {
  compose,
  formattedDate,
  formattedDateTime,
  TableColumnBuilder,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import { UseRole } from "@aeo/core/hooks";
import {
  Badge,
  ColumnNoWrap,
  Icon,
  RoleGuard,
  WhiteSpace,
} from "@aeo/core/components";

interface CurrentArgs {
  onCreate: React.Dispatch<any>;
  onDelete: React.Dispatch<any>;
  onDone: React.Dispatch<any>;
  role: UseRole;
}

interface DoneArgs {
  onView: React.Dispatch<any>;
}
export const currentColumns = ({
  onCreate,
  onDelete,
  onDone,
  role,
}: CurrentArgs) =>
  new TableColumnBuilder<models.Task>()
    .add("Nr. Cerere", "application")
    .add("Operator Economic", ["organization", "name"])
    .add("Titlu", "title", undefined, { ellipsis: true, width: 200 })
    .add("Descriere", "description", undefined, { ellipsis: true, width: 300 })
    .add(
      "Data creării",
      "created_at",
      compose(formattedDateTime, ColumnNoWrap),
      { width: 150 },
    )
    .add(
      "Data scadentă",
      "execution_date",
      compose(formattedDate, ColumnNoWrap),
      { width: 150 },
    )
    .add(
      "Persoană asignată",
      "executor",
      (record) =>
        !!record ? (
          <Badge size="md" children={record.name} round variant="outline" />
        ) : (
          "-"
        ),
      { width: 200 },
    )
    .add("", "", (record) =>
      ColumnNoWrap(
        <div
          style={{ width: "110px", marginLeft: "auto" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            icon={() => <Icon type="check" color="gray" />}
            size="small"
            onClick={() => onDone(record)}
          />
          <WhiteSpace inline h="0.5rem" />
          <Button
            icon={() => <Icon type="edit" color="gray" />}
            size="small"
            onClick={() => onCreate(record)}
            disabled={record.risk && !role.isSuperuser}
          />
          <RoleGuard restritedTo={["user", "admin"]}>
            <>
              <WhiteSpace inline h="0.5rem" />
              <Button
                disabled={record.risk}
                icon={() => <Icon type="delete" color="gray" />}
                size="small"
                onClick={() => onDelete(record.id)}
              />
            </>
          </RoleGuard>
        </div>,
      ),
    )
    .getColumns();

export const completeColumns = ({ onView }: DoneArgs) =>
  new TableColumnBuilder<models.Task>()
    .add("Operator Economic", ["organization", "name"], undefined, {
      width: 200,
    })
    .add("Nr. Cerere", "application", undefined, { width: 100 })
    .add("Titlu", "title", undefined, { width: 300, ellipsis: true })
    .add("Descriere", "description", undefined, { width: 300, ellipsis: true })
    .add(
      "Data creării",
      "created_at",
      compose(formattedDateTime, ColumnNoWrap),
      { width: 150 },
    )
    .add("Răspuns", "complaints_response", undefined, {
      width: 300,
      ellipsis: true,
    })
    .add(
      "Data executării",
      "execution_date",
      compose(formattedDate, ColumnNoWrap),
      { width: 150 },
    )
    .add(
      "Persoană asignată",
      "executor",
      (record) =>
        !!record
          ? ColumnNoWrap(
              <Badge
                className="nowrap"
                size="md"
                children={record.name}
                round
                variant="outline"
              />,
            )
          : "-",
      { width: 200 },
    )
    .getColumns();
