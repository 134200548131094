import { AxiosResponse } from "axios";

import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";
import { GenericObject } from "@aeo/core/types";

export const chat = {
  organizations: {
    get: async (query: GenericObject) => {
      const url = stringifyUrl(`/cs/chat/organizations`, query);
      const { data } = await apiAxios.get<
        models.WithResults<models.ChartOrganizationConversation>
      >(url);
      return data;
    },
    getById: async (id: number) => {
      const { data } = await apiAxios.get<models.ChartOrganizationConversation>(
        `/cs/chat/organizations/${id}`,
      );
      return data;
    },
  },
  getRepliesById: async (id: number, query: GenericObject) => {
    const url = stringifyUrl(`/cs/chat/${id}/replies`, query);
    const { data } = await apiAxios.get<models.WithResults<models.ChatReply>>(
      url,
    );
    return data;
  },
  postReply: async (id: number, reply?: models.ChatReply) => {
    const { data } = await apiAxios.post<
      models.ChatReply,
      AxiosResponse<models.ChatReply>
    >(`/cs/chat/${id}/reply`, reply);
    return data;
  },
};
