import { useState } from "react";
import { Space, Input, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";

import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";
import { LoadingButton } from "@aeo/core/components";
import api from "@aeo/core/api";

interface Props {
  item: models.PeriodNomenclature;
}

export const EditPeriodItem = ({ item }: Props) => {
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [period, setPeriod] = useState(item.legal_period);

  const { mutate, isLoading } = useMutation(
    (values: Pick<models.PeriodNomenclature, "legal_period">) =>
      api.nomenclatures.editEntity(
        "application-states",
        String(item.id),
        values,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("application-states");
        notify.success({
          title: "Perioadă modificată cu succes!",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Space className="ebs-space--grow-first mb-15">
      <h4 className="fw-500">{item.name}</h4>
      <Input
        size="large"
        name="inputlegal_period"
        value={period ? period : undefined}
        onChange={(v) => setPeriod(String(v))}
        placeholder="Numarul de zile"
        style={{ width: 200 }}
        type="number"
        min={0}
      />
      <LoadingButton
        type="primary"
        loading={isLoading}
        onClick={() => mutate({ legal_period: period })}
      >
        Salvează
      </LoadingButton>
    </Space>
  );
};
