import { TabsWithRoute, WhiteSpace } from "@aeo/core/components";
import { taskTabs } from "features/tasks/config";

export const Tasks = () => {
  return (
    <div>
      <h2>Sarcini</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <TabsWithRoute type="badges" tabItems={taskTabs} />
    </div>
  );
};
