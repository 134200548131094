import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const preaudit = {
  getById: async (id: number) => {
    const { data } = await apiAxios.get(`/cs/pre-audit/${id}`);
    return data;
  },
  patch: async (id: number, value: any) => {
    const { data } = await apiAxios.patch(`/cs/pre-audit/${id}`, value);
    return data;
  },
  postOrder: async (
    id: number,
    state: string,
    value: models.OrderFormValues,
  ) => {
    const { data } = await apiAxios.post<models.OrderFormValues>(
      `/cs/${state}/${id}/control-order`,
      value,
    );
    return data;
  },
  patchOrder: async (
    id: number,
    state: string,
    value: models.OrderFormValues,
  ) => {
    const { data } = await apiAxios.patch<models.OrderFormValues>(
      `/cs/${state}/control-order/${id}`,
      value,
    );
    return data;
  },
};
