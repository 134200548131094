import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const Customer = () => {
  const person = { ...fields.person, ...fields.personCustomer };

  return (
    <>
      <ExaminationCard {...person.first_name} />
      <ExaminationCard {...person.last_name} />
      <ExaminationCard {...person.birthday} type="date" />
      <ExaminationCard {...person.idnp} />
      <ExaminationCard {...person.phone} />
      <ExaminationCard {...person.email} />
      <ExaminationCard {...person.address} />
      <ExaminationCard {...person.attachments_persons} type="attachments" />
      <ExaminationCard attachmentList />
    </>
  );
};
