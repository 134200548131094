import { useContext } from "react";
import { Card } from "ebs-design";
import { ApplicationContext } from "@aeo/core/contexts";
import messages from "@aeo/core/messages";

export const Resume = () => {
  const applicationCtx = useContext(ApplicationContext);
  const saq = messages.SAQTabsContent;

  return (
    <Card>
      <Card.Header>
        <h2>Cuprins</h2>
      </Card.Header>
      <Card.Body>
        <ul>
          <li>Cererea</li>
          <li>SAQ: {saq.economicAgentInfo.title}</li>
          <li style={{ marginLeft: "1pc" }}>
            SAQ: {saq.companyGeneralInfo.title}
          </li>
          <li style={{ marginLeft: "2pc" }}>SAQ: {saq.economicAgent.title}</li>
          <li style={{ marginLeft: "2pc" }}>SAQ: {saq.shareholders.title}</li>
          <li style={{ marginLeft: "3pc" }}>
            SAQ: {saq.shareholdersOne.title}
          </li>
          <li style={{ marginLeft: "3pc" }}>SAQ: {saq.directors.title}</li>
          <li style={{ marginLeft: "3pc" }}>SAQ: {saq.advisory.title}</li>
          <li style={{ marginLeft: "3pc" }}>SAQ: {saq.contactPerson.title}</li>
          <li style={{ marginLeft: "2pc" }}>SAQ: {saq.customer.title}</li>
          <li style={{ marginLeft: "2pc" }}>SAQ: {saq.commercial.title}</li>
          <li style={{ marginLeft: "2pc" }}>SAQ: {saq.structure.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.turnover.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.statistic.title}</li>
          <li>SAQ: {saq.legislation.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.legislationOne.title}</li>
          <li>SAQ: {saq.logistic.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.logisticOne.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.logisticTwo.title}</li>
          <li style={{ marginLeft: "1pc" }}>SAQ: {saq.logisticThree.title}</li>
          <li>SAQ: {saq.solvency.title}</li>

          {applicationCtx?.type === "AEOC" && (
            <>
              <li>SAQ: {saq.standard.title}</li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.standardOne.title}
              </li>
            </>
          )}
          {applicationCtx?.type === "AEOS" && (
            <>
              <li>SAQ: {saq.security.title}</li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityOne.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityTwo.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityThree.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityFour.title}
              </li>
            </>
          )}
          {applicationCtx?.type === "AEOF" && (
            <>
              <li>SAQ: {saq.standard.title}</li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.standardOne.title}
              </li>
              <li>SAQ: {saq.security.title}</li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityOne.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityTwo.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityThree.title}
              </li>
              <li style={{ marginLeft: "1pc" }}>
                SAQ: {saq.securityFour.title}
              </li>
            </>
          )}
        </ul>
      </Card.Body>
    </Card>
  );
};
