import { Icon } from "@aeo/core/components";
import models from "@aeo/core/models";
import { Button, Space } from "ebs-design";
interface Props {
  openModal: (value: models.Template) => void;
  template: models.Template;
}
export const EditDocumentCard = ({ openModal, template }: Props) => {
  return (
    <Space
      className="ebs-space--grow-first edit-document-row p-10"
      align="center"
    >
      <h4 className="fw-500">{template?.title}</h4>
      <Space justify="space-between">
        <Button
          onClick={() => openModal(template)}
          icon={() => <Icon type="edit" color="gray" />}
        />
      </Space>
    </Space>
  );
};
