import { Link, useLocation, matchPath } from "react-router-dom";
import { useQuery } from "react-query";

import { Sidebar } from "ebs-design";

import { Icon } from "@aeo/core/components/icons";
import { useRole } from "@aeo/core/hooks";
import { Flex, RoleGuard } from "@aeo/core";
import { REFETCH_MENU_NOTIFICATIONS_INTERVAL } from "@aeo/core/app-constants";
import api, { querykeys } from "api";

export const AppLayoutMenu = () => {
  const location = useLocation();
  const role = useRole();

  const { data } = useQuery(
    querykeys.menuNotifications,
    api.profile.notifications,
    { refetchInterval: REFETCH_MENU_NOTIFICATIONS_INTERVAL },
  );

  const isActive = (url: string | string[], exact = false): boolean =>
    !!matchPath(location.pathname, { path: url, exact });
  
  const isDirector = role.isAdmin;

  return (
    <Sidebar.TopMenu toggleText="Ascunde">
      <RoleGuard restritedTo={["user"]}>
        <Link to="/">
          <Sidebar.Item
            text={"Panou control"}
            prefix={<Icon type="dashboard" />}
            active={isActive("/", true)}
          />
        </Link>
      </RoleGuard>
      <Link to="/applications">
        <Sidebar.Item
          text={
            <Flex align="center">
              Cereri
              {!!data?.unviewed_applications && (
                <span className="menu-item-notification">
                  {data?.unviewed_applications}
                </span>
              )}
            </Flex>
          }
          prefix={<Icon type="applications" />}
          active={isActive("/applications")}
        />
      </Link>
      <Link to="/agents">
        <Sidebar.Item
          text={"Operatori economici"}
          prefix={<Icon type="economical-agents" />}
          active={isActive(["/agents"])}
        />
      </Link>
      {!isDirector && (
        <Link to="/tasks">
          <Sidebar.Item
            text={
              <Flex align="center">
                Sarcini
                {!!data?.unviewed_tasks && (
                  <span className="menu-item-notification">
                    {data?.unviewed_tasks}
                  </span>
                )}
              </Flex>
            }
            prefix={<Icon type="tasks" />}
            active={isActive("/tasks")}
          />
        </Link>
      )}
      <Link to="/approvals">
        <Sidebar.Item
          text={
            <Flex align="center">
              Aprobări
              {!!data?.unviewed_approvals && (
                <span className="menu-item-notification">
                  {data?.unviewed_approvals}
                </span>
              )}
            </Flex>
          }
          prefix={<Icon type="approvals" />}
          active={isActive("/approvals")}
        />
      </Link>
      <Link to="/orders">
        <Sidebar.Item
          text={"Ordine"}
          prefix={<Icon type="orders" style={{ strokeWidth: 1.5 }} />}
          active={isActive("/orders")}
        />
      </Link>
      <Link to="/authorizations">
        <Sidebar.Item
          text={<Flex align="center">Autorizații</Flex>}
          prefix={<Icon type="verification-list" />}
          active={isActive("/authorizations")}
        />
      </Link>
      {!isDirector && (
        <Link to="/chat">
          <Sidebar.Item
            text={
              <Flex align="center">
                Chat
                {!!data?.unviewed_messages && (
                  <span className="menu-item-notification">
                    {data?.unviewed_messages}
                  </span>
                )}
              </Flex>
            }
            prefix={<Icon type="chat" />}
            active={isActive("/chat")}
          />
        </Link>
      )}
      {role.isSuperuser && !isDirector && (
        <Link to="/users">
          <Sidebar.Item
            text={"Utilizatori"}
            prefix={<Icon type="users" />}
            active={isActive("/users")}
          />
        </Link>
      )}
      <Link to="/settings">
        <Sidebar.Item
          text={"Setări"}
          prefix={<Icon type="settings" />}
          active={isActive("/settings")}
        />
      </Link>
      {!isDirector && (
        <Link to="/news">
          <Sidebar.Item
            text={"Anunțuri"}
            prefix={<Icon type="news" />}
            active={isActive("/news")}
          />
        </Link>
      )}
    </Sidebar.TopMenu>
  );
};
