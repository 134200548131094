import { Link } from "react-router-dom";
import cn from "classnames";

import { makeBEM } from "@aeo/core/utils";
import { DynamicCssProperties } from "@aeo/core/types";

interface Props {
  title: string;
  value?: number;
  progress?: React.ReactNode;
  color?: string;
  redirect?: string;
}

const bem = makeBEM("statistics-card");
export const StatisticsCard = ({
  title,
  value,
  progress,
  color = "#01689a",
  redirect = "",
}: Props) => {
  return (
    <Link to={redirect}>
      <div className="statistics-card">
        <h5
          className={cn(bem("title"))}
          style={
            {
              "--background-color": color,
            } as DynamicCssProperties
          }
        >
          {title}
        </h5>
        <div className={bem("box")}>
          <span
            className={bem("value")}
            style={
              {
                "--color": color,
              } as DynamicCssProperties
            }
          >
            {value}
          </span>

          <div className={bem("progress-box")}>{progress}</div>
        </div>
      </div>
    </Link>
  );
};
