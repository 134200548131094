import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const FinancialSolvency = () => {
  const solvency = fields.financialSolvency;

  return (
    <>
      <KeyValueRow {...solvency.has_insolvency_proceedings} type="boolean" />
      <KeyValueRow {...solvency.insolvency_proceedings} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...solvency.good_financial_situation} type="boolean" />
      <KeyValueRow {...solvency.financial_situation} />
      <KeyValueRow
        {...solvency.financial_situation_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...solvency.actual_financial_reports} type="attachments" />

      <KeyValueRow {...solvency.has_problems_solvency} type="boolean" />
      <KeyValueRow {...solvency.problems_solvency} NA />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
