import { Button, Form, Modal, useForm } from "ebs-design";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";

import { LogoFormData } from ".";

interface Props extends ModalProps {
  mutation: UseMutationResult<void, AxiosError<never>, LogoFormData, unknown>;
  data?: LogoFormData;
}
export const LogoModal = ({ mutation, data, ...props }: Props) => {
  const [form] = useForm();

  return (
    <Modal {...props}>
      <Form
        form={form}
        onFinish={(values) =>
          mutation.mutate({ state: data?.state, body: values })
        }
      >
        <Modal.Content>
          <InputFormField
            textarea
            name="content"
            label="Cauza suspendării"
            required
          />
        </Modal.Content>

        <Modal.Footer>
          <Grid cols="200px 1fr" gap="1rem">
            <InputFormField
              placeholder="Numarul de zile"
              name="period"
              className="mb-0"
              required
            />
            <Flex justify="flex-end" align="start">
              <Button onClick={props.onClose}>Anulează</Button>
              <WhiteSpace h="1rem" />
              <LoadingButton loading={mutation.isLoading} submit type="primary">
                Salvează
              </LoadingButton>
            </Flex>
          </Grid>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
