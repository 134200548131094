import classNames from "classnames";

import { makeBEM, getStatusColor } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { Flex } from "@aeo/core/components";

const bem = makeBEM("aeo-table");

export const StatusApprovalBadge = ({
  state,
}: {
  state?: models.ApprovalState;
}) => {
  if (!state) {
    return null;
  }

  return (
    <Flex justify="start" align="center">
      <span
        className={classNames(bem("status-circle"), getStatusColor(state))}
      ></span>
      {models.ApprovalStateText[state]}
    </Flex>
  );
};
