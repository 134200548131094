import { useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";

import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  AlertErrors,
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { useParam } from "@aeo/core/hooks";
import { setFieldsErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";

import { CriterionSelectField } from ".";

export const ComplaintModal = ({ ...props }: ModalProps) => {
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const idParam = useParam("id", NumberParam.decode) || 0;

  const mutation = useMutation(
    (value: models.Lament) => api.agents.addLament(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.agents.one(idParam || 0));
        close();
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(error.response?.data, form, notify);
      },
    },
  );

  const handleForm = (values: models.Lament) => {
    mutation.mutate({ ...values, organization: idParam });
  };

  const close = () => {
    form.resetFields();
    props?.onClose?.();
  };

  return (
    <Modal
      title="Adaugă sesizarea"
      size="small"
      open={props.open}
      onClose={close}
    >
      <Form form={form} onFinish={handleForm}>
        <Modal.Content>
          <CriterionSelectField />
          <InputFormField textarea required name="body" label="Sesizare" />

          <AlertErrors error={mutation.error} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={close}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton submit type="primary" loading={mutation.isLoading}>
              Salvează
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
