import { GenericObject } from "@aeo/core/types";
import { stringifyUrl } from "@aeo/core/api/utils";
import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const tasks = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/tasks", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Task>>(url);
    return data;
  },

  post: async (body: models.CreateTask) => {
    const { data } = await apiAxios.post("/cs/tasks", body);
    return data;
  },
  patch: async (id: number, body: models.CreateTask) => {
    const { data } = await apiAxios.patch(`/cs/tasks/${id}`, body);
    return data;
  },
  delete: async (id?: number) => {
    const url = stringifyUrl(`/cs/tasks/${id}`);
    await apiAxios.delete(url);
  },
  done: async (id: number, body: models.Task) => {
    const { data } = await apiAxios.post(`/cs/tasks/${id}/done`, body);
    return data;
  },
};
