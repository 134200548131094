import React from "react";
import { NumberParam } from "use-query-params";
import { useSetState } from "react-use";

import { makeBEM } from "@aeo/core/utils";
import { useParam, useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { ChatMessageListContent, SendMessageForm } from "@aeo/core/components";

import api, { querykeys } from "api";

interface Props {
  managerId?: number;
}
const bem = makeBEM("chat-messages");

export const ChatMessageList = ({ managerId }: Props) => {
  const { isManager, isAdmin } = useRole(managerId);
  const idParam = useParam("id", NumberParam.decode) || 0;

  const [justSent, setJustSent] = useSetState<{
    [key in number]: models.TemporaryChatReply[];
  }>();

  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={bem("list")} ref={loadMoreRootRef}>
        <ChatMessageListContent
          querykey={querykeys.chat.organizations.replies(idParam)}
          loadMoreRootRef={loadMoreRootRef}
          justSent={justSent[idParam] || []}
          apiCall={(...args) => api.chat.getRepliesById(idParam, ...args)}
        />
      </div>

      {(isManager || isAdmin) && (
        <SendMessageForm
          uploadUrl={"/cs/chat/files"}
          apiCall={(...args) => api.chat.postReply(idParam, ...args)}
          setJustSent={(fnOrVal) =>
            setJustSent(
              fnOrVal instanceof Function
                ? (prev) => ({ [idParam]: fnOrVal(prev[idParam]) })
                : { [idParam]: fnOrVal },
            )
          }
        />
      )}
    </>
  );
};
