import { NumberParam } from "use-query-params";

import { makeBEM } from "@aeo/core/utils";
import { useParam } from "@aeo/core/hooks";
import { RerenderProvider } from "@aeo/core/contexts";

import { ConversationContent } from ".";

const bem = makeBEM("chat-conversation");

export const Conversation = () => {
  const idParam = useParam("id", NumberParam.decode);

  return (
    <RerenderProvider>
      {idParam ? (
        <ConversationContent />
      ) : (
        <div className={bem()}>
          <div className={bem("no-selected-container")}>
            <div className={bem("no-selected-message")}>
              Selectează din stânga o conversație
            </div>
          </div>
        </div>
      )}
    </RerenderProvider>
  );
};
