import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { NumberParam } from "use-query-params";
import { Card, Form, Loader, useForm, useNotify, Alert } from "ebs-design";
import cn from "classnames";

import {
  AlertErrors,
  BooleanRadioFormField,
  InputFormField,
  Flex,
  GoBackTitle,
  InputFormList,
  LoadingButton,
  ScrollToTopButton,
  WhiteSpace,
  AttachmentsFormField,
  AttachmentItem,
  Badge,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { useParam, useRole, useAuth } from "@aeo/core/hooks";
import { notifyErrors, scrollToFieldError, groupBy } from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";
import messages from "@aeo/core/messages";

import api, { querykeys } from "api";

import { useApplication } from "../../hooks";
import { criterionExamination } from "../titles";
import { RiskList, RiskViewItem } from "..";

export const SubcriterionExamination = () => {
  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();
  const queryClient = useQueryClient();
  const match = useRouteMatch(
    "/applications/flow/:applicationId/audit/:criterion",
  );

  const { user } = useAuth();
  const application = useApplication();
  const role = useRole(application?.organization?.manager?.id);

  const criterionParam = useParam("subcriterion", NumberParam.decode) || 0;
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isUserInAuditTeam = !!application.order?.team?.find(
    (item) => item.id === user?.id,
  );

  const isAllowedForUser =
    role.isManager || role.isSuperuser || isUserInAuditTeam;

  const { data, isLoading, isError, error } = useQuery(
    querykeys.audit(application?.id!),
    () =>
      api.audit.getByCriterion(application?.id || 0, {
        criterion: criterionParam,
      }),
    {
      enabled: !!application && isAllowedForUser,
      select: (data) => {
        const { risks, residual_risks } = groupBy<models.Risk>(data?.risks, {
          // Holds new risks
          risks: (r) => !r.residual,
          // Filter residual risks
          residual_risks: (r) => r.residual,
        });

        return {
          ...data,
          risks,
          residual_risks,
        };
      },
      onSuccess: (data) => {
        const normalizeInitialRisks = data?.initial_risks
          ?.map((v) => ({
            id: v.id,
            description: v?.audit_statements?.[0]?.description,
          }))
          .flat();

        form.setFieldsValue({
          ...data,
          audit_statements: normalizeInitialRisks,
        });
      },
    },
  );

  const mutation = useMutation(
    (values: models.AuditCriterion) =>
      api.audit.patchCriterion(application.id!, values),
    {
      onSuccess: () => {
        notify.success({ title: notifications.savedData });
        queryClient.invalidateQueries(querykeys.audit(application?.id!));
        if (match?.url) history.push(match?.url);
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const mutationRisk = useMutation(
    (values: Partial<models.Risk>) =>
      api.audit.updateCriterionRisk(application.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.audit(application?.id!));
        notify.success({
          title: messages.notifications.updatedRisk,
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );
  const mutationRiskObservation = useMutation(
    (values: models.RiskObservation[]) =>
      api.audit.updateRiskObservation(application.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.audit(application?.id!));
        notify.success({
          title: messages.notifications.successObservation,
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const submitHandler = (values: models.AuditCriterion) => {
    mutation.mutate({
      ...values,
      criterion: criterionParam,
      // Merge residual risks with new risks
      risks: [...(data?.residual_risks || []), ...(values?.risks || [])],
    });

    const riskObservationValues = values?.audit_statements
      ?.filter((v) => v.description !== undefined)
      .map((v) => ({
        id: v?.id,
        audit_statements: [{ description: v.description }],
      }));

    mutationRiskObservation.mutate(riskObservationValues || []);
  };

  const updateRisk = (v: models.Risk) => {
    mutationRisk.mutate({
      id: v.id,
      residual: !v.residual,
      audit_statements: v.audit_statements,
    });
  };

  if (!isAllowedForUser) {
    return (
      <GoBackTitle>
        <Alert type="warning">Nu aveți permisiune să vizualizați pagina</Alert>
      </GoBackTitle>
    );
  }

  return (
    <Form form={form} onFinish={submitHandler}>
      <ScrollToTopButton />
      <GoBackTitle>
        <Flex justify="space-between" align="center">
          {data?.criterion_nomenclature?.name}
          <WhiteSpace h="1rem" />
          {!isError && !isLoading && data?.can_edit && (
            <LoadingButton
              loading={mutation.isLoading}
              onClick={() => scrollToFieldError(containerRef.current)}
              submit
              type="primary"
            >
              Salvează
            </LoadingButton>
          )}
        </Flex>
      </GoBackTitle>
      <WhiteSpace v="1rem" />
      <Card>
        <Card.Header>
          <h3> {data?.criterion_nomenclature?.name}</h3>
        </Card.Header>
        <Card.Body>
          {isLoading && <Loader loading />}
          <AlertErrors error={error} />
          {data && (
            <div ref={containerRef}>
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.responsible}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.domain?.name}</p>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.information}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.description}</p>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.consultativeRisks}</h4>
                  <WhiteSpace v="0.5rem" />
                  <ul>
                    {data?.criterion_nomenclature?.default_risks.map((risk) => (
                      <li key={risk.id} className="mt-10">
                        {risk.description}
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.recommendedActions}</h4>
                  <WhiteSpace v="0.5rem" />
                  <p>{data?.criterion_nomenclature?.activity}</p>
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.identifiedRisk}</h4>
                  <WhiteSpace v="1rem" />
                  {data?.initial_risks?.map((risk, i) => (
                    <RiskViewItem
                      key={risk.id}
                      risk={risk}
                      canEdit={data?.can_edit}
                      riskType="initial"
                      index={i}
                      {...(data?.can_edit && { onClick: updateRisk })}
                    />
                  ))}
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.newRisk}</h4>
                </Card.Header>
                <Card.Body>
                  {data?.can_edit ? (
                    <RiskList name="risks" state="audit" />
                  ) : (
                    data?.risks?.map((risk) => (
                      <RiskViewItem key={risk.id} risk={risk} riskType="new" />
                    ))
                  )}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.monitoringRisk}</h4>
                </Card.Header>
                <Card.Body>
                  {data?.residual_risks?.map((risk) => (
                    <RiskViewItem
                      key={risk.id}
                      risk={risk}
                      riskType="residual"
                      {...(data?.can_edit && { onClick: updateRisk })}
                    />
                  ))}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  <h4>{criterionExamination.conclusion}</h4>
                  <WhiteSpace v="1rem" />
                  {data.can_edit && (
                    <>
                      <div
                        className={cn({ "disabled-radio": !data?.can_edit })}
                      >
                        <BooleanRadioFormField
                          label={
                            data?.criterion_nomenclature?.conclusion_question
                          }
                          required
                          name="conclusion"
                        />
                        <InputFormField
                          label="Detalii"
                          name="conclusion_details"
                          textarea
                        />
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Header>
                  <h4>{criterionExamination.recommendations}</h4>
                </Card.Header>
                <Card.Body className={cn({ "p-0": data?.can_edit })}>
                  {data?.can_edit ? (
                    <InputFormList
                      title="recomandare"
                      name="recommendations"
                      inputName="description"
                      withResponse
                      textarea
                    />
                  ) : (
                    data?.recommendations?.map((v) => <p>{v.description}</p>)
                  )}
                </Card.Body>
              </Card>

              <WhiteSpace v="1rem" />
              <Card className="bordered">
                <Card.Body>
                  {data?.can_edit && <AttachmentsFormField />}
                  {data?.attachments?.length && !data.can_edit ? (
                    data?.attachments?.map((v, i) => (
                      <AttachmentItem className="mb-5 mr-7" key={i} {...v} />
                    ))
                  ) : (
                    <Badge variant="subtle">Lipsesc</Badge>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
        </Card.Body>
      </Card>
    </Form>
  );
};
