import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const EconomicAgent = () => {
  const economicAgent = fields.economicalAgent;

  return (
    <>
      <ExaminationCard {...economicAgent.name} />
      <ExaminationCard {...economicAgent.address} />
      <ExaminationCard {...economicAgent.created} type="date" />
      <ExaminationCard {...economicAgent.juridic_form} type="nomenclature" />
      <ExaminationCard {...economicAgent.site} />
      <ExaminationCard {...economicAgent.part_of_group} type="boolean" />
      <ExaminationCard {...economicAgent.detail_group} NA />
      <ExaminationCard {...economicAgent.license_group} type="boolean" />
      <ExaminationCard {...economicAgent.license_name_group} NA />
      <ExaminationCard {...economicAgent.license_audit} type="boolean" />
      <ExaminationCard {...economicAgent.license_audit_group} NA />
      <ExaminationCard {...economicAgent.reorganization} />

      <ExaminationCard attachmentList />
    </>
  );
};
