import { BadgeTabLabel } from "@aeo/core/components";
import { CompleteTasks, CurrentTasks } from "features/tasks/components";

export const taskTabs = [
  {
    label: <BadgeTabLabel title="Curente" />,
    route: "current",
    content: <CurrentTasks />,
  },
  {
    label: <BadgeTabLabel title="Complete" />,
    route: "complete",
    content: <CompleteTasks />,
  },
];
