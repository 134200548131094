import classNames from "classnames";

import { makeBEM, getStatusColor } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import models from "@aeo/core/models";
import { Flex } from "@aeo/core/components";

const bem = makeBEM("aeo-table");

interface StatusBadgeProps {
  state?: models.ApplicationState | models.AuthorizationState;
}

export const StatusBadge = ({ state }: StatusBadgeProps) => {
  if (!state) {
    return null;
  }

  return (
    <Flex justify="start" align="center">
      <span
        className={classNames(bem("status-circle"), getStatusColor(state))}
      />
      {messages.applicationStates[state]}
    </Flex>
  );
};
