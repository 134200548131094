import React from "react";
import { useInfiniteQuery } from "react-query";
import { Form, Select } from "ebs-design";
import { SelectMode } from "ebs-design/dist/components/molecules/Select/interfaces";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import {
  combineQueryPagesData,
  controlSelect,
  hasNextPage,
} from "@aeo/core/utils";
import {
  WhiteSpace,
  DebouncedInput,
  LoadingButton,
} from "@aeo/core/components";

import api, { querykeys } from "api";

interface ApplicationSelectProps extends FormFieldProps {
  placeholder?: string;
  mode?: SelectMode;
  size?: string;
  disabled?: boolean;
}

export const OrganizationSelect = ({
  size = "large",
  placeholder,
  mode,
  disabled,
  ...props
}: ApplicationSelectProps) => {
  const [search, setSearch] = React.useState("");
  const queryParams = { search };

  const query = useInfiniteQuery(
    querykeys.agents.select({
      ...queryParams,
    }),
    ({ pageParam = 1 }) =>
      api.agents.get({
        ...queryParams,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
    },
  );
  const data = combineQueryPagesData(query.data);

  return (
    <Form.Field {...props}>
      {(_control) => {
        const control = controlSelect(_control, data);

        return (
          <Select
            loading={query.isLoading}
            placeholder={placeholder}
            size={size}
            mode={mode}
            {...control}
            disabled={disabled}
          >
            <DebouncedInput
              placeholder="Caută"
              value={search}
              onChange={setSearch}
            />
            <WhiteSpace v="0.5rem" />

            <Select.Options emptyLabel="Nu a fost găsit">
              {data.map((item) => (
                <Select.Options.Item key={item.id} value={item.id || 0}>
                  {item.name}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <WhiteSpace v="0.5rem" />
            {query.hasNextPage && (
              <LoadingButton
                loading={query.isFetching}
                onClick={() => query.fetchNextPage()}
                size="small"
              >
                Încarcă mai multe
              </LoadingButton>
            )}
          </Select>
        );
      }}
    </Form.Field>
  );
};
