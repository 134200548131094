import { Card } from "ebs-design";

import models from "@aeo/core/models";
import { WhiteSpace } from "@aeo/core/components";

import {
  AeoComplaints,
  SvComplaints,
  Reaudits,
} from "features/agents/components/tables";

interface Props {
  data?: models.Agent;
}

export const Monitoring = ({ data }: Props) => {
  return (
    <Card>
      <Card.Header>
        <h3>Monitorizare</h3>
      </Card.Header>
      <Card.Body>
        <Reaudits data={data} />
        <WhiteSpace v="1rem" />
        <AeoComplaints data={data} />
        <WhiteSpace v="1rem" />
        <SvComplaints data={data} />
      </Card.Body>
    </Card>
  );
};
