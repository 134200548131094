import { TableColumnBuilder } from "@aeo/core/utils";
import models from "@aeo/core/models";
import fields from "@aeo/core/fields";

// WARNING: Do not remove commented lines
export const declarationColumns = () =>
  new TableColumnBuilder<models.AsycudaDeclaration>()
    .add(fields.asycudaFields.declaration.id, "id")
    // .add(fields.asycudaFields.declaration.identifier, "identifier")
    // .add(fields.asycudaFields.declaration.code, "code")
    // .add(fields.asycudaFields.declaration.status, "status")
    .add(fields.asycudaFields.declaration.type, "type")
    // .add(fields.asycudaFields.declaration.complimentary, "complimentary")
    // .add(fields.asycudaFields.declaration.principal, "principal")
    // .add(fields.asycudaFields.declaration.article, "article")
    .add(fields.asycudaFields.declaration.export_code, "export_code")
    // .add(fields.asycudaFields.declaration.export_country, "export_country")
    .add(
      fields.asycudaFields.declaration.transaction_country,
      "transaction_country",
    )
    // .add(fields.asycudaFields.declaration.name, "name")
    // .add(fields.asycudaFields.declaration.expedition_code, "expedition_code")
    // .add(fields.asycudaFields.declaration.origin_country, "origin_country")
    // .add(
    //   fields.asycudaFields.declaration.transport_identity,
    //   "transport_identity",
    // )
    // .add(fields.asycudaFields.declaration.delivery_terms, "delivery_terms")
    // .add(fields.asycudaFields.declaration.place_and_date, "place_and_date")
    // .add(fields.asycudaFields.declaration.internal_value, "internal_value")
    // .add(fields.asycudaFields.declaration.currency_value, "currency_value")
    // .add(fields.asycudaFields.declaration.currency_code, "currency_code")
    // .add(fields.asycudaFields.declaration.exchange_rate, "exchange_rate")
    // .add(fields.asycudaFields.declaration.transaction_code, "transaction_code")
    // .add(
    //   fields.asycudaFields.declaration.transaction_particular_code,
    //   "transaction_particular_code",
    // )
    // .add(
    //   fields.asycudaFields.declaration.transport_internal,
    //   "transport_internal",
    // )
    // .add(fields.asycudaFields.declaration.bank_code, "bank_code")
    // .add(fields.asycudaFields.declaration.bank_name, "bank_name")
    // .add(fields.asycudaFields.declaration.subdivision_code, "subdivision_code")
    // .add(
    //   fields.asycudaFields.declaration.settlement_account,
    //   "settlement_account",
    // )
    // .add(
    //   fields.asycudaFields.declaration.payment_term_code,
    //   "payment_term_code",
    // )
    // .add(
    //   fields.asycudaFields.declaration.payment_term_description,
    //   "payment_term_description",
    // )
    // .add(fields.asycudaFields.declaration.customs_code, "customs_code")
    // .add(fields.asycudaFields.declaration.customs_name, "customs_name")
    // .add(fields.asycudaFields.declaration.goods_location, "goods_location")
    // .add(fields.asycudaFields.declaration.tax_calculation, "tax_calculation")
    // .add(fields.asycudaFields.declaration.payment_report, "payment_report")
    // .add(fields.asycudaFields.declaration.warehouse_code, "warehouse_code")
    // .add(fields.asycudaFields.declaration.warehouse_term, "warehouse_term")
    .getColumns();

// WARNING: Do not remove commented lines
export const itemColumns = () =>
  new TableColumnBuilder<models.AsycudaItem>()
    .add(fields.asycudaFields.item.id, "id")
    // .add(fields.asycudaFields.item.identifier, "identifier")
    // .add(fields.asycudaFields.item.package_first, "package_first")
    // .add(fields.asycudaFields.item.package_second, "package_second")
    // .add(fields.asycudaFields.item.package_code, "package_code")
    // .add(fields.asycudaFields.item.package_name, "package_name")
    // .add(fields.asycudaFields.item.container_first, "container_first")
    // .add(fields.asycudaFields.item.container_second, "container_second")
    // .add(fields.asycudaFields.item.container_third, "container_third")
    // .add(fields.asycudaFields.item.container_fourth, "container_fourth")
    .add(fields.asycudaFields.item.goods_description, "goods_description")
    // .add(fields.asycudaFields.item.package_number, "package_number")
    .add(fields.asycudaFields.item.cargo_code_first, "cargo_code_first")
    // .add(fields.asycudaFields.item.cargo_code_second, "cargo_code_second")
    // .add(fields.asycudaFields.item.cargo_code_third, "cargo_code_third")
    // .add(fields.asycudaFields.item.cargo_code_fourth, "cargo_code_fourth")
    // .add(fields.asycudaFields.item.cargo_code_fifth, "cargo_code_fifth")
    // .add(fields.asycudaFields.item.origin_country_code, "origin_country_code")
    // .add(fields.asycudaFields.item.gross_weight, "gross_weight")
    .add(fields.asycudaFields.item.preference, "preference")
    .add(fields.asycudaFields.item.customs_regime, "customs_regime")
    .add(
      fields.asycudaFields.item.customs_specification,
      "customs_specification",
    )
    // .add(fields.asycudaFields.item.net_weight, "net_weight")
    // .add(fields.asycudaFields.item.contingent, "contingent")
    // .add(fields.asycudaFields.item.summary_statement, "summary_statement")
    // .add(fields.asycudaFields.item.measurement_code, "measurement_code")
    // .add(fields.asycudaFields.item.measurement_quantity, "measurement_quantity")
    // .add(fields.asycudaFields.item.measurement_name, "measurement_name")
    // .add(
    //   fields.asycudaFields.item.measurement_code_first,
    //   "measurement_code_first",
    // )
    // .add(
    //   fields.asycudaFields.item.measurement_quantity_first,
    //   "measurement_quantity_first",
    // )
    // .add(
    //   fields.asycudaFields.item.measurement_name_first,
    //   "measurement_name_first",
    // )
    // .add(
    //   fields.asycudaFields.item.measurement_code_second,
    //   "measurement_code_second",
    // )
    // .add(
    //   fields.asycudaFields.item.measurement_quantity_second,
    //   "measurement_quantity_second",
    // )
    // .add(
    //   fields.asycudaFields.item.measurement_name_second,
    //   "measurement_name_second",
    // )
    // .add(
    //   fields.asycudaFields.item.price_invoice_currency,
    //   "price_invoice_currency",
    // )
    // .add(
    //   fields.asycudaFields.item.price_invoice_currency_code,
    //   "price_invoice_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_invoice_currency_curs,
    //   "price_invoice_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_invoice_currency_curs_reference,
    //   "price_invoice_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.price_external, "price_external")
    // .add(
    //   fields.asycudaFields.item.price_external_currency,
    //   "price_external_currency",
    // )
    // .add(
    //   fields.asycudaFields.item.price_external_currency_code,
    //   "price_external_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_external_currency_curs,
    //   "price_external_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_external_currency_curs_reference,
    //   "price_external_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.price_internal, "price_internal")
    // .add(
    //   fields.asycudaFields.item.price_internal_currency,
    //   "price_internal_currency",
    // )
    // .add(
    //   fields.asycudaFields.item.price_internal_currency_code,
    //   "price_internal_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_internal_currency_curs,
    //   "price_internal_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_internal_currency_curs_reference,
    //   "price_internal_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.price_ensuring, "price_ensuring")
    // .add(
    //   fields.asycudaFields.item.price_ensuring_currency_code,
    //   "price_ensuring_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_ensuring_currency_curs,
    //   "price_ensuring_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_ensuring_currency_curs_reference,
    //   "price_ensuring_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.price_other, "price_other")
    // .add(
    //   fields.asycudaFields.item.price_other_currency_code,
    //   "price_other_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_other_currency_curs,
    //   "price_other_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_other_currency_curs_reference,
    //   "price_other_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.price_deduction, "price_deduction")
    // .add(
    //   fields.asycudaFields.item.price_deduction_currency_code,
    //   "price_deduction_currency_code",
    // )
    // .add(
    //   fields.asycudaFields.item.price_deduction_currency_curs,
    //   "price_deduction_currency_curs",
    // )
    // .add(
    //   fields.asycudaFields.item.price_deduction_currency_curs_reference,
    //   "price_deduction_currency_curs_reference",
    // )
    // .add(fields.asycudaFields.item.closing_documents, "closing_documents")
    // .add(
    //   fields.asycudaFields.item.additional_deposit_code,
    //   "additional_deposit_code",
    // )
    // .add(fields.asycudaFields.item.licence_code, "licence_code")
    // .add(fields.asycudaFields.item.licence_value, "licence_value")
    // .add(fields.asycudaFields.item.licence_quantity, "licence_quantity")
    // .add(fields.asycudaFields.item.text_free, "text_free")
    // .add(fields.asycudaFields.item.text_reserved, "text_reserved")
    .add(fields.asycudaFields.item.statistical_value, "statistical_value")
    // .add(
    //   fields.asycudaFields.item.evaluation_method_code,
    //   "evaluation_method_code",
    // )
    .getColumns();

// WARNING: Do not remove commented lines
export const segmentColumns = () =>
  new TableColumnBuilder<models.AsycudaSegment>()
    .add(fields.asycudaFields.segment.id, "id")
    // .add(fields.asycudaFields.segment.identifier, "identifier")
    // .add(fields.asycudaFields.segment.principal, "principal")
    // .add(fields.asycudaFields.segment.complimentary, "complimentary")
    // .add(fields.asycudaFields.segment.article, "article")
    // .add(fields.asycudaFields.segment.package_count, "package_count")
    // .add(fields.asycudaFields.segment.declaration, "declaration")
    // .add(fields.asycudaFields.segment.reference_number, "reference_number")
    .add(fields.asycudaFields.segment.exporter_cod, "exporter_cod")
    .add(fields.asycudaFields.segment.exporter_name, "exporter_name")
    .add(fields.asycudaFields.segment.recipient_name, "recipient_name")
    // .add(fields.asycudaFields.segment.export_code, "export_code")
    // .add(fields.asycudaFields.segment.export_country, "export_country")
    // .add(
    //   fields.asycudaFields.segment.recipient_country_code,
    //   "recipient_country_code",
    // )
    // .add(
    //   fields.asycudaFields.segment.recipient_country_name,
    //   "recipient_country_name",
    // )
    // .add(
    //   fields.asycudaFields.segment.transport_identification,
    //   "transport_identification",
    // )
    // .add(
    //   fields.asycudaFields.segment.transport_country_code,
    //   "transport_country_code",
    // )
    // .add(fields.asycudaFields.segment.transport_mode, "transport_mode")
    .add(fields.asycudaFields.segment.broker_code, "broker_code")
    // .add(fields.asycudaFields.segment.broker_name, "broker_name")
    // .add(
    //   fields.asycudaFields.segment.transport_signature,
    //   "transport_signature",
    // )
    // .add(fields.asycudaFields.segment.transport_stamp, "transport_stamp")
    // .add(fields.asycudaFields.segment.reference_year, "reference_year")
    // .add(
    //   fields.asycudaFields.segment.transport_arrival_identification,
    //   "transport_arrival_identification",
    // )
    // .add(
    //   fields.asycudaFields.segment.transport_arrival_code,
    //   "transport_arrival_code",
    // )
    // .add(
    //   fields.asycudaFields.segment.transport_container,
    //   "transport_container",
    // )
    .getColumns();
