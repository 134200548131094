import { Flex, WhiteSpace } from "@aeo/core/components";
import { DetailsByType } from "..";
import { Resume } from "../Summary";
import { Documents } from "../Documents";

export const Summary = () => {
  return (
    <Flex justify="flex-start" align="flex-start" wrap="wrap">
      <div className="details-by-type">
        <DetailsByType />
      </div>
      <div className="resume">
        <Resume />
        <WhiteSpace v="1rem" />
        <Documents />
      </div>
    </Flex>
  );
};
