import { useRouteMatch, Route } from "react-router-dom";
import { Form, useForm, useNotify } from "ebs-design";
import { useMutation } from "react-query";

import { AttachmentsFormField, Flex, WhiteSpace } from "@aeo/core/components";
import models from "@aeo/core/models";
import { useRole } from "@aeo/core/hooks";
import { notifyErrors } from "@aeo/core/utils";

import api from "api";
import { useApplication } from "../../hooks";
import { Criteria, Subcriteria } from "../Criteria";
import { ControlOrderReAudit, CompanyDetails } from "..";
import { SubcriterionExamination, ControlPlanButtons } from ".";

export const ReAudit = () => {
  const [form] = useForm();
  const routeMatch = useRouteMatch();
  const application = useApplication();
  const notify = useNotify();

  const { isManager } = useRole(application?.organization?.manager?.id);

  const updateReAudit = useMutation(
    (v: { attachments?: models.Attachment[] }) =>
      api.reAudit.patch(application?.id!, v),
    { onError: (error) => notifyErrors(notify, error) },
  );

  return (
    <Flex justify="flex-start" align="flex-start" wrap="wrap">
      <div className="details-by-type">
        <Route path={routeMatch.path} exact>
          {(application?.report_order || isManager) && (
            <>
              <ControlOrderReAudit />
              <WhiteSpace v="1rem" />
            </>
          )}

          <Criteria type="re-audit" disabled={!application.re_report} />
          <WhiteSpace v="1rem" />
          <CompanyDetails>
            <h3 className="uppercase">Documente atașate</h3>
            <WhiteSpace v="1rem" />
            <Form
              form={form}
              initialValues={{
                attachments: application?.re_report?.attachments,
              }}
              onValuesChange={(attachments) =>
                updateReAudit.mutate(attachments)
              }
            >
              <AttachmentsFormField name="attachments">
                Atașează documente
              </AttachmentsFormField>
            </Form>
          </CompanyDetails>
        </Route>
        <Route path={`${routeMatch.path}/:criterion/:subcriterion`} exact>
          <SubcriterionExamination />
        </Route>
      </div>
      <Route path={`${routeMatch.path}/:criterion`} exact>
        <Subcriteria backLink={routeMatch.url} location="re-audit" />
      </Route>
      <Route path={routeMatch.path} exact>
        <div className="resume">
          {isManager && (
            <>
              <ControlPlanButtons />
              <WhiteSpace v="1rem" />
            </>
          )}
        </div>
      </Route>
    </Flex>
  );
};
