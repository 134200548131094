import { useQuery } from "react-query";
import { Card, Loader } from "ebs-design";

import api from "@aeo/core/api";
import models from "@aeo/core/models";
import { usePagination } from "@aeo/core/hooks";
import { Flex, Pagination, WhiteSpace } from "@aeo/core/components";

import { EditPeriodItem } from "./EditPeriodItem";

export const PeriodSettings = () => {
  const pagination = usePagination();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const { data, isLoading } = useQuery(
    ["application-states", queryParams],
    () =>
      api.nomenclatures.getEntityList<models.PeriodNomenclature>(
        "application-states",
        queryParams,
      ),
  );

  return (
    <Card>
      <Card.Header>
        <h3>Perioade</h3>
      </Card.Header>
      <Card.Body>
        <Loader loading={isLoading} style={{ maxWidth: 900 }}>
          {data?.results?.map((item) => (
            <EditPeriodItem key={item.id} item={item} />
          ))}
          <WhiteSpace />
        </Loader>
      </Card.Body>

      <Card.Footer>
        <Flex justify="flex-end">
          <Pagination
            data={data}
            pagination={pagination}
            pageSizeChoice={[5, 10, 15]}
          />
        </Flex>
      </Card.Footer>
    </Card>
  );
};
