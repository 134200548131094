import React from "react";
import { UseQueryResult } from "react-query";

import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import models from "@aeo/core/models";
import { makeBEM } from "@aeo/core/utils";
import { WhiteSpace } from "@aeo/core/components";

export interface CriterionSelectModalProps
  extends Omit<ModalProps, "onSelect"> {
  query: UseQueryResult<models.WithResults<models.CriterionNomenclature>>;
  selected?: number;
  onSelect?: React.Dispatch<number>;
}

const selectableBem = makeBEM("selectable");
const bem = makeBEM("select-criterion-modal");

export const CriterionSelectModal = ({
  query,
  selected,
  onSelect,
  ...props
}: CriterionSelectModalProps) => {
  const criterions = query.data?.results;

  const clickHandler = (id?: number) => () => {
    if (id) onSelect?.(id);
    props.onClose?.();
  };

  return (
    <Modal title="Selectează criteriul" {...props}>
      <Modal.Content className={bem("content")}>
        {criterions?.map?.((criterion) => (
          <React.Fragment key={criterion.id}>
            <h4>{criterion.name}</h4>
            {criterion.children?.map((subcriterion) => (
              <div
                key={subcriterion.id}
                onClick={clickHandler(subcriterion.id)}
                className={selectableBem(null, {
                  selected: subcriterion.id === selected,
                })}
                style={{ paddingLeft: "2rem" }}
              >
                {subcriterion.name}
              </div>
            ))}
            <WhiteSpace v="1rem" />
          </React.Fragment>
        ))}
      </Modal.Content>
    </Modal>
  );
};
