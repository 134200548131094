import { Link } from "react-router-dom";
import { NumberParam } from "use-query-params";

import { Badge, Flex, WhiteSpace } from "@aeo/core/components";
import { formattedDateTime, makeBEM } from "@aeo/core/utils";
import { ChartOrganizationConversation } from "@aeo/core/models/chat";
import { useParam } from "@aeo/core/hooks";

export interface ConversationLinkProps extends ChartOrganizationConversation {}

const bem = makeBEM("chat-conversation-link");

export const ConversationLink = ({
  id,
  name,
  replies_unread_count,
  conversation,
}: ConversationLinkProps) => {
  const idParam = useParam("id", NumberParam.decode);

  return (
    <Link to={`/chat/${id}`} className={bem(null, { active: idParam === id })}>
      <Flex>
        <h4 className={bem("title")}>{name}</h4>
        <WhiteSpace h="0.5rem" />
        {replies_unread_count ? (
          <Badge round variant="fill" size="sm" color="danger">
            {replies_unread_count}
          </Badge>
        ) : null}
      </Flex>
      <WhiteSpace h="0.25rem" />
      <div className={bem("subtitle")}>
        {conversation?.latest_reply?.user?.name}
        {conversation?.latest_reply?.user?.function &&
          ` - ${conversation?.latest_reply?.user?.function}`}
      </div>
      <div className={bem("last-message")}>
        {conversation?.latest_reply?.body}
      </div>
      <WhiteSpace h="0.25rem" />
      <div className={bem("date")}>
        {formattedDateTime(conversation?.latest_reply?.created_at)}
      </div>
    </Link>
  );
};
