import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";
import { GenericObject } from "@aeo/core/types";

export const criterions = {
  done: async (id: number, type: string) => {
    const { data } = await apiAxios.get<
      models.WithResults<models.CriterionNomenclature>
    >(`/cs/${type}/${id}/done`);
    return data;
  },
  detailsDone: async (id: number, type: string, query: GenericObject = {}) => {
    const url = stringifyUrl(`/cs/${type}/${id}/done/details`, query);
    const { data } = await apiAxios.get<models.CriterionNomenclature>(url);
    return data;
  },
  update: async (id: number, type: string, body: any) => {
    const { data } = await apiAxios.patch<models.CriterionNomenclature>(
      `/cs/${type}/${id}/criterion`,
      body,
    );
    return data;
  },
};
