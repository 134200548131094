import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const profile = {
  get: async () => {
    const { data } = await apiAxios.get<models.Profile>("/cs/profile");
    return data;
  },
  patch: async (body: models.User) => {
    const { data } = await apiAxios.patch<models.User>(`/cs/profile`, body);
    return data;
  },
  notifications: async () => {
    const { data } = await apiAxios.get<models.MenuNotifications>(
      "/cs/profile/unviewed",
    );
    return data;
  },
};
