import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Button, Form, Modal, useForm, useNotify, Select } from "ebs-design";

import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  Flex,
  InputFormField,
  InputIDNPFormField,
  InputPhoneFormField,
  LoadingButton,
  NomenclaturesSelect,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import {
  getProperty,
  normalizeNomenclatures,
  setFieldsErrors,
  mcsUserRolesOptions,
} from "@aeo/core/utils";
import fields from "@aeo/core/fields";

import api from "api";

interface Props extends ModalProps {
  data?: models.User;
}
export const UserFormModal = ({ data, ...props }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const isEditing = useMemo(() => !!data, [data]);

  const user = fields.user;

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError<models.ErrorResponse>,
    models.User
  >(
    async (values) =>
      isEditing
        ? api.users.editUser({ id: data?.id, ...values })
        : api.users.createUser(values),
    {
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(getProperty(error.response?.data), form, notify);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        form.resetFields();
        props?.onClose?.();
      },
    },
  );

  return (
    <Modal
      title={isEditing ? "Editează utilizatorul" : "Adaugă utilizator"}
      size="small"
      {...props}
    >
      <Form
        form={form}
        initialValues={data}
        onFinish={(values: models.User) => {
          mutate({
            ...values,
            profile: normalizeNomenclatures(values.profile),
          });
        }}
      >
        <Modal.Content>
          <InputFormField name="last_name" label="Nume" required />
          <InputFormField name="first_name" label="Prenume" required />
          <InputIDNPFormField name="idnp" label="IDNP" required />

          {!isEditing && (
            <InputFormField name="username" label="E-mail" required />
          )}

          <InputPhoneFormField name="phone" label="Telefon" required />
          <InputFormField
            name={["profile", "function"]}
            label="Funcție"
            required
          />
          <NomenclaturesSelect
            entityName={models.Nomenclatures.Domains}
            mode="single"
            {...user.domain}
          />
          <Form.Field name={["profile", "role"]} label="Rol utilizator">
            <Select size="large" options={mcsUserRolesOptions} />
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={props.onClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton loading={isLoading} submit type="primary">
              {isEditing ? "Salvează modificarile" : "Adaugă utilizator"}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
