import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types";
import models from "@aeo/core/models";

export const organizations = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/organizations", queryParams);
    const { data } = await apiAxios.get<
      models.WithResults<models.Organization>
    >(url);
    return data;
  },
  patch: async (id: number, body: models.ManagerOrganization) => {
    const { data } = await apiAxios.patch(`/cs/organizations/${id}`, body);
    return data;
  },
};
