import * as React from "react";
import cn from "classnames";
import { Extra, Label, Loader } from "ebs-design";

export type InputSize = "small" | "medium" | "large";
export type InputStyleType = "white" | "grey";

export interface FakeInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLDivElement>,
    "size" | "prefix" | "onChange"
  > {
  styleType?: InputStyleType;
  hasError?: boolean;
  label?: React.ReactNode;
  extra?: React.ReactNode;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
  loading?: boolean;
  autoFocus?: boolean;
  containerClass?: string;
  size?: InputSize;
  isClearable?: boolean;

  onKeyDown?: (e: React.KeyboardEvent) => void;
  onClickPrefix?: () => void;
  onClickSuffix?: () => void;

  cursor?: React.CSSProperties["cursor"];
  onClear?: () => void;
}

export const FakeInput = React.forwardRef<HTMLDivElement, FakeInputProps>(
  (
    {
      styleType = "white",
      type = "text",
      size = "large",
      onKeyDown,
      hasError,
      label,
      extra,
      value,
      prefix,
      suffix,
      loading,
      disabled,
      width,
      autoFocus,
      className,
      containerClass,
      isClearable,
      cursor,
      onClear,
      ...props
    },
    ref,
  ) => {
    const hasValue = React.useMemo(
      // eslint-disable-next-line eqeqeq
      () => value != undefined && value.toString().length,
      [value],
    );

    const onClickPrefixHandler = (): void => {
      if (!loading && props.onClickPrefix) {
        props.onClickPrefix();
      }
    };

    const onClickSuffixHandler = (): void => {
      if (!loading && props.onClickSuffix) {
        props.onClickSuffix();
      }
    };

    return (
      <div className={cn(`ebs-input__container`, containerClass)}>
        <Label text={label} disabled={disabled} />

        <div
          style={{ cursor }}
          className={cn(
            `ebs-input__wrapper`,
            `ebs-input__wrapper--${hasValue ? `active` : `unactive`}`,
            `ebs-input__type--${type}`,
            `ebs-input-style--${styleType}`,
            className,
            {
              "ebs-input__empty": value === "",
              "has-prefix": prefix,
              "has-suffix": suffix,
              "has-error": hasError,
              disabled: disabled,
            },
          )}
        >
          {(loading && !suffix) || prefix ? (
            <div
              className={cn(
                `ebs-input__prefix`,
                !loading && props.onClickPrefix ? `clickable` : `not-clickable`,
              )}
              onClick={onClickPrefixHandler}
            >
              {loading && !suffix ? <Loader.Spinner size="small" /> : prefix}
            </div>
          ) : null}

          {(loading && !prefix && suffix) || suffix ? (
            <div
              className={cn(
                `ebs-input__suffix`,
                !loading && props.onClickSuffix ? `clickable` : `not-clickable`,
              )}
              onClick={onClickSuffixHandler}
            >
              {loading && suffix ? <Loader.Spinner size="small" /> : suffix}
            </div>
          ) : null}

          <div className="ebs-input__container">
            <div
              ref={ref}
              className={cn("ebs-input", `ebs-input--${size}`, "fake-input")}
              onKeyDown={onKeyDown}
              children={value}
              {...props}
            />

            {hasValue && isClearable ? (
              <div className="ebs-input__clear" onClick={onClear}>
                &#215;
              </div>
            ) : null}
          </div>
        </div>

        <Extra
          text={extra}
          status={hasError ? "danger" : "text"}
          disabled={disabled}
        />
      </div>
    );
  },
);
