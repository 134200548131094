import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  DatePickerFormField,
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { setFieldsErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { UsersSelect } from "components";
import {
  ApplicationSelect,
  OrganizationSelect,
} from "features/tasks/components";

interface FormFields {
  id?: number;
  title?: string;
  organization?: models.Organization;
  person?: models.User;
  description?: string;
  executed_at?: string;
}

interface TaskCreationProps extends ModalProps {
  task?: models.Task;
}

export const TaskCreationModal = ({ task, ...props }: TaskCreationProps) => {
  const [organization, setOrganization] = React.useState<models.Agent>();
  const [form] = useForm();

  const queryClient = useQueryClient();
  const notify = useNotify();
  const isEditing = React.useMemo(() => !!task, [task]);

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError<models.ErrorResponse>,
    models.CreateTask
  >(
    async (values) =>
      isEditing ? api.tasks.patch(task?.id!, values) : api.tasks.post(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.tasks.many());
        close();
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(error.response?.data, form, notify);
      },
    },
  );

  const handleForm = (values: models.Task) => {
    mutate({
      ...values,
      // Send exectur as object with id
      executor: {
        id: values.executor as string,
      },
      application: values?.application?.id,
    });
  };

  const close = () => {
    form.resetFields();
    props?.onClose?.();
    setOrganization(undefined);
  };

  const handleApplicationSelect = (fields: FormFields) => {
    if (!!fields?.organization) {
      setOrganization(fields.organization);
      form.resetFields(["application"]);
    }
  };

  React.useEffect(() => {
    form.setFieldsValue(task);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <Modal
      size="small"
      {...props}
      onClose={close}
      title={task ? "Editează sarcina" : "Adaugă o sarcină"}
    >
      <Form
        form={form}
        onFinish={handleForm}
        onValuesChange={handleApplicationSelect}
      >
        <Modal.Content>
          <InputFormField name="title" label="Titlu" required />
          <OrganizationSelect
            name="organization"
            label="Operatori economici"
            mode="single"
            placeholder="Selectează un operator economic"
          />
          <ApplicationSelect
            name="application"
            label="Cereri"
            placeholder="Selectează o aplicare"
            mode="single"
            organization={organization}
            disabled={task?.application ? !task.application : !organization?.id}
          />
          <UsersSelect
            name="executor"
            label="Atribuie un utilizator"
            placeholder="Selectează un utilizator"
            mode="single"
            rules={[{ required: true }]}
          />
          <InputFormField name="description" label="Descriere" textarea />
          <DatePickerFormField
            name="execution_date"
            label="Dată"
            placeholder="Selectează o data"
          />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={close}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton loading={isLoading} submit type="primary">
              Salvează modificarile
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
