import models from "../models";
import { GenericObject } from "../types";
import { useAuth } from ".";

export const useIsManager =
  () => (application?: models.ActiveApplication & GenericObject<any>) => {
    const { user } = useAuth();

    return (
      user?.id === application?.organization?.manager?.id
      /* OLD
        || role === UserRoles.superuser
      */
    );
  };
