import React from "react";

import { Button, List, Checkbox, Form } from "ebs-design";
import { ListProps } from "rc-field-form/es/List";

import { Flex, WhiteSpace, InputFormField, Icon } from "@aeo/core/components";
import {
  impactOptions,
  makeNamePath,
  probabilityOptions,
} from "@aeo/core/utils";
import { ApplicationExaminationStateType } from "@aeo/core/types";

import { RiskSelectField } from ".";

export interface RiskListProps extends ListProps {
  state?: ApplicationExaminationStateType;
}

export const RiskList = ({ state, ...props }: RiskListProps) => {
  return (
    <List initialValue={[]} {...props}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, i) => (
              <React.Fragment key={field.key}>
                <Flex align="center">
                  <div className="flex-1">Risc {i + 1}</div>
                  <WhiteSpace h="1rem" />
                  <span className="color-gray">Probabilitatea:</span>
                  <WhiteSpace h="0.25rem" />
                  <RiskSelectField
                    field={field}
                    name="probability"
                    options={probabilityOptions}
                  />

                  <WhiteSpace h="1rem" />
                  <span className="color-gray">Impactul:</span>
                  <WhiteSpace h="0.25rem" />
                  <RiskSelectField
                    field={field}
                    name="impact"
                    options={impactOptions}
                  />

                  <WhiteSpace h="1rem" />
                  <Button
                    icon={() => <Icon type="close" />}
                    onClick={() => remove(i)}
                    size="small"
                  />
                </Flex>

                <WhiteSpace v="0.5rem" />

                <InputFormField
                  label="Descriere"
                  // hideLabel
                  required
                  textarea
                  name={makeNamePath(field.name, "description")}
                  className="m-0"
                />
                <WhiteSpace v="0.5rem" />
                {(state === "audit" || state === "re-audit") && (
                  <InputFormField
                    label="Constatare"
                    textarea
                    name={makeNamePath(
                      field.name,
                      state === "audit"
                        ? "audit_statements"
                        : "re_audit_statements",
                      0,
                      "description",
                    )}
                    className="m-0"
                  />
                )}

                {/* Audit and re-audit statements should have default value */}
                <Form.Field
                  name={makeNamePath(
                    field.name,
                    state === "audit"
                      ? "audit_statements"
                      : "re_audit_statements",
                  )}
                  initialValue={[]}
                />

                <WhiteSpace h="1rem" />
                {(state === "audit" || state === "re-audit") && (
                  <Form.Field
                    name={makeNamePath(
                      field.name,
                      state === "audit" ? "residual" : "re_residual",
                    )}
                  >
                    {(control) => (
                      <Checkbox
                        text="Este risc spre monitorizare?"
                        checked={control.value}
                        onChange={(v) => control.onChange(v)}
                      />
                    )}
                  </Form.Field>
                )}

                <Form.Field
                  name={makeNamePath(field.name, "initial")}
                  initialValue={false}
                />
              </React.Fragment>
            ))}
            <Button
              block
              prefix={<Icon type="plus" />}
              onClick={() => {
                add({});
              }}
            >
              Adaugă risc
            </Button>
          </>
        );
      }}
    </List>
  );
};
