import { Button } from "ebs-design";
import cn from "classnames";

import { Flex, Icon } from ".";

interface Props {
  title?: string;
  onClose?: () => void;
  className?: string;
}
export const ModalHeader = ({ title, onClose, className }: Props) => {
  return (
    <Flex justify="space-between" className={cn("px-28 py-21", className)}>
      <h2>{title}</h2>
      <Button icon={() => <Icon type="close" size="1em" />} onClick={onClose} />
    </Flex>
  );
};
