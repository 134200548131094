import React from "react";
import { RouteBuilder } from "@aeo/core/utils";

import { Login, ResetPassword, RecoveryPassword } from "./features/auth/pages";

import { Dashboard } from "./features/dashboard/pages";
import { Agent, Agents } from "./features/agents/pages";
import { Applications, ApplicationFlow } from "./features/applications/pages";
import { Tasks } from "./features/tasks/pages";
import { Approval, Approvals } from "./features/approvals/pages";
import { Order, Orders } from "./features/orders/pages";
import { Settings } from "./features/settings/pages";
import { Chat } from "./features/chat/pages";
import { News } from "./features/news/pages";
import { Users } from "./features/users/pages";
import { Authorizations } from "./features/authorizations/pages";

export const authRoutes = new RouteBuilder()
  .add("/login", Login)
  .add("/reset-password", ResetPassword)
  .add("/recovery-password", RecoveryPassword)
  .getRoutes();

export const appRoutes = new RouteBuilder()
  .add("/", Dashboard, true)
  .add("/agents/:id", Agent)
  .add("/agents", Agents)
  .add("/users", Users)
  .add("/applications/flow/:id", ApplicationFlow)
  .add("/applications", Applications)
  .add("/tasks", Tasks)
  .add("/approvals/view/:id", Approval)
  .add("/approvals", Approvals)
  .add("/orders/:id", Order)
  .add("/orders", Orders)
  .add("/settings", Settings)
  .add("/chat/:id?", Chat)
  .add("/news", News)
  .add("/authorizations", Authorizations)

  .add("*", () => React.createElement("div", { children: "Not found" }))

  .getRoutes();
