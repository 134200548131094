import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import models from "@aeo/core/models";
import messages from "@aeo/core/messages";
import { examinationPageTitle } from "@aeo/core/messages/saqTabs";
import { AutoScrollToTop, Flex, WhiteSpace } from "@aeo/core/components";
import { arrIncludes } from "@aeo/core/utils";
import { ApplicationContext } from "@aeo/core/contexts";

import { Resume } from "../Summary";
import { Documents } from "../Documents";
import { DetailsByType } from "..";
import {
  EconomicAgent,
  Contact,
  Customer,
  CommercialActivity,
  OrganizationStructure,
  Turnover,
  CustomsStatistic,
  ComplianceLegislation,
  LogisticOne,
  LogisticTwo,
  LogisticThree,
  FinancialSolvency,
  PracticalStandard,
  SecurityOne,
  SecurityTwo,
  SecurityThree,
  SecurityFour,
  PersonList,
  Shareholders,
  ExaminationForm,
  RequestDetails,
  Advisory,
} from ".";

export const PreAudit = () => {
  const application = React.useContext(ApplicationContext);
  const routeMatch = useRouteMatch();
  const msgs = messages.SAQTabs;

  const examinable = application?.state === models.ApplicationState.pre_audit;

  const makeExamineRoute = (
    path: models.ApplicationValidatePath,
    title: React.ReactNode,
    content: React.ReactNode,
  ) => {
    return (
      <Route path={`${routeMatch.path}/examine/${path}` as string}>
        <ExaminationForm
          {...{ title, path, content }}
          backLink={routeMatch.url}
        />
        <AutoScrollToTop />
      </Route>
    );
  };

  return (
    <Flex justify="flex-start" align="flex-start" wrap="wrap">
      <div className="details-by-type">
        <Switch>
          <Route path={routeMatch.path} exact>
            <DetailsByType examinable={examinable} />
          </Route>
          {makeExamineRoute(
            "request",
            examinationPageTitle.request,
            <RequestDetails />,
          )}
          {makeExamineRoute(
            "saq/economic_agent",
            examinationPageTitle.economicAgent,
            <EconomicAgent />,
          )}
          {makeExamineRoute(
            "saq/shareholders",
            examinationPageTitle.shareholders,
            <Shareholders />,
          )}
          {makeExamineRoute(
            "saq/directors",
            examinationPageTitle.directors,
            <PersonList title={msgs.directors.title} />,
          )}
          {makeExamineRoute(
            "saq/advisory",
            examinationPageTitle.advisory,
            <Advisory />,
          )}
          {makeExamineRoute(
            "saq/contact",
            examinationPageTitle.contactPerson,
            <Contact />,
          )}
          {makeExamineRoute(
            "saq/customer",
            examinationPageTitle.customer,
            <Customer />,
          )}
          {makeExamineRoute(
            "saq/commercial",
            examinationPageTitle.commercial,
            <CommercialActivity />,
          )}
          {makeExamineRoute(
            "saq/structure",
            examinationPageTitle.structure,
            <OrganizationStructure />,
          )}
          {makeExamineRoute(
            "saq/turnover",
            examinationPageTitle.turnover,
            <Turnover />,
          )}
          {makeExamineRoute(
            "saq/statistic",
            examinationPageTitle.statistic,
            <CustomsStatistic />,
          )}
          {makeExamineRoute(
            "saq/legislation",
            examinationPageTitle.legislation,
            <ComplianceLegislation />,
          )}
          {makeExamineRoute(
            "saq/logistic_one",
            examinationPageTitle.logisticOne,
            <LogisticOne />,
          )}
          {makeExamineRoute(
            "saq/logistic_two",
            examinationPageTitle.logisticTwo,
            <LogisticTwo />,
          )}
          {makeExamineRoute(
            "saq/logistic_three",
            examinationPageTitle.logisticThree,
            <LogisticThree />,
          )}
          {makeExamineRoute(
            "saq/solvency",
            examinationPageTitle.solvency,
            <FinancialSolvency />,
          )}

          {arrIncludes(
            [models.AEOType.AEOC, models.AEOType.AEOF],
            application?.type as models.AEOType,
          ) &&
            makeExamineRoute(
              "saq/standard",
              examinationPageTitle.standard,
              <PracticalStandard />,
            )}
          {arrIncludes(
            [models.AEOType.AEOS, models.AEOType.AEOF],
            application?.type as models.AEOType,
          ) && (
            <>
              {makeExamineRoute(
                "saq/security_one",
                examinationPageTitle.securityOne,
                <SecurityOne />,
              )}
              {makeExamineRoute(
                "saq/security_two",
                examinationPageTitle.securityTwo,
                <SecurityTwo />,
              )}
              {makeExamineRoute(
                "saq/security_three",
                examinationPageTitle.securityThree,
                <SecurityThree />,
              )}
              {makeExamineRoute(
                "saq/security_four",
                examinationPageTitle.securityFour,
                <SecurityFour />,
              )}
            </>
          )}
        </Switch>
      </div>
      <Route path={routeMatch.path} exact>
        <div className="resume">
          <Resume />
          <WhiteSpace v="1rem" />
          <Documents />
        </div>
      </Route>
    </Flex>
  );
};
