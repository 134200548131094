import React from "react";
import { useQuery } from "react-query";
import { Button } from "ebs-design";
import { useUpdateEffect } from "react-use";

import { useModalState, useQueryPagination } from "@aeo/core/hooks";
import {
  QueryTable,
  RoleGuard,
  TableTitle,
  WhiteSpace,
} from "@aeo/core/components";

import api, { querykeys } from "api";
import { NewsModal } from "features/news/components";
import { newsTableColums } from "features/news/config";

export const News = () => {
  const [search, setSearch] = React.useState("");

  const addNewsModalState = useModalState();
  const pagination = useQueryPagination();

  const queryParams = { ...pagination.queryParams, search };

  const query = useQuery(querykeys.news.many(queryParams), () =>
    api.news.get(queryParams),
  );

  useUpdateEffect(() => pagination.setPage(1), [search]);

  return (
    <>
      <h2>Anunțuri</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            extra={
              <RoleGuard restritedTo={["user", "admin"]}>
                <Button type="primary" onClick={addNewsModalState.open}>
                  Adaugă anunț
                </Button>
              </RoleGuard>
            }
          />
        )}
        query={query}
        columns={newsTableColums()}
        scroll={{ x: 1400 }}
      />
      <NewsModal
        open={addNewsModalState.isOpen}
        closeModal={addNewsModalState.close}
      />
    </>
  );
};
