import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const SecurityThree = () => {
  const securityThree = fields.securityThree;

  return (
    <>
      <h4>{messages.titles.enteredGoods.toUpperCase()}</h4>

      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityThree.security_goods_procedure} />
      <KeyValueRow {...securityThree.checking_security_procedure} />
      <KeyValueRow {...securityThree.employee_security_measures} />
      <KeyValueRow {...securityThree.checking_goods_seals} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_import_goods_sealed} type="boolean" />
      <KeyValueRow {...securityThree.has_types_of_goods} type="boolean" />
      <KeyValueRow {...securityThree.import_goods_sealed} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.counting_process} />
      <KeyValueRow {...securityThree.counting_process_register} />
      <KeyValueRow
        {...securityThree.has_separated_responsible}
        type="boolean"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_internal_control} type="boolean" />
      <KeyValueRow {...securityThree.internal_control} NA />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.goodsStorage.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityThree.allocated_storage_subdivision} />
      <KeyValueRow {...securityThree.assigning_storage_procedure} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_outdoor_storage} type="boolean" />
      <KeyValueRow {...securityThree.outdoor_storage} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow
        {...securityThree.has_inventorying_procedures}
        type="boolean"
      />
      <KeyValueRow {...securityThree.inventorying_procedures} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_stored_separate} type="boolean" />
      <KeyValueRow {...securityThree.criteria_separate_storage} />
      <KeyValueRow {...securityThree.register_accounting_goods} />
      <KeyValueRow {...securityThree.protected_goods_access} />
      <KeyValueRow {...securityThree.procedures_verified} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_third_party_storage} type="boolean" />
      <KeyValueRow {...securityThree.third_party_storage} NA />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.goodsProduction.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityThree.production_subdivisions} />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityThree.has_third_party_storage} type="boolean" />
      <KeyValueRow {...securityThree.third_party_storage} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_external_production} type="boolean" />
      <KeyValueRow {...securityThree.external_production} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow
        {...securityThree.has_protect_access_measures}
        type="boolean"
      />
      <KeyValueRow {...securityThree.protect_access_measures} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.product_packaging_procedure} />
      <WhiteSpace v="1rem" />
      <KeyValueRow
        {...securityThree.has_third_party_packaging}
        type="boolean"
      />
      <KeyValueRow {...securityThree.third_party_packaging} NA />
      <WhiteSpace v="2rem" />
      <h4>{messages.titles.goodsLoading.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityThree.goods_loading} />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityThree.has_loading_procedure} type="boolean" />

      <KeyValueRow {...securityThree.has_goods_sealed} type="boolean" />
      <KeyValueRow {...securityThree.goods_sealed} NA />

      <KeyValueRow {...securityThree.has_marking_goods} type="boolean" />
      <KeyValueRow {...securityThree.marking_goods} NA />

      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_seals_register} type="boolean" />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityThree.seals_register} />
      <KeyValueRow {...securityThree.client_requirements} />
      <KeyValueRow {...securityThree.supervision_measures} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.has_checking_completion} type="boolean" />
      <KeyValueRow {...securityThree.checking_completion} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityThree.checking_coming_orders} />
      <KeyValueRow {...securityThree.loading_procedure} />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
