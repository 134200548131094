import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize, prefFieldName } from "@aeo/core/utils";

import { AttachmentsList } from "..";
import { KeyValueList, KeyValueRow } from "..";

export const LogisticOne = () => {
  const logistic = fields.logisticOne;
  const outsourceOrganization = fields.outsourceOrganization;

  return (
    <>
      <h4>{messages.titles.auditTrack.toUpperCase()}</h4>
      <KeyValueRow {...logistic.has_audit_trail} type="boolean" />
      <KeyValueRow {...logistic.audit_trail} NA />
      <WhiteSpace v="1rem" />

      <h4>{capitalize(messages.titles.logisticSubsection)}</h4>
      <KeyValueRow {...logistic.accounting_systems} />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logistic.can_differentiate} type="boolean-no-na" NA />
      <KeyValueRow {...logistic.differentiate} NA />

      <WhiteSpace v="2rem" />

      <KeyValueRow {...logistic.it_activity} NA />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...logistic.has_it_outsourced} type="boolean" />
      <WhiteSpace v="1rem" />
      <KeyValueList
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.name, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.address, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.code_fiscal, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(outsourceOrganization.description, prefix)}
            />
          </>
        )}
        title="Companii"
        name="outsource_organizations"
      />
      <WhiteSpace v="2rem" />
      <h4>{messages.titles.internControl.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />
      <KeyValueRow {...logistic.has_internal_control} type="boolean" />
      <KeyValueRow {...logistic.internal_control} NA />
      <KeyValueRow {...logistic.has_audit_control} type="boolean" />
      <KeyValueRow {...logistic.is_include_parcels} type="boolean" />

      <KeyValueRow {...logistic.recent_audit_report} type="attachments" />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logistic.incorrect_registration} />
      <KeyValueRow {...logistic.incorrect_data} />
      <KeyValueRow {...logistic.incorrect_business_processes} />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
