import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const RequestDetails = () => {
  const request = fields.request;

  return (
    <>
      <ExaminationCard {...request.applicant_name} />
      <ExaminationCard {...request.applicant_idno} />
      <ExaminationCard {...request.juridic_form} type="nomenclature" />
      <ExaminationCard {...request.applicant_created} />
      <ExaminationCard {...request.applicant_address} />
      <ExaminationCard {...request.activity_address} />
      <ExaminationCard {...request.postal_address} />
      <ExaminationCard {...request.activity_sector} type="nomenclature-list" />
      <ExaminationCard {...request.activity_states} type="nomenclature-list" />
      <ExaminationCard {...request.crossing_customs} type="nomenclature-list" />
      <ExaminationCard {...request.facility} />
      <ExaminationCard {...request.management_customs} />
      <ExaminationCard {...request.accounting_office} />
      <ExaminationCard {...request.contact_name} />
      <ExaminationCard {...request.contact_phone} />
      <ExaminationCard {...request.contact_email} />
      <ExaminationCard {...request.contact_fax} />
      <ExaminationCard {...request.signer_name} />
      <ExaminationCard {...request.signer_function} />
      <ExaminationCard {...request.license_attachments} type="attachments" />
      <ExaminationCard attachmentList />
    </>
  );
};
