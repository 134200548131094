import React from "react";
import { useUpdateEffect } from "react-use";
import { useQuery } from "react-query";

import { QueryTable, TableTitle } from "@aeo/core/components";
import { ViewModal } from "features/tasks/components";
import models from "@aeo/core/models";
import {
  useOrderingQueryParam,
  useModalState,
  useQueryPagination,
} from "@aeo/core/hooks";
import { completeColumns, orderingOptions } from "features/tasks/config";

import api, { querykeys } from "api";
import { useTasksFilterParams } from "../hooks";
import { TasksFilters } from ".";

export const CompleteTasks = () => {
  const [search, setSearch] = React.useState("");

  const pagination = useQueryPagination();
  const viewModal = useModalState<models.Task>();

  const [ordering, setOrdering] = useOrderingQueryParam();
  const [filterParams, setFilterParams] = useTasksFilterParams();

  const queryParams = {
    ...pagination.queryParams,
    done: true,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.tasks.many(queryParams), () =>
    api.tasks.get(queryParams),
  );

  const tableColumns = React.useMemo(
    () =>
      completeColumns({
        onView: viewModal.openWith,
      }),
    [viewModal.openWith],
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <>
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          onClick: () => {
            viewModal.openWith(data);
          },
        })}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <TasksFilters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              />
            }
            onSortChange={setOrdering}
            sortOptions={orderingOptions}
          />
        )}
        query={query}
        columns={tableColumns}
        scroll={{ x: 1440 }}
      />
      <ViewModal
        open={viewModal.isOpen}
        onClose={viewModal.close}
        title="Vezi detalii"
        size="regular"
        task={viewModal.data}
        viewMode
      />
    </>
  );
};
