import { KeyValueList } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { prefFieldName } from "@aeo/core/utils";

import { ExaminationCard } from ".";
import { KeyValueRow } from "..";

export const Advisory = () => {
  const person = { ...fields.person, ...fields.personAdvisory };
  return (
    <>
      <ExaminationCard {...person.has_advisory} type="boolean" />
      <ExaminationCard
        {...person.advisers}
        title={
          <KeyValueList
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
                <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
                <KeyValueRow {...prefFieldName(person.email, prefix)} />
                <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                <KeyValueRow {...prefFieldName(person.address, prefix)} />
                <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
                <KeyValueRow {...prefFieldName(person.birthday, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.attachments, prefix)}
                  type="attachments"
                />
              </>
            )}
            name={person.advisers.name}
            title={messages.SAQTabs.advisory.subTitle}
            Wrapper={({ children }) => (
              <>
                <div className="divider mb-10 mt-10" />
                {children}
              </>
            )}
          />
        }
      />
    </>
  );
};
