import React from "react";
import { Button } from "ebs-design";

import { Table, TableTitle } from "@aeo/core/components";
import models from "@aeo/core/models";

import { ComplaintModal } from "features/agents/components/ComplaintModal";
import { agentSvComplaintsColumns } from "features/agents/columns";
import { useModalState, useRole } from "@aeo/core/hooks";

interface Props {
  data?: models.Agent;
}
export const SvComplaints = ({ data }: Props) => {
  const modal = useModalState();
  const role = useRole(data?.manager?.id);

  return (
    <>
      <Table
        className="mcs-aplication-table"
        title={() => (
          <TableTitle
            title={<h3>Sesizări - SV</h3>}
            extra={
              role.isManager && (
                <Button onClick={modal.open}>Adaugă sesizare</Button>
              )
            }
          />
        )}
        data={data?.laments}
        columns={agentSvComplaintsColumns()}
        variants={["bordered"]}
      />
      <ComplaintModal open={modal.isOpen} onClose={modal.close} />
    </>
  );
};
