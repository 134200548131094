import { useQuery } from "react-query";
import { Form, Select } from "ebs-design";
import { SelectMode } from "ebs-design/dist/components/molecules/Select/interfaces";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { isPlainObject } from "@aeo/core/utils";
import { WhiteSpace, Flex } from "@aeo/core/components";

import api, { querykeys } from "api";
import cn from "classnames";

interface UsersSelectProps extends FormFieldProps {
  entityName?: string;
  placeholder?: string;
  mode?: SelectMode;
  size?: string;
}

export const UsersSelect = ({
  size = "large",
  placeholder,
  mode,
  ...props
}: UsersSelectProps) => {
  const queryParams = {
    page_size: 500,
    ordering: "first_name",
  };
  const { data, isLoading } = useQuery(querykeys.users.many(queryParams), () =>
    api.users.get(queryParams),
  );

  return (
    <Form.Field {...props}>
      {(_control) => {
        const control = controlSelect(_control);

        return (
          <Select
            loading={isLoading}
            placeholder={placeholder}
            size={size}
            mode={mode}
            {...control}
          >
            <Select.Options emptyLabel="Nu a fost găsit">
              {data?.results?.map((item) => (
                <Select.Options.Item key={item.id} value={item.id || 0}>
                  <Flex align="center">
                    <span
                      className={cn("status-circle", {
                        "status-color-success": !item?.is_blocked,
                        "status-color-danger": item?.is_blocked,
                      })}
                    />
                    {item?.name}
                  </Flex>
                </Select.Options.Item>
              ))}
            </Select.Options>

            <WhiteSpace v="0.5rem" />
          </Select>
        );
      }}
    </Form.Field>
  );
};

// Handle select values by id
const controlSelect = (control: any) => {
  if (isPlainObject(control.value) && control.value.id) {
    control.selected = {
      value: control.value.id,
      text: control.value.name,
    };
    control.value = control.value.id;
  } else if (Array.isArray(control.value)) {
    // Handle array values
    control.selected = control.value.map((value: any) => ({
      value: value.id,
      text: value.name,
    }));

    control.value = control.value.map((v: any) =>
      isPlainObject(v) ? v.id : v,
    );
  }

  return { ...control };
};
