import { useRouteMatch, Route } from "react-router-dom";
import { Form, useForm, useNotify } from "ebs-design";
import { useMutation } from "react-query";

import { AttachmentsFormField, Flex, WhiteSpace } from "@aeo/core/components";
import { useRole } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";

import api from "api";
import { useApplication } from "../../hooks";
import { Criteria, Subcriteria } from "../Criteria";
// import { applicationFlowTabs } from "features/applications/config";
import { ControlOrderAudit, CompanyDetails } from "..";
import { SubcriterionExamination, ControlCard } from ".";

export const Audit = () => {
  const [form] = useForm();
  const routeMatch = useRouteMatch();
  const application = useApplication();
  const notify = useNotify();
  const { isManager } = useRole(application?.organization?.manager?.id);

  const updateAudit = useMutation(
    (v: { attachments?: models.Attachment[] }) =>
      api.audit.patch(application?.id!, v),
    { onError: (error) => notifyErrors(notify, error) },
  );

  return (
    <Flex justify="flex-start" align="flex-start" wrap="wrap">
      <div className="details-by-type">
        <Route path={routeMatch.path} exact>
          {(application?.order || isManager) && (
            <>
              <ControlOrderAudit state="audit" />
              <WhiteSpace v="1rem" />
            </>
          )}

          <Criteria
            type="audit"
            disabled={!application.order?.can_edit_audit}
          />
          <WhiteSpace v="1rem" />
          <CompanyDetails>
            <h3 className="uppercase">Documente atașate</h3>
            <WhiteSpace v="1rem" />
            <Form
              form={form}
              initialValues={{
                attachments: application?.report?.attachments,
              }}
              onValuesChange={(attachments) => updateAudit.mutate(attachments)}
            >
              <AttachmentsFormField name="attachments">
                Atașează documente
              </AttachmentsFormField>
            </Form>
          </CompanyDetails>
        </Route>
        <Route path={`${routeMatch.path}/:criterion/:subcriterion`} exact>
          <SubcriterionExamination />
        </Route>
      </div>
      <Route path={`${routeMatch.path}/:criterion`} exact>
        <Subcriteria backLink={routeMatch.url} location="audit" />
      </Route>

      <Route path={routeMatch.path} exact>
        <div className="resume">{isManager && <ControlCard />}</div>
      </Route>
    </Flex>
  );
};
