import {
  useQueryParams,
  NumericArrayParam,
  ArrayParam,
  StringParam,
} from "use-query-params";

export type UseApplicationsFilterParamsResult = ReturnType<
  typeof useApplicationsFilterParams
>;

export const useApplicationsFilterParams = () =>
  useQueryParams({
    type: ArrayParam,
    state: ArrayParam,
    manager_id: NumericArrayParam,
    created_at_after: StringParam,
    created_at_before: StringParam,
    organization_id: NumericArrayParam,
  });
