import React from "react";

import { List, Button, Form } from "ebs-design";
import { ListProps } from "rc-field-form/es/List";
import { NamePath } from "rc-field-form/es/interface";

import { Flex, InputFormField, Icon } from "@aeo/core/components";
import model from "@aeo/core/models";

import { FormControl } from "../types";
import { capitalized, makeNamePath } from "../utils";
import { AttachmentsList } from "./AttachmentsList";

export interface InputFormListProps extends Omit<ListProps, "children"> {
  textarea?: boolean;
  required?: boolean;
  hideLabel?: boolean;
  inputName?: NamePath;
  withResponse?: boolean;
  /** should be lowercase */
  title: string;
}

export const InputFormList = ({
  title,
  textarea,
  required,
  inputName,
  withResponse,
  hideLabel = true,
  ...props
}: InputFormListProps) => {
  return (
    <List
      initialValue={[""]}
      {...props}
      children={(fields, { add, remove }) => {
        if (required && !fields.length) add();
        return (
          <>
            {fields.map(({ key, ...field }, i) => (
              <React.Fragment key={key}>
                <div className="pt-10 p-20">
                  <Flex align="center" justify="space-between">
                    {capitalized(title)} {i + 1}
                    <Button
                      icon={() => <Icon type="close" />}
                      onClick={() => remove(i)}
                      size="small"
                    />
                  </Flex>
                  <InputFormField
                    hideLabel={hideLabel}
                    required
                    className="m-0 mt-5"
                    textarea={textarea}
                    {...field}
                    name={
                      inputName
                        ? makeNamePath(field.name, inputName)
                        : field.name
                    }
                  />
                  {withResponse && (
                    <Form.Field name={makeNamePath(field.name)}>
                      {(control) => {
                        const { value } =
                          control as FormControl<model.Recommendation>;
                        if (!value?.response && !value?.attachment) return;

                        return (
                          <Flex direction="column" align="flex-start">
                            <p>Răspuns: </p>
                            {value.response && (
                              <p className="mt-10">{value.response}</p>
                            )}
                            {value.attachment && (
                              <AttachmentsList value={value.attachment} />
                            )}
                          </Flex>
                        );
                      }}
                    </Form.Field>
                  )}
                </div>
                <div className="divider" />
              </React.Fragment>
            ))}
            <div className="p-20">
              <Button
                block
                prefix={<Icon type="plus" />}
                onClick={() => {
                  add("");
                }}
              >
                Adaugă {title}
              </Button>
            </div>
          </>
        );
      }}
    />
  );
};
