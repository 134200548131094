import { AxiosResponse } from "axios";

import { GenericObject } from "@aeo/core/types";
import { stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";
import { apiAxios } from "@aeo/core/api/axios";

export const users = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/cs/management/users`, query);
    const { data } = await apiAxios.get<models.WithResults<models.User>>(url);

    return data;
  },

  getById: async (id: string) => {
    const { data } = await apiAxios.get(`/cs/management/users/${id}`);
    return data;
  },

  createUser: async (credentials: models.User) => {
    await apiAxios.post<models.User, AxiosResponse<models.TokensResponse>>(
      `/cs/management/users/create`,
      credentials,
    );
  },

  editUser: async ({ id, ...data }: models.User) => {
    await apiAxios.patch<models.User, AxiosResponse<models.TokensResponse>>(
      `/cs/management/users/${id}`,
      data,
    );
  },

  deleteUser: async (id: number) => {
    return await apiAxios.delete<void>(`/cs/management/users/${id}`);
  },

  blockUser: async (id?: number) => {
    const { data } = await apiAxios.post<
      { id: number },
      AxiosResponse<models.ResponseWithSuccess>
    >(`/cs/management/users/${id}/block`);
    return data;
  },

  unblockUser: async (id?: number) => {
    const { data } = await apiAxios.post<
      { id: number },
      AxiosResponse<models.ResponseWithSuccess>
    >(`/cs/management/users/${id}/unblock`);
    return data;
  },

  resendConfirmation: async (username: string) => {
    const { data } = await apiAxios.post<
      { username: string },
      AxiosResponse<models.ResponseWithSuccess>
    >("/cs/management/users/resend-confirmation", {
      username,
    });
    return data;
  },
};
