import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Card, useNotify } from "ebs-design";

import { Flex, Icon, ViewPdfModal, WhiteSpace } from "@aeo/core/components";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";
import { ApplicationContext } from "@aeo/core/contexts";
import { useModalState } from "@aeo/core/hooks";

import api, { querykeys } from "api";

export const ControlCard = () => {
  const application = React.useContext(ApplicationContext);
  const notify = useNotify();
  const queryClient = useQueryClient();
  const modalPdf = useModalState<models.Attachment>();

  const verificationListDocument =
    application?.verification_list?.signed_document;
  const controlPlanDocument = application?.plan?.signed_document;

  const plan = useMutation(
    (value: models.ApplicationId) =>
      api.audit.controlPlan(application?.id || 0, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.one(application?.id!),
        );
        notify.success({
          title: "Planul de control a fost creat cu succes!",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const list = useMutation(
    (value: models.ApplicationId) =>
      api.audit.verificationList(application?.id || 0, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.one(application?.id!),
        );
        notify.success({
          title: "Lista de verificare a fost creată cu succes!",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <Card className="control-card">
        <Card.Body>
          <Flex justify="space-between">
            <Button
              prefix={
                verificationListDocument ? (
                  <Icon type="open-eye" />
                ) : (
                  <Icon type="verification-list" />
                )
              }
              onClick={() =>
                verificationListDocument
                  ? modalPdf.openWith(verificationListDocument)
                  : list.mutate({ application: application?.id })
              }
            >
              Listă de verificare
            </Button>
            <WhiteSpace v="1rem" h="1rem" />
            <Button
              prefix={
                controlPlanDocument ? (
                  <Icon type="open-eye" />
                ) : (
                  <Icon type="action-plan" />
                )
              }
              onClick={() =>
                controlPlanDocument
                  ? modalPdf.openWith(controlPlanDocument)
                  : plan.mutate({ application: application?.id })
              }
            >
              Plan de control
            </Button>
          </Flex>
        </Card.Body>
      </Card>
      {modalPdf.isOpen && (
        <ViewPdfModal
          file={modalPdf?.data?.file_upload}
          fileTitle={modalPdf?.data?.title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title={modalPdf?.data?.title}
          size="large"
        />
      )}
    </>
  );
};
