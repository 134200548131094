import { AxiosResponse } from "axios";
import models from "@aeo/core/models";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@aeo/core/app-constants";
import { authAxios, apiAxios } from "@aeo/core/api/axios";
import coreApi from "@aeo/core/api";
import api from ".";

export const auth = {
  getUser: async () => {
    try {
      // Verify if the user token is valid
      await coreApi.authentication.verify();

      const user = await api.profile.get();
      return user;
    } catch (e) {
      throw e;
    }
  },

  logout: async () => {
    await authAxios.get("/cs/auth/logout", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
      },
    });

    // Remove tokens from local storage
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  // Recovery password
  recoveryPassword: async (body: models.RecoveryPassword) => {
    const { data } = await apiAxios.post<
      models.RecoveryPassword,
      AxiosResponse<models.ResponseWithSuccess>
    >(`/cs/auth/restore-request`, body);

    return data;
  },

  // Reset password
  resetPassword: async (body: models.ResetPassword) => {
    const { data } = await apiAxios.post<
      models.ResetPassword,
      AxiosResponse<models.ResponseWithSuccess>
    >(`/cs/auth/restore-confirm`, body);

    return data;
  },

  /** Need this blank method to avoid some typescript errors in AuthProvider */
  register: async () => {},
};
