import { KeyValueList, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";
import messages from "@aeo/core/messages";

import { KeyValueRow, AttachmentsList } from "..";
import { ExaminationCard } from ".";

export const Shareholders = () => {
  const person = fields.person;

  return (
    <>
      <ExaminationCard
        name={[person.juridic.name]}
        title={
          <KeyValueList
            title={messages.titles.juridicPerson}
            name={person.juridic.name}
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow {...prefFieldName(person.name, prefix)} />
                <KeyValueRow {...prefFieldName(person.idno, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.created, prefix)}
                  type="date"
                />
                <KeyValueRow {...prefFieldName(person.address, prefix)} />
                <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.percentage_shares, prefix)}
                />

                <WhiteSpace v="1rem" />
                <AttachmentsList name={makeNamePath(prefix, "attachments")} />
              </>
            )}
          />
        }
      />

      <ExaminationCard
        name={[person.physic.name]}
        title={
          <KeyValueList
            title={messages.titles.physicPerson}
            name={person.physic.name}
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
                <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.birthday, prefix)}
                  type="date"
                />
                <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
                <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                <KeyValueRow {...prefFieldName(person.email, prefix)} />
                <KeyValueRow {...prefFieldName(person.address, prefix)} />
                <KeyValueRow
                  {...prefFieldName(person.percentage_shares, prefix)}
                />

                <WhiteSpace v="1rem" />
                <AttachmentsList name={makeNamePath(prefix, "attachments")} />
              </>
            )}
          />
        }
      />
    </>
  );
};
