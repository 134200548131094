import { BadgeTabLabel } from "@aeo/core/components";
import { UserRoles } from "@aeo/core/utils";
import {
  NomenclatureSettings,
  ProfileSettings,
  PeriodSettings,
  DocumentsSettings,
} from "../components";

export const settingTabs = [
  {
    label: <BadgeTabLabel title="Profil" />,
    route: "profile",
    content: <ProfileSettings />,
    allowedTo: [UserRoles.admin, UserRoles.user, UserRoles.superuser],
  },
  {
    label: <BadgeTabLabel title="Nomenclaturi" />,
    route: "nomenclature",
    content: <NomenclatureSettings />,
    allowedTo: [UserRoles.superuser],
  },
  {
    label: <BadgeTabLabel title="Perioade" />,
    route: "periods",
    content: <PeriodSettings />,
    allowedTo: [UserRoles.superuser],
  },
  {
    label: <BadgeTabLabel title="Documente" />,
    route: "documents",
    content: <DocumentsSettings />,
    allowedTo: [UserRoles.superuser],
  },
];
