import React from "react";
import classNames from "classnames";

import { Card, Form, Alert } from "ebs-design";

import {
  BooleanRadioFormField,
  Flex,
  Icon,
  InputFormField,
  WhiteSpace,
} from "@aeo/core/components";
import { makeNamePath } from "@aeo/core/utils";
import { FormControl, KeyValueProps } from "@aeo/core/types";
import models from "@aeo/core/models";

import { AttachmentsList, KeyValueRow, RiskList } from "..";
import { useFieldValue, useNAField } from "@aeo/core/hooks";

export interface ExaminationCardProps extends KeyValueProps {
  title?: React.ReactNode;
  hideRisks?: boolean;
  attachmentList?: boolean;
  ident?: number;
  collapsible?: boolean;
}

export const ExaminationCard = ({
  title,
  name,
  hideRisks,
  attachmentList,
  ident,
  ...props
}: ExaminationCardProps) => {
  if (attachmentList) name = makeNamePath(name, "attachments");

  const na = useNAField({ name, ...props });
  const value = useFieldValue(name);

  const confirmedIsOptional =
    na || value === null || (Array.isArray(value) && !value.length);

  return (
    <div
      style={{
        marginLeft: typeof ident === "number" ? `${ident * 2}rem` : ident,
        marginBottom: "1rem",
      }}
    >
      <Card size="small" className="bordered">
        <Card.Header>
          <Flex>
            <Form.Field className="m-0" name={makeNamePath(name, "confirmed")}>
              {(control) => {
                const { value: confirm } = control as FormControl<boolean>;

                return (
                  <div
                    className={classNames("mt-4", "bordered-icon", {
                      success: confirm === true,
                      error: confirm === false,
                    })}
                  >
                    <Icon
                      type={
                        confirm === true
                          ? "circle-check"
                          : confirm === false
                          ? "circle-warning"
                          : "circle-question"
                      }
                    />
                  </div>
                );
              }}
            </Form.Field>
            <WhiteSpace h="1rem" />
            <div className="flex-1">
              {title ??
                (attachmentList ? (
                  <AttachmentsList />
                ) : (
                  <KeyValueRow {...props} name={name} />
                ))}
            </div>
            <WhiteSpace h="1rem" />
          </Flex>
        </Card.Header>
        <Card.Body>
          <BooleanRadioFormField
            required={!confirmedIsOptional}
            label="Conform?"
            name={makeNamePath(name, "confirmed")}
            nullable={confirmedIsOptional}
          />
          <Form.Field
            className="m-0"
            name={makeNamePath(name, "is_optional")}
          />
          {/* Send is_optional: true when this field has 'Nu se aplica' and confirmed is not selected */}
          <Form.Field
            className="m-0"
            dependencies={[makeNamePath(name, "confirmed")]}
          >
            {(_, __, form) => {
              const confirmed: boolean | undefined = form.getFieldValue(
                makeNamePath(name, "confirmed"),
              );
              if (confirmedIsOptional && confirmed === null) {
                form.setFields([
                  {
                    name: makeNamePath(name, "is_optional"),
                    value: true,
                  },
                ]);
              }
              return <></>;
            }}
          </Form.Field>
          <InputFormField
            textarea
            label="Mențiuni"
            name={makeNamePath(name, "note", "description")}
            normalize={(value) =>
              value && !!String(value).trim() ? value : undefined
            }
          />
          <Form.Field name={makeNamePath(name, "note")} style={{ margin: 0 }}>
            {(control) => {
              const { value: note } =
                control as FormControl<models.NoteResponse>;
              if (!note?.attachment && !note?.response) return;

              return (
                <div style={{ marginBottom: "1rem" }}>
                  <div className="divider" />
                  <h4 className="fw-500 my-10">Răspuns mențiuni:</h4>
                  <Alert type="info" icon={false}>
                    {note?.response}
                  </Alert>
                  {note?.attachment && (
                    <AttachmentsList value={note?.attachment} />
                  )}
                  <div className="divider my-20" />
                </div>
              );
            }}
          </Form.Field>

          {!hideRisks && (
            <RiskList name={makeNamePath(name, "risks")} state="pre_audit" />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
