import React from "react";

import { Space, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";

import { notifyErrors } from "@aeo/core/utils";
import {
  EditorModalProps,
  Icon,
  LoadingButton,
  ViewPdfModal,
} from "@aeo/core/components";
import { useModalState } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { ApplicationExaminationStateType } from "@aeo/core/types";
import { notifications } from "@aeo/core/messages/notifications";

import { TemplateModal } from "components";
import api, { querykeys } from "api";

import { useApplication } from "../../hooks/useApplication";

export const ConclusionModal = ({
  type,
}: {
  type: ApplicationExaminationStateType;
}) => {
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const queryClient = useQueryClient();
  const { id, report, re_report } = useApplication();
  const modalPdf = useModalState<models.Attachment>();
  const editorModalState = useModalState<EditorModalProps["data"]>();

  const notify = useNotify();

  const reportMutation = useMutation(
    (values: models.ContentEntity) =>
      type === "audit"
        ? api.audit.report(id!, values)
        : api.reAudit.report(id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.applications.one(id!));
        editorModalState.close();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const auditAction = useMutation(
    (values: any) =>
      api.reAudit.auditAction(
        id!,
        type,
        editorModalState.data?.action!,
        values,
      ),
    {
      onSuccess: () => {
        notify.success({ title: notifications.action });
        queryClient.invalidateQueries(querykeys.applications.one(id!));
        editorModalState.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const auditActionPreview = useMutation(
    (values: any) =>
      api.reAudit.auditActionPreview(
        id!,
        type,
        editorModalState.data?.action!,
        values,
      ),
    {
      onSuccess: (value) => setPdfFile(value),
      onError: notifyErrors.bind(null, notify),
    },
  );

  const documentsBtns = React.useMemo(
    () => [
      {
        name: report?.project_document
          ? "Proiect Raport Final"
          : "Creare Proiect Raport Final",
        disabled:
          !report?.can_create_project_report && !report?.project_document,
        prefix: !!report?.project_document ? <Icon type="open-eye" /> : null,
        onClick: () =>
          !!report?.project_document
            ? modalPdf.openWith(report?.project_document)
            : editorModalState.openWith({
                action: "report-project",
                name: "Creare Proiect Raport Final",
                headerTemplateId: "header_project_report",
                templateId: "content_project_report",
              }),
        state: "audit",
      },
      {
        name: report?.signed_document ? "Raport Final" : "Creare Raport Final",
        prefix: report?.signed_document ? <Icon type="open-eye" /> : null,
        loading: reportMutation.isLoading,
        disabled: !report?.can_create_report && !report?.signed_document,
        onClick: () =>
          report?.signed_document
            ? modalPdf.openWith(report?.signed_document)
            : editorModalState.openWith({
                action: "report",
                name: "Creare Raport Final",
                headerTemplateId: "header_report",
                templateId: "content_report",
              }),
        state: "audit",
      },
      {
        name: !!re_report?.monitoring_document
          ? "Raport de Monitorizare"
          : "Creare Raport de Monitorizare",
        disabled:
          !re_report?.can_create_monitoring_document &&
          !re_report?.monitoring_document,

        prefix: !!re_report?.monitoring_document ? (
          <Icon type="open-eye" />
        ) : null,
        onClick: () =>
          !!re_report?.monitoring_document
            ? modalPdf.openWith(re_report?.monitoring_document)
            : editorModalState.openWith({
                action: "report-monitoring",
                name: "Creare Raport de Monitorizare",
              }),
        state: "re-audit",
      },
      {
        name: !!re_report?.project_document
          ? "Proiect Raport Final"
          : "Creare Proiect Raport Final",
        disabled:
          !re_report?.can_create_project_re_report &&
          !re_report?.project_document,
        prefix: !!re_report?.project_document ? <Icon type="open-eye" /> : null,
        onClick: () =>
          !!re_report?.project_document
            ? modalPdf.openWith(re_report?.project_document)
            : editorModalState.openWith({
                action: "report-project",
                name: "Creare Proiect Raport Final",
                headerTemplateId: "header_project_report",
                templateId: "content_project_report",
              }),
        state: "re-audit",
      },
      {
        name: !!re_report?.signed_document
          ? "Raport Final"
          : "Creare Raport Final",
        prefix: !!re_report?.signed_document ? <Icon type="open-eye" /> : null,
        isVisible: true,
        disabled:
          !re_report?.can_create_re_report && !re_report?.signed_document,
        onClick: () =>
          !!re_report?.signed_document
            ? modalPdf.openWith(re_report?.signed_document)
            : editorModalState.openWith({
                action: "report",
                name: "Creare Raport Final",
                headerTemplateId: "header_report",
                templateId: "content_report",
              }),
        state: "re-audit",
      },
    ],
    [report, re_report, modalPdf, editorModalState, reportMutation.isLoading],
  );

  return (
    <>
      <Space>
        {documentsBtns
          .filter((v) => v.state === type)
          .map((btn) => (
            <LoadingButton
              size="medium"
              type="primary"
              prefix={btn?.prefix}
              onClick={btn?.onClick}
              disabled={btn?.disabled}
              loading={btn?.loading}
            >
              {btn?.name}
            </LoadingButton>
          ))}
      </Space>

      {modalPdf.isOpen && (
        <ViewPdfModal
          file={modalPdf?.data?.file_upload}
          fileTitle={modalPdf?.data?.title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title={modalPdf?.data?.title}
          size="large"
        />
      )}
      {editorModalState.isOpen && (
        <TemplateModal
          open={editorModalState.isOpen}
          onClose={editorModalState.close}
          title={editorModalState?.data?.name}
          mutation={auditAction}
          previewMutation={auditActionPreview}
          pdfFile={pdfFile}
          headerTemplateId={editorModalState.data?.headerTemplateId}
          templateId={editorModalState.data?.templateId}
          isReplace
          headerContent
        />
      )}
    </>
  );
};
