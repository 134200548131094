import { apiAxios } from "@aeo/core/api/axios";
import models from "@aeo/core/models";

export const dashboard = {
  statistics: {
    get: async () => {
      const { data } = await apiAxios.get<models.Statistics>(
        "/cs/dashboard/statistics",
      );
      return data;
    },
  },
};
