import { apiAxios } from "@aeo/core/api/exports";
import { LogoFormData } from "features/applications/components/AuthorizationLogo";

export const logo = {
  changeLogoStatus: async (id: number, values: LogoFormData) => {
    const { data } = await apiAxios.patch<any>(
      `/cs/logo/${id}/${values.state}`,
      values.body,
    );
    return data;
  },
};
