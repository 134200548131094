import React from "react";
import { Card } from "ebs-design";
import { useQuery } from "react-query";

import models from "@aeo/core/models";
import { useParam } from "@aeo/core/hooks";
import { NumberParam } from "use-query-params";
import {
  arrIncludes,
  titleWithSAQ,
  transformDataToProgress,
} from "@aeo/core/utils";
import api, { querykeys } from "api";

import { WithExaminable } from "./types";
import { DetailsCard } from ".";

import {
  EconomicAgent,
  Contact,
  Customer,
  CommercialActivity,
  OrganizationStructure,
  Turnover,
  CustomsStatistic,
  ComplianceLegislation,
  LogisticOne,
  LogisticTwo,
  LogisticThree,
  FinancialSolvency,
  PracticalStandard,
  SecurityOne,
  SecurityTwo,
  SecurityThree,
  SecurityFour,
  PersonList,
  ShareholdersList,
  RequestDetails,
  Advisory,
} from "./Summary";
import { ApplicationContext } from "@aeo/core/contexts";

export const DetailsByType = ({ examinable }: WithExaminable) => {
  const idParam = useParam("id", NumberParam.decode) || 0;

  const application = React.useContext(ApplicationContext);

  const { data } = useQuery(querykeys.preaudit(idParam), () =>
    api.preaudit.getById(idParam),
  );

  const progress: any = transformDataToProgress(data);

  return application?.type || "" in models.AEOType ? (
    <>
      <DetailsCard
        {...{ examinable }}
        title="Cererea"
        content={<RequestDetails />}
        path="request"
        progress={progress?.request}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle={titleWithSAQ("economicAgent")}
        content={<EconomicAgent />}
        path="saq/economic_agent"
        progress={progress?.saq?.economic_agent}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle="SAQ: 1.1.2. a)"
        content={<ShareholdersList />}
        path="saq/shareholders"
        progress={progress?.saq?.shareholders}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle="SAQ: 1.1.2. b)"
        content={<PersonList />}
        path="saq/directors"
        progress={progress?.saq?.directors}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle="SAQ: 1.1.2. c)"
        content={<Advisory />}
        path="saq/advisory"
        progress={progress?.saq?.advisory}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle="SAQ: 1.1.2. d), e)"
        content={<Contact />}
        path="saq/contact"
        progress={progress?.saq?.contact}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle={titleWithSAQ("customer")}
        content={<Customer />}
        path="saq/customer"
        progress={progress?.saq?.customer}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle={titleWithSAQ("commercial")}
        content={<CommercialActivity />}
        path="saq/commercial"
        progress={progress?.saq?.commercial}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("companyGeneralInfo")}
        subtitle={titleWithSAQ("structure")}
        content={<OrganizationStructure />}
        path="saq/structure"
        progress={progress?.saq?.structure}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("turnover")}
        content={<Turnover />}
        path="saq/turnover"
        progress={progress?.saq?.turnover}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("statistic")}
        content={<CustomsStatistic />}
        path="saq/statistic"
        progress={progress?.saq?.statistic}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("legislation")}
        subtitle={titleWithSAQ("legislationOne")}
        content={<ComplianceLegislation />}
        path="saq/legislation"
        progress={progress?.saq?.legislation}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("logistic")}
        subtitle={titleWithSAQ("logisticOne")}
        content={<LogisticOne />}
        path="saq/logistic_one"
        progress={progress?.saq?.logistic_one}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("logistic")}
        subtitle={titleWithSAQ("logisticTwo")}
        content={<LogisticTwo />}
        path="saq/logistic_two"
        progress={progress?.saq?.logistic_two}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("logistic")}
        subtitle={titleWithSAQ("logisticThree")}
        content={<LogisticThree />}
        path="saq/logistic_three"
        progress={progress?.saq?.logistic_three}
      />
      <DetailsCard
        {...{ examinable }}
        title={titleWithSAQ("solvency")}
        content={<FinancialSolvency />}
        path="saq/solvency"
        progress={progress?.saq?.solvency}
      />
      {arrIncludes(
        [models.AEOType.AEOC, models.AEOType.AEOF],
        application?.type,
      ) && (
        <DetailsCard
          {...{ examinable }}
          title={titleWithSAQ("standard")}
          subtitle={titleWithSAQ("standardOne")}
          content={<PracticalStandard />}
          path="saq/standard"
          progress={progress?.saq?.standard}
        />
      )}
      {arrIncludes(
        [models.AEOType.AEOS, models.AEOType.AEOF],
        application?.type,
      ) && (
        <>
          <DetailsCard
            {...{ examinable }}
            title={titleWithSAQ("security")}
            subtitle={titleWithSAQ("securityOne")}
            content={<SecurityOne />}
            path="saq/security_one"
            progress={progress?.saq?.security_one}
          />
          <DetailsCard
            {...{ examinable }}
            title={titleWithSAQ("security")}
            subtitle={titleWithSAQ("securityTwo")}
            content={<SecurityTwo />}
            path="saq/security_two"
            progress={progress?.saq?.security_two}
          />
          <DetailsCard
            {...{ examinable }}
            title={titleWithSAQ("security")}
            subtitle={titleWithSAQ("securityThree")}
            content={<SecurityThree />}
            path="saq/security_three"
            progress={progress?.saq?.security_three}
          />
          <DetailsCard
            {...{ examinable }}
            title={titleWithSAQ("security")}
            subtitle={titleWithSAQ("securityFour")}
            content={<SecurityFour />}
            path="saq/security_four"
            progress={progress?.saq?.security_four}
          />
        </>
      )}
    </>
  ) : (
    <Card>
      <Card.Body>
        <h4 className="color-danger">Cererea are un tip invalid</h4>
      </Card.Body>
    </Card>
  );
};
