import { AxiosResponse } from "axios";
import { apiAxios } from "@aeo/core/api/axios";
import { jsonFromBufferTransformer, stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types";
import models from "@aeo/core/models";

export const reAudit = {
  patch: async (id: number, values?: { attachments?: models.Attachment[] }) => {
    const { data } = await apiAxios.patch(`/cs/re-audit/${id}`, values);
    return data;
  },
  patchControlOrder: async (id: number, values?: any) => {
    const { data } = await apiAxios.patch(
      `/cs/re-audit/control-order/${id}`,
      values,
    );
    return data;
  },
  postControlOrder: async (id: number, values?: any) => {
    const { data } = await apiAxios.post(
      `/cs/re-audit/${id}/control-order`,
      values,
    );
    return data;
  },
  getByCriterion: async (applicationId: number, query?: GenericObject) => {
    const url = stringifyUrl(`/cs/re-audit/${applicationId}/criterion`, query);
    const { data } = await apiAxios.get<models.AuditCriterion>(url);
    return data;
  },
  patchCriterion: async (id: number, values: models.AuditCriterion) => {
    const { data } = await apiAxios.patch(
      `/cs/re-audit/${id}/criterion`,
      values,
    );
    return data;
  },
  report: async (id: number, values: models.ContentEntity) => {
    const { data } = await apiAxios.post(`/cs/re-audit/${id}/report`, values);
    return data;
  },
  postReportOrderActive: async (id: number, values?: any) => {
    const { data } = await apiAxios.post(
      `/cs/re-audit/${id}/report-order/active`,
      values,
    );
    return data;
  },
  postReportOrderRevoke: async (id: number, values?: any) => {
    const { data } = await apiAxios.post(
      `/cs/re-audit/${id}/report-order/revoke`,
      values,
    );
    return data;
  },
  patchReportOrderAnnul: async (id: number, values?: any) => {
    const { data } = await apiAxios.patch(
      `/cs/re-audit/${id}/report-order/annul`,
      values,
    );
    return data;
  },
  plan: async (id: number) => {
    const { data } = await apiAxios.post(`/cs/re-audit/${id}/plan`);
    return data;
  },
  getCriterionDashboard: async (id?: number, query?: GenericObject) => {
    const url = stringifyUrl(`/cs/re-audit/${id}/criterion-dashboard`, query);
    const { data } = await apiAxios.get<models.CriterionDashboard[]>(url);
    return data;
  },
  updateCriterionRisk: async (id: number, values?: Partial<models.Risk>) => {
    const { data } = await apiAxios.patch(`/cs/re-audit/${id}/risk`, values);
    return data;
  },
  auditAction: async (
    id: number,
    type: string,
    action: string,
    body?: models.ContentEntity,
  ) => {
    const { data } = await apiAxios.post(`/cs/${type}/${id}/${action}`, body);
    return data;
  },
  auditActionPreview: async (
    id: number,
    type: string,
    action: string,
    body?: models.ContentEntityWithHeader,
  ) => {
    const { data } = await apiAxios.post<
      models.ContentEntityWithHeader,
      AxiosResponse<ArrayBuffer>
    >(`/cs/${type}/${id}/${action}/preview`, body, {
      responseType: "arraybuffer",
      transformResponse: jsonFromBufferTransformer,
    });
    return data;
  },
};
