import React from "react";
import { Checkbox, Loader } from "ebs-design";
import { useInfiniteQuery, QueryKey } from "react-query";

import { GenericObject } from "@aeo/core/types";
import models from "@aeo/core/models";
import { combineQueryPagesData, getNextPageParam } from "@aeo/core/utils";
import { useIntersectionObserver } from "@aeo/core/hooks";

import { FilterCollapse } from ".";
import {
  AlertErrors,
  DebouncedInput,
  Grid,
  InfiniteScrollTrigger,
  WhiteSpace,
} from "..";

export interface SelectQueryListFilterProps<T, Key extends string | number> {
  title: React.ReactNode;
  value?: (Key | null)[] | null | undefined;
  setValue?: React.Dispatch<
    React.SetStateAction<(Key | null)[] | null | undefined>
  >;
  querykey: (queryParams: GenericObject) => QueryKey;
  apiCall: (queryParams: GenericObject) => Promise<models.WithResults<T>>;

  getKey: (record: T) => Key | undefined;
  getValue: (record: T) => React.ReactNode;
  getFilterGroup?: (record: T) => T[] | undefined;
  showSearch?: boolean;
  excludeStates?: string[];
}

export const SelectQueryListFilter = <T, Key extends string | number>({
  title,
  value,
  querykey,
  apiCall,
  getKey,
  getValue,
  setValue,
  getFilterGroup,
  // FIXME: Check if the search input should be by default true
  showSearch = true,
  excludeStates = [],
}: SelectQueryListFilterProps<T, Key>) => {
  const loadMoreRef = React.useRef<HTMLDivElement>(null);
  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);

  const [search, setSearch] = React.useState("");

  const queryParams = { search, page_size: 20 };

  const query = useInfiniteQuery(
    querykey({ ...queryParams }),
    ({ pageParam = 1 }) =>
      apiCall({
        ...queryParams,
        page: pageParam,
      }),
    { getNextPageParam },
  );
  const results = combineQueryPagesData(query.data);
  const data = results.filter(
    (v) => !excludeStates.includes(getValue(v) as string),
  );

  useIntersectionObserver({
    rootRef: loadMoreRootRef,
    targetRef: loadMoreRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
    rootMargin: "150px",
  });

  return (
    <FilterCollapse title={title} count={value?.length}>
      {showSearch && (
        <DebouncedInput
          placeholder="Caută"
          value={search}
          onChange={setSearch}
          size="large"
        />
      )}

      <WhiteSpace v="0.5rem" />
      <AlertErrors error={query.error} />
      {query.isLoading && <Loader.Inline>Se încarcă</Loader.Inline>}

      {!data.length && !query.isError && !query.isLoading && (
        <h5 className="color-gray text-center m-15">Nu s-a găsit nimic</h5>
      )}

      <div className="list-filter-container" ref={loadMoreRootRef}>
        {data.length ? (
          <>
            <Grid gap="0.5rem">
              {data?.map((item) =>
                getFilterGroup?.(item) ? (
                  <>
                    <h4>{getValue(item)}</h4>
                    {getFilterGroup?.(item)?.map((item) => (
                      <Checkbox
                        checked={value?.includes(getKey(item) || null) || false}
                        onChange={(c) =>
                          c
                            ? setValue?.((prev) => [
                                ...(prev || []),
                                getKey(item) || null,
                              ])
                            : setValue?.((prev) =>
                                prev?.filter((p) => p !== getKey(item)),
                              )
                        }
                        key={getKey(item)}
                        text={getValue(item)}
                      />
                    ))}
                  </>
                ) : (
                  <Checkbox
                    checked={value?.includes(getKey(item) || null) || false}
                    onChange={(c) =>
                      c
                        ? setValue?.((prev) => [
                            ...(prev || []),
                            getKey(item) || null,
                          ])
                        : setValue?.((prev) =>
                            prev?.filter((p) => p !== getKey(item)),
                          )
                    }
                    key={getKey(item)}
                    text={getValue(item)}
                  />
                ),
              )}
            </Grid>
            <WhiteSpace v="0.5rem" />
          </>
        ) : null}

        <InfiniteScrollTrigger ref={loadMoreRef} {...{ query, data }} />
      </div>
    </FilterCollapse>
  );
};
