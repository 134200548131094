import React from "react";

import { Button, Form, Loader, Modal, useForm, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import cn from "classnames";
import { AxiosError } from "axios";

import {
  makeBEM,
  notifyErrors,
  setFieldsErrors,
  wrapInArr,
} from "@aeo/core/utils";
import {
  DatePickerFormField,
  Editor,
  Flex,
  Grid,
  InputFormField,
  LoadingButton,
  PDFViewer,
  PreviewTabs,
  PreviewTabsEntity,
  PreviewTabsState,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import coreApi from "@aeo/core/api";
import { querykeys as coreQueryKeys } from "@aeo/core/api";

import { UsersSelect } from "components";
import api, { querykeys } from "api";
import { useTemplate } from "hooks";

export interface ControlOrderModalProps extends ModalProps {
  canCreateOrder?: boolean;
  application?: models.ActiveApplication;
  buttonText?: React.ReactNode;
  state: string;
  templateId?: string;
}

const bem = makeBEM("select-criterion-modal");

export const ControlOrderModal = ({
  canCreateOrder,
  application,
  state,
  templateId = "control_order",
  ...props
}: ControlOrderModalProps) => {
  const [editor, setEditor] = React.useState<string>();

  const [currentTab, setCurrentTab] =
    React.useState<PreviewTabsState>("editor");
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const isReAudit = state === "re-audit" && !!application?.re_order;

  const variablesToReplace: models.VariablesToReplace = {
    company_name: application?.organization?.name,
    company_type: application?.saq?.economic_agent.juridic_form?.name,
    fiscal_code: application?.organization?.idno,
    company_address: application?.saq?.economic_agent.address,
    app_type: application?.type,
    authorization_id: application?.authorization?.id,
    authorization_date: application?.authorization?.created_at,
    audit_report_date: application?.report?.created_at,
    audit_report_number: application?.report?.id,
    order_number: isReAudit
      ? application?.re_order?.id
      : application?.order?.id,
    order_title: isReAudit
      ? application?.re_order?.subject
      : application?.order?.subject,
  };

  const template = useTemplate(variablesToReplace, templateId, true);

  const { mutate, isLoading } = useMutation(
    async (values: models.OrderFormValues) =>
      canCreateOrder
        ? api.preaudit.postOrder(application?.id!, state, values)
        : api.preaudit.patchOrder(
            isReAudit ? application?.re_order?.id! : application?.order?.id!,
            state,
            values,
          ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.one(application?.id!),
        );

        // Reset modal form
        if (props.onClose) props.onClose();
        form.resetFields();
      },
      onError: (error) => {
        const err = error as AxiosError<any>;
        const wrapError = wrapInArr(err.response?.data.detail);

        if (wrapError.length) wrapError.map((v) => notify.error({ title: v }));

        if (error.response?.status === 400)
          setFieldsErrors(error.response?.data, form, notify);
      },
    },
  );

  const previewTemplateMutation = useMutation(
    coreApi.nomenclatures.orderPreview,
    {
      onSuccess: (value) => setPdfFile(value),
      onError: notifyErrors.bind(null, notify),
    },
  );

  const handleForm = (values: models.Order) => {
    mutate({
      ...values,
      team: values?.team?.map((v) =>
        typeof v === "object" && v.id ? v.id : v,
      ),
      content: editor,
    } as models.OrderFormValues);
  };

  const resetState = React.useCallback(() => {
    templateId &&
      queryClient.invalidateQueries(coreQueryKeys.templates.one(templateId!));
    setEditor(undefined);
  }, [queryClient, templateId]);

  React.useEffect(() => {
    form.setFieldsValue(isReAudit ? application?.re_order : application?.order);

    if (isReAudit) {
      setEditor(
        templateId && !application?.re_order?.content
          ? template?.data?.content
          : !!application?.re_order?.content
          ? application?.re_order.content
          : "",
      );
    } else {
      setEditor(
        templateId && !application?.order?.content
          ? template?.data?.content
          : !!application?.order?.content
          ? application?.order.content
          : "",
      );
    }

    // Reset values
    return () => resetState();
  }, [
    template?.data?.content,
    templateId,
    resetState,
    isReAudit,
    application?.order,
    application?.re_order,
    form,
  ]);

  const handleClose = async () => {
    resetState();
    props.onClose?.();
  };

  const previewTabs: PreviewTabsEntity = {
    editor: {
      tabKey: "editor",
      title: "Editare",
      onClick: () => setCurrentTab("editor"),
      content: (
        <>
          <Grid cols="1fr 1fr" gap="1rem">
            <InputFormField name="subject" label="Titlul" />
            <UsersSelect name="team" label="Echipa de audit" mode="multiple" />
          </Grid>
          <Grid cols="1fr 1fr 1fr" gap="1rem">
            <DatePickerFormField
              name="start_date"
              label="Data începerii auditului"
            />
            <DatePickerFormField
              name="order_date"
              label="Data emiterii ordinului"
            />
          </Grid>
          <WhiteSpace v="1rem" />
          <div className={bem("editor-border")}>
            {typeof editor !== "undefined" ? (
              <Editor value={editor} onChange={setEditor} />
            ) : null}
          </div>
        </>
      ),
    },
    preview: {
      tabKey: "preview",
      title: "Vizualizare",
      onClick: () => {
        previewTemplateMutation?.mutate({
          content: editor,
        });
        setCurrentTab("preview");
        setEditor(editor);
      },
      content: (
        <div className="py-20">
          <Loader loading={previewTemplateMutation?.isLoading!}>
            <PDFViewer file={pdfFile} />
          </Loader>
        </div>
      ),
    },
  };

  return (
    <Modal {...props} onClose={handleClose}>
      <Form form={form} onFinish={handleForm}>
        <Modal.Content className={cn("p-0", bem("content"))}>
          <PreviewTabs tabs={previewTabs} currentTab={currentTab} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={props.onClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton loading={isLoading} submit type="primary">
              Salvează
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
