import { Link, useRouteMatch } from "react-router-dom";
import { Button, Card } from "ebs-design";

import { Flex, Icon, WhiteSpace } from "@aeo/core/components";
import { ApplicationPathContext } from "@aeo/core/contexts";
import models from "@aeo/core/models";

import { useApplication } from "../hooks";

export interface DetailsCardProps {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  path: models.ApplicationValidatePath;
  examinable?: boolean;
  progress?: {
    completed: number;
    notCompleted: number;
    total: number;
  };
}

export const DetailsCard = ({
  title,
  subtitle,
  content,
  path,
  examinable,
  progress = {
    completed: 0,
    notCompleted: 0,
    total: 0,
  },
}: DetailsCardProps) => {
  const routeMatch = useRouteMatch();
  const activeApplication = useApplication();

  return (
    <>
      <Card collapsed collapsible>
        <Card.Header style={{ padding: "1rem" }}>
          <Flex align="center" justify="space-between">
            <Flex align="center" justify="space-between">
              {examinable && (
                <>
                  <div className="progress" title={`Total ${progress.total}`}>
                    <div className="progress__value progress__value--success">
                      <Icon type="circle-check" />
                      <span>{progress.completed}</span>
                    </div>
                    <p className="progress__delimiter">|</p>
                    <div className="progress__value progress__value--error">
                      <Icon type="circle-warning" />
                      <span>{progress.notCompleted}</span>
                    </div>
                  </div>
                  <WhiteSpace h="1rem" />
                </>
              )}
              <div>
                <h3>{title}</h3>
                {subtitle && (
                  <>
                    <WhiteSpace v="0.25rem" />
                    <h5 className="color-mid-gray">{subtitle}</h5>
                  </>
                )}
              </div>
            </Flex>
            {examinable && activeApplication?.is_manager && (
              <>
                <WhiteSpace h="1rem" />
                <Link to={`${routeMatch.url}/examine/${path}`}>
                  <Button prefix={<Icon type="examined" />}>Examinează</Button>
                </Link>
              </>
            )}
          </Flex>
        </Card.Header>
        <Card.Body>
          <ApplicationPathContext.Provider value={path}>
            {content}
          </ApplicationPathContext.Provider>
        </Card.Body>
      </Card>
      <WhiteSpace v="1rem" />
    </>
  );
};
