import { useQuery } from "react-query";

import { QueryTable, TableTitle } from "@aeo/core/components";
import { NumberParam } from "use-query-params";
import api, { querykeys } from "api";

import { agentLogsColumns } from "features/agents/columns";
import { useParam, useQueryPagination } from "@aeo/core/hooks";

export const Logs = () => {
  const id = useParam("id", NumberParam.decode) || 0;
  const pagination = useQueryPagination();

  const queryParams = { ...pagination.queryParams };

  const query = useQuery(querykeys.agent.logs(queryParams), () =>
    api.agents.logs(id, queryParams),
  );

  return (
    <QueryTable
      className="mcs-aplication-table"
      title={() => <TableTitle title={<h3>Logs</h3>} />}
      query={query}
      pagination={pagination}
      columns={agentLogsColumns()}
    />
  );
};
