import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUpdateEffect } from "react-use";
import { Button, useNotify } from "ebs-design";
import { ArrayParam, useQueryParams } from "use-query-params";

import {
  WhiteSpace,
  QueryTable,
  TableTitle,
  Filters,
  ChoiceFilter,
  SelectQueryListFilter,
} from "@aeo/core/components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "@aeo/core/hooks";
import {
  forwardSetState,
  mcsUserRolesOptions,
  notifyErrors,
  UserRoles,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import messages from "@aeo/core/messages";
import { orderingOptions } from "features/users/config/ordering";

import api, { querykeys } from "api";
import coreApi, { querykeys as apiQueryKeys } from "@aeo/core/api";
import { usersTableColumns } from "../config/columns";
import { DeleteUserModal, UserFormModal } from "../components";

const userBlockChoise: { [key: string]: string } = {
  true: "Blocat",
  false: "Neblocat",
};

export const Users = () => {
  const [search, setSearch] = React.useState("");
  const notify = useNotify();

  const pagination = useQueryPagination();
  const queryClient = useQueryClient();

  const userModal = useModalState<models.User>();
  const deleteModal = useModalState<models.User>();

  const [ordering, setOrdering] = useOrderingQueryParam();

  const [filterParams, setFilterParams] = useQueryParams({
    profile__role: ArrayParam,
    profile__domain_id: ArrayParam,
    is_blocked: ArrayParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ordering,
    ...filterParams,
  };

  const query = useQuery(querykeys.users.many(queryParams), () =>
    api.users.get(queryParams),
  );

  const { mutate: blockUser } = useMutation(api.users.blockUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(querykeys.users.many());
      notify.success({
        title: data?.message || messages.userAction.blockUser,
      });
    },
    onError: (error) => notifyErrors(notify, error),

    // TODO: Add optimistic update
  });

  const { mutate: unblockUser } = useMutation(api.users.unblockUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(querykeys.users.many());
      notify.success({
        title: data?.message || messages.userAction.unblockUser,
      });
    },
    onError: (error) => notifyErrors(notify, error),

    // TODO: Add optimistic update
  });

  const { mutate: resendConfirmation } = useMutation(
    api.users.resendConfirmation,
    {
      onSuccess: (data) => {
        notify.success({
          title: data?.message || messages.userAction.resendInvitation,
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      usersTableColumns({
        onResendConfirmation: resendConfirmation,
        onEditClick: userModal.openWith,
        onDeleteClick: deleteModal.openWith,
        onChangeUserStatus: (value) =>
          value.is_blocked ? unblockUser(value.id) : blockUser(value.id),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userModal.openWith, deleteModal.openWith],
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <>
      <h2>Utilizatori</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1.5rem" />
      <QueryTable
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <Filters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              >
                <ChoiceFilter
                  value={filterParams.profile__role}
                  setValue={forwardSetState(setFilterParams, "profile__role")}
                  title="Rol"
                  choice={Object.values(UserRoles).map((profile__role) => ({
                    key: profile__role,
                    value: mcsUserRolesOptions.find(
                      (v) => v.value === profile__role,
                    )?.text,
                  }))}
                />
                <SelectQueryListFilter
                  showSearch={false}
                  title="Domeniu"
                  value={filterParams.profile__domain_id}
                  setValue={forwardSetState(
                    setFilterParams,
                    "profile__domain_id",
                  )}
                  querykey={apiQueryKeys.nomenclatures.many}
                  apiCall={() => coreApi.nomenclatures.getEntityList("domains")}
                  getKey={(u: models.IdName) => String(u.id)}
                  getValue={(u) => u.name}
                />

                <ChoiceFilter
                  value={filterParams.is_blocked}
                  setValue={forwardSetState(setFilterParams, "is_blocked")}
                  title="Blocare"
                  choice={Object.values(["true", "false"]).map(
                    (is_blocked) => ({
                      key: is_blocked,
                      value: userBlockChoise[is_blocked],
                    }),
                  )}
                />
              </Filters>
            }
            extra={
              <Button type="primary" onClick={userModal.open}>
                Adaugă utilizator
              </Button>
            }
            onSortChange={setOrdering}
            sortOptions={orderingOptions}
          />
        )}
        query={query}
        columns={tableColumns}
        pagination={pagination}
      />

      {userModal.isOpen && (
        <UserFormModal
          open={userModal.isOpen}
          data={userModal.data}
          onClose={userModal.close}
        />
      )}

      {deleteModal.isOpen && (
        <DeleteUserModal
          open={deleteModal.isOpen}
          data={deleteModal.data}
          onClose={deleteModal.close}
        />
      )}
    </>
  );
};
