import {
  ButtonProps,
  ButtonType,
} from "ebs-design/dist/components/atoms/Button/Button";

export interface UseButtonRadioArgs<T> {
  activeType?: ButtonType;
  defaultType?: ButtonType;
}

export function useButtonRadio<T>(
  active: T,
  onChange?: React.Dispatch<T>,
  { activeType = "primary", defaultType }: UseButtonRadioArgs<T> = {},
) {
  return (value: T): ButtonProps => ({
    type: active === value ? activeType : defaultType,
    onClick: () => {
      onChange?.(value);
    },
  });
}
