import React from "react";
import { useInfiniteQuery } from "react-query";
import { Button, Loader } from "ebs-design";

import { AlertErrors, DebouncedInput, Flex, Grid } from "@aeo/core/components";
import { combineQueryPagesData, hasNextPage, makeBEM } from "@aeo/core/utils";
import { useIntersectionObserver } from "@aeo/core/hooks";
import { INFINITE_SCROLL_MARGIN } from "@aeo/core/app-constants";

import api, { querykeys } from "api";

import { ConversationLink } from ".";

const bem = makeBEM("chat");

export const ConversationList = () => {
  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);
  const loadMoreRef = React.useRef<HTMLDivElement>(null);

  const [search, setSearch] = React.useState("");

  const queryParams = { search, page_size: 12 };

  const query = useInfiniteQuery(
    querykeys.chat.organizations.many({ ...queryParams }),
    ({ pageParam = 1 }) =>
      api.chat.organizations.get({
        ...queryParams,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
    },
  );

  const data = combineQueryPagesData(query.data);

  useIntersectionObserver({
    targetRef: loadMoreRef,
    rootRef: loadMoreRootRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
    rootMargin: INFINITE_SCROLL_MARGIN,
  });

  return (
    <div className={bem("conversation-list-container")}>
      <div className={bem("conversation-search")}>
        <Flex align="stretch">
          <DebouncedInput
            value={search}
            onChange={setSearch}
            placeholder="Caută"
            containerClass="flex-1"
            size="large"
          />
        </Flex>
      </div>
      <div className={bem("conversation-list")} ref={loadMoreRootRef}>
        <AlertErrors error={query.error} />
        {query.isLoading && <Loader loading />}
        {!query.isLoading && !data?.length && (
          <div className="text-center py-20">Nu sunt rezultate</div>
        )}
        <Grid gap="1rem">
          {data?.map?.((organization, i) => (
            <ConversationLink {...organization} key={i} />
          ))}
        </Grid>
        <div ref={loadMoreRef}>
          {query.hasNextPage && (
            <div className="text-center m-15">
              <Button
                onClick={() => query.fetchNextPage()}
                disabled={!query.hasNextPage || query.isFetchingNextPage}
              >
                {query.isFetchingNextPage
                  ? "Se încarcă..."
                  : query.hasNextPage
                  ? "Încărcați mai multe"
                  : null}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
