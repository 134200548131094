import { Layout, Sidebar } from "ebs-design";
import { Link } from "react-router-dom";

import logoImageSrc from "@aeo/core/common/assets/Logo.svg";

import { AppLayoutMenu, AppLayoutTopbar } from ".";

export const AppLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <Layout.Topbar>
        <Layout.Topbar.Toggler />

        <Layout.Topbar.Title>
          <Link to="/" className="logo">
            <img src={logoImageSrc} alt="Logo" />
            AEO Management
          </Link>
        </Layout.Topbar.Title>

        <Layout.Topbar.RightSide>
          <AppLayoutTopbar />
        </Layout.Topbar.RightSide>
      </Layout.Topbar>

      <Sidebar>
        <AppLayoutMenu />
      </Sidebar>

      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};
