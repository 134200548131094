import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const LogisticTwo = () => {
  const logisticTwo = fields.logisticTwo;

  return (
    <>
      <h4>{messages.titles.flowOfGoods.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logisticTwo.registration_procedure} />
      <KeyValueRow {...logisticTwo.stock_procedure} />

      <WhiteSpace v="2rem" />
      <h4>{messages.titles.customsFormalities.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...logisticTwo.has_declaration_process} type="boolean" />
      <KeyValueRow {...logisticTwo.declaration_process} NA />
      <KeyValueRow
        {...logisticTwo.declaration_process_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...logisticTwo.has_checking_declaration} type="boolean" />
      <KeyValueRow {...logisticTwo.checking_declaration} NA />

      <WhiteSpace v="2rem" />

      <KeyValueRow
        {...logisticTwo.has_irregularities_instruction}
        type="boolean"
      />
      <KeyValueRow {...logisticTwo.has_documented_instruction} type="boolean" />
      <KeyValueRow
        {...logisticTwo.documented_instruction_attachments}
        type="attachments"
      />
      <KeyValueRow {...logisticTwo.has_identified_violation} type="boolean" />
      <KeyValueRow {...logisticTwo.identified_violation} />
      <KeyValueRow {...logisticTwo.has_licensed_goods} type="boolean" />
      <KeyValueRow
        {...logisticTwo.licensed_goods_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...logisticTwo.has_external_license} type="boolean" />
      <KeyValueRow {...logisticTwo.external_license} NA />
      <KeyValueRow
        {...logisticTwo.external_license_attachments}
        type="attachments"
      />

      <WhiteSpace v="2rem" />

      <KeyValueRow {...logisticTwo.has_goods_under_law} type="boolean" />
      <KeyValueRow {...logisticTwo.goods_under_law} NA />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
