import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  AlertErrors,
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";

export interface Props extends ModalProps {
  closeModal: () => void;
}

export const NewsModal = ({ closeModal, ...props }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const { mutate, isLoading, error } = useMutation(
    (value: models.NewsItem) => api.news.post(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.news.many());
        closeModal();
        form.resetFields();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const close = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal title="Adaugă anunț" size="small" open={props.open} onClose={close}>
      <Form form={form} onFinish={(news: models.NewsItem) => mutate(news)}>
        <Modal.Content>
          <InputFormField name="title" label="Titlu" />
          <InputFormField textarea name="text" label="Text" />
          <AlertErrors error={error} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={close}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton submit type="primary" loading={isLoading}>
              Adaugă anunț
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
