import {
  useQueryParams,
  NumericArrayParam,
  ArrayParam,
  StringParam,
} from "use-query-params";

export type UseTasksFilterParamsResult = ReturnType<
  typeof useTasksFilterParams
>;

export const useTasksFilterParams = () =>
  useQueryParams({
    type: ArrayParam,
    executor_id: NumericArrayParam,
    execution_date_after: StringParam,
    execution_date_before: StringParam,
    created_at_after: StringParam,
    created_at_before: StringParam,
  });
