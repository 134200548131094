import React from "react";
import { Card, Form, Space, useNotify } from "ebs-design";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api, { querykeys } from "@aeo/core/api";
import models from "@aeo/core/models";

import { useModalState, usePagination } from "@aeo/core/hooks";
import { Flex, Pagination } from "@aeo/core/components";

import { EditDocumentCard } from "./EditDocumentCard";
import { notifyErrors } from "@aeo/core/utils";
import { TemplateModal } from "components";

export const DocumentsSettings = () => {
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const templateModal = useModalState<models.Template>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const pagination = usePagination();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const { data } = useQuery(
    querykeys.nomenclatures.many({
      entityName: models.Nomenclatures.Templates,
      queryParams,
    }),
    () =>
      api.nomenclatures.getEntityList<models.Template>(
        models.Nomenclatures.Templates,
        queryParams,
      ),
  );

  const updateTemplate = useMutation(
    (values: models.Template) =>
      api.nomenclatures.editEntity(
        models.Nomenclatures.Templates,
        templateModal?.data?.id!,
        values,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.nomenclatures.many({
            entityName: models.Nomenclatures.Templates,
          }),
        );
        templateModal.close();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const updateTemplatePreview = useMutation(api.nomenclatures.orderPreview, {
    onSuccess: (value) => setPdfFile(value),
    onError: notifyErrors.bind(null, notify),
  });

  return (
    <>
      <Card>
        <Card.Body>
          <Form>
            <Space
              size="small"
              direction="vertical"
              className="ebs-space--stretch-content"
            >
              {data?.results?.map((t, idx) => (
                <EditDocumentCard
                  openModal={templateModal.openWith}
                  template={t}
                  key={idx}
                />
              ))}
            </Space>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Flex justify="flex-end">
            <Pagination
              data={data}
              pagination={pagination}
              pageSizeChoice={[10, 15, 20]}
            />
          </Flex>
        </Card.Footer>
      </Card>
      {templateModal.isOpen && (
        <TemplateModal
          open={templateModal.isOpen}
          onClose={templateModal.close}
          templateId={templateModal?.data?.id}
          mutation={updateTemplate}
          previewMutation={updateTemplatePreview}
          pdfFile={pdfFile}
          hasTitle
        />
      )}
    </>
  );
};
