import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const ComplianceLegislation = () => {
  const legislation = fields.complianceLegislation;

  return (
    <>
      <ExaminationCard
        {...legislation.has_breaches_legislation}
        type="boolean-no-na"
      />
      <ExaminationCard {...legislation.violations_description} NA />
      <ExaminationCard {...legislation.notify_violations} NA />
      <ExaminationCard {...legislation.introduced_measures} NA />
      <ExaminationCard {...legislation.register_measures} type="boolean" NA />

      <ExaminationCard
        {...legislation.convicted_economic_crimes}
        type="boolean"
      />
      <ExaminationCard {...legislation.economic_crimes} NA />

      <ExaminationCard {...legislation.apply_other_customs} type="boolean" />
      <ExaminationCard {...legislation.other_customs} NA />

      <ExaminationCard
        {...legislation.any_refused_applications}
        type="boolean"
      />
      <ExaminationCard {...legislation.refused_applications} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
