import { AxiosResponse } from "axios";
import { apiAxios } from "@aeo/core/api/axios";
import { GenericObject } from "@aeo/core/types";
import { jsonFromBufferTransformer, stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";

export const agents = {
  get: async (queryParams: GenericObject = {}) => {
    const url = stringifyUrl("/cs/organizations", queryParams);
    const { data } = await apiAxios.get<models.WithResults<models.Agent>>(url);
    return data;
  },
  getAgent: async (id: number, queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/cs/organizations/${id}`, queryParams);
    const { data } = await apiAxios.get<models.Agent>(url);
    return data;
  },
  addLament: async (body: models.Lament) => {
    const { data } = await apiAxios.post<models.Lament>(`/cs/laments`, body);
    return data;
  },
  complaintReply: async (id: number, body: models.Complaint) => {
    const { data } = await apiAxios.post<models.Complaint>(
      `/cs/complaints/${id}/reply`,
      body,
    );
    return data;
  },

  logs: async (id: number, queryParams: GenericObject = {}) => {
    const url = stringifyUrl(`/cs/organizations/${id}/logs`, queryParams);
    const { data } = await apiAxios.get<any>(url);
    return data;
  },
  organizationActions: async (action: string, body: models.ContentEntity) => {
    const { data } = await apiAxios.post(`/cs/organizations/${action}`, body);
    return data;
  },
  organizationActionsPreview: async (
    action: string,
    body: models.ContentEntity,
  ) => {
    const { data } = await apiAxios.post<
      models.ContentEntityWithHeader,
      AxiosResponse<ArrayBuffer>
    >(`/cs/organizations/${action}/preview`, body, {
      responseType: "arraybuffer",
      transformResponse: jsonFromBufferTransformer,
    });
    return data;
  },
};
