import React from "react";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";

import { QueryTable, TableTitle } from "@aeo/core/components";
import {
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "@aeo/core/hooks";

import api, { querykeys } from "api";
import { AssignModal } from "features/applications/components/AssignModal";
import { draftApplicationsColumns } from "features/applications/config";
import { orderingOptions } from "features/applications/config";
import { useApplicationsFilterParams } from "../hooks";
import { ApplicationsFilters } from ".";

export const DraftApplications = () => {
  const [search, setSearch] = React.useState("");

  const pagination = useQueryPagination();
  const modal = useModalState();
  const [filterParams, setFilterParams] = useApplicationsFilterParams();

  const [ordering, setOrdering] = useOrderingQueryParam();

  const queryParams = {
    ...pagination.queryParams,
    ...filterParams,
    state: "draft",
    search,
    ordering,
  };

  const query = useQuery(querykeys.applications.many(queryParams), () =>
    api.applications.get(queryParams),
  );

  const tableColumns = React.useMemo(() => draftApplicationsColumns(), []);

  useUpdateEffect(() => pagination.setPage(1), [search]);

  return (
    <>
      <QueryTable
        className="mcs-aplication-table"
        pagination={pagination}
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <ApplicationsFilters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              />
            }
            sortOptions={orderingOptions}
            onSortChange={setOrdering}
          />
        )}
        query={query}
        columns={tableColumns}
      />
      <AssignModal open={modal.isOpen} onClose={modal.close} />
    </>
  );
};
