import coreApi, { querykeys as coreQuerykeys } from "@aeo/core/api";
import {
  ChoiceFilter,
  DateRangeFilter,
  Filters,
  FiltersProps,
  SelectQueryListFilter,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import { forwardSetState } from "@aeo/core/utils";

import api, { querykeys } from "api";

import { UseApplicationsFilterParamsResult } from "../hooks";

export interface ApplicationsFiltersProps extends FiltersProps {
  queryParams: UseApplicationsFilterParamsResult[0];
  setQueryParams: UseApplicationsFilterParamsResult[1];
  options?: ("type" | "date" | "state" | "manager" | "organization")[];
  excludeStates?: string[];
}

export const ApplicationsFilters = ({
  queryParams,
  setQueryParams,
  options = ["type", "date"],
  excludeStates,
}: ApplicationsFiltersProps) => (
  <Filters {...{ queryParams, setQueryParams }}>
    {options.includes("type") && (
      <ChoiceFilter
        title="Tipul"
        value={queryParams.type}
        setValue={forwardSetState(setQueryParams, "type")}
        choice={Object.values(models.AEOType).map((type) => ({
          key: type,
          value: type,
        }))}
      />
    )}
    {options.includes("date") && (
      <DateRangeFilter
        title="Data creării"
        after={queryParams.created_at_after}
        before={queryParams.created_at_before}
        setAfter={forwardSetState(setQueryParams, "created_at_after")}
        setBefore={forwardSetState(setQueryParams, "created_at_before")}
      />
    )}
    {options.includes("state") && (
      <SelectQueryListFilter
        title="Starea"
        value={queryParams.state}
        setValue={forwardSetState(setQueryParams, "state")}
        querykey={coreQuerykeys.nomenclatures.filterPhases}
        apiCall={(q) =>
          coreApi.nomenclatures.getEntityList("filter-phases", {
            ...q,
            ordering: "order",
          })
        }
        getKey={(u: models.IdName<string>) => u.id}
        getValue={(u) => u.name}
        getFilterGroup={(u: models.FilterPhases) => u.states}
        excludeStates={excludeStates}
      />
    )}
    {options.includes("manager") && (
      <SelectQueryListFilter
        value={queryParams.manager_id}
        setValue={forwardSetState(setQueryParams, "manager_id")}
        querykey={querykeys.users.many}
        apiCall={api.users.get}
        getKey={(u: models.User) => u.id}
        getValue={(u) => u.name}
        title="Persoana responsabilă"
      />
    )}
    {options.includes("organization") && (
      <SelectQueryListFilter
        title="Operator economic"
        value={queryParams.organization_id}
        setValue={forwardSetState(setQueryParams, "organization_id")}
        querykey={querykeys.organizations.many}
        apiCall={api.organizations.get}
        getKey={(u: models.Organization) => u.id}
        getValue={(u) => u.name}
      />
    )}
  </Filters>
);
