import { AxiosResponse } from "axios";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types/common";
import models from "@aeo/core/models";

export const documents = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/cs/documents`, query);
    const { data } = await apiAxios.get<models.WithResults<models.Attachment>>(
      url,
    );
    return data;
  },
  upload: async (option: UploadRequestOption<models.Attachment>) => {
    try {
      const formData = new FormData();
      if (option.file instanceof Blob) {
        formData.append(
          option.filename || "",
          option.file,
          (option.file as any).name,
        );
      } else {
        formData.append(option.filename || "", option.file);
      }
      formData.append("title", option.file.name);
      const { data } = await apiAxios.post<
        any,
        AxiosResponse<models.Attachment>
      >("/cs/documents", formData, {
        onUploadProgress: (e: ProgressEvent) => {
          option.onProgress &&
            option.onProgress({
              ...e,
              percent: e.total ? (e.loaded / e.total) * 100 : 0,
            });
        },
      });
      option.onSuccess && option.onSuccess(data, {} as XMLHttpRequest);
      return data;
    } catch (error: any) {
      option.onError && option.onError(error, undefined);
      return null;
    }
  },
};
